/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LinearProgress, Select, MenuItem } from '@mui/material';
import Spinner from '../../../components/Spinner/Spinner';
// import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from '@apollo/client';
import { GET_PRICE_TYPES, ADD_PRODUCT_PACKAGE, GET_PRODUCT_PACKAGES } from '../../../graphql/packages.graphql';
import { GET_PRODUCTS } from '../../../graphql/products.graphql';
import useToast from '../../../hooks/useToast';
import { PriceType } from '../../../models/packages.models';
import { useForm } from 'react-hook-form';
import { Product } from '../../../models/product.model';
import FeaturedImage from '../../../components/Image component/ImageContainer';
import { uploadImage } from '../../../hooks/upload-image.hook';
import { useNavigate } from 'react-router-dom';

const PackageAddForm = () => {
    const navigate = useNavigate();
    const { loading, error, data } = useQuery(GET_PRICE_TYPES);
    const { loading: loadingProducts, error: errorLoadingProducts, data: loadedProducts } = useQuery(GET_PRODUCTS);
    const [description, setDescription] = useState<string>('');
    const [addPackageMutation, { error: addingError, data: addedPackage }] = useMutation(ADD_PRODUCT_PACKAGE, {
        refetchQueries: [{ query: GET_PRODUCT_PACKAGES }],
    });
    const { showToast, Notification } = useToast();
    const [imageFile, setImageFile] = useState<any>();
    const [image, setImage] = useState<string>();
    const fileRef: any = useRef(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [assignable, setAssignable] = useState<boolean>(false);

    const redirectToPackageView = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    useEffect(() => {
        if (error || errorLoadingProducts) {
            showToast('An error has occurred, please try again', 'danger');
        }

        if (addingError) {
            showToast('An error has occurred while trying to add a package', 'danger');
        }
    }, [error, addingError, errorLoadingProducts]);

    useEffect(() => {
        if (!error && !addingError && addedPackage) {
            showToast('Successfully added package', 'success');
            navigate(`/admin/packages/manage/${addedPackage.package.id}`);
            reset();
            setDescription('');
            setIsLoading(false);
        }
    }, [addedPackage]);

    type FormData = {
        name: string;
        description: string;
        price: string;
        unit: string;
        status: string;
        priceType: string;
        product: string;
    };

    useEffect(() => {
        register('description', { required: true });
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        trigger,
        formState: { errors },
    } = useForm<FormData>();

    const onSubmit = async (data: FormData) => {
        setIsLoading(true);
        if (!imageFile) {
            showToast('Please make sure that an image file is selected', 'danger');
            setIsLoading(false);
            return;
        }

        if (getValues('priceType') === 'empty') {
            setValue('priceType', '');
            setIsLoading(false);
            return;
        }

        if (getValues('product') === 'empty') {
            setValue('product', '');
            setIsLoading(false);
            return;
        }

        const imageKey = await uploadImage(imageFile, 'product_packages_images');

        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload this image, please try again', 'danger');
            setIsLoading(false);
            return;
        }

        const _package = {
            name: data.name,
            description: data.description,
            unit: data.unit,
            price: data.price,
            price_type_enum: data.priceType,
            status_enum: data.status,
            product_id: data.product,
            featured_image: imageKey,
            is_assignable: assignable,
        };

        addPackageMutation({
            variables: {
                package: _package,
            },
        });
    };

    const onImageChange = (e: any) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    if (loading || loadingProducts) {
        return <LinearProgress />;
    }

    const priceTypes: PriceType[] = data?.priceTypes;
    const products: Product[] = loadedProducts?.products;

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => redirectToPackageView(e)}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>
                                    <h3 className='m-0'>Add package</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Featured Image
                                        </label>
                                        <FeaturedImage imageSource={image} fileInputRef={fileRef} />
                                        <input
                                            onChange={(e) => onImageChange(e)}
                                            type='file'
                                            accept='image/*'
                                            ref={fileRef}
                                            style={{ display: 'none' }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Name
                                        </label>
                                        <input
                                            {...register('name', { required: true })}
                                            className='form-control'
                                            id='product'
                                            placeholder="Enter package's name..."
                                            type='text'
                                        />
                                        {errors?.name && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='description'>
                                            Description
                                        </label>
                                        <CKEditor
                                            id='description'
                                            editor={ClassicEditor}
                                            data={description}
                                            config={{
                                                removePlugins: ['Heading'],
                                                toolbar: [
                                                    'bold',
                                                    'italic',
                                                    'bulletedList',
                                                    'numberedList',
                                                    'blockQuote',
                                                    'link',
                                                ],
                                            }}
                                            onChange={(event: any, editor: any) => {
                                                setDescription(editor.getData());
                                                setValue('description', description);
                                                trigger('description');
                                            }}
                                            onReady={(editor: any) => {}}
                                        />
                                        {errors?.description && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='price'>
                                            Price
                                        </label>
                                        <input
                                            {...register('price', { required: true })}
                                            className='form-control'
                                            id='price'
                                            placeholder="Enter package's price..."
                                            type='number'
                                            step={0.01}
                                        />
                                        {errors?.price && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='unit'>
                                            Unit
                                        </label>
                                        <input
                                            {...register('unit', { required: true })}
                                            className='form-control'
                                            id='unit'
                                            placeholder="Enter package's unit..."
                                            type='text'
                                        />
                                        {errors?.unit && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='status'>
                                            Status
                                        </label>
                                        <Select
                                            id='status'
                                            sx={{ borderRadius: '12px', padding: '0' }}
                                            className='form-control'
                                            placeholder='Select a role'
                                            defaultValue={'active'}
                                            inputProps={{
                                                ...register('status', {
                                                    required: true,
                                                    value: 'active',
                                                }),
                                            }}>
                                            <MenuItem value={'active'}>Active</MenuItem>
                                        </Select>
                                        {errors?.status && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='price-type'>
                                            Price type
                                        </label>
                                        <Select
                                            id='price-type'
                                            sx={{ borderRadius: '12px', padding: '0' }}
                                            className='form-control'
                                            placeholder='Select a role'
                                            defaultValue={'empty'}
                                            inputProps={{
                                                ...register('priceType', {
                                                    required: true,
                                                    value: 'empty',
                                                }),
                                            }}>
                                            <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                Select package price type...
                                            </MenuItem>
                                            {priceTypes.map((priceType: PriceType) => {
                                                return (
                                                    <MenuItem value={priceType?.value} key={priceType?.value}>
                                                        {priceType?.title}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {errors?.priceType && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='price-type'>
                                            Product
                                        </label>
                                        <Select
                                            id='price-type'
                                            sx={{ borderRadius: '12px', padding: '0' }}
                                            className='form-control'
                                            placeholder='Select a role'
                                            defaultValue={'empty'}
                                            inputProps={{
                                                ...register('product', {
                                                    required: true,
                                                    value: 'empty',
                                                }),
                                            }}>
                                            <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                Select package product...
                                            </MenuItem>
                                            {products.map((product: Product) => {
                                                return (
                                                    <MenuItem value={product.id} key={product.id}>
                                                        {product.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {errors?.product && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <div className='d-flex h-25 w-25'>
                                            <input
                                                type='checkbox'
                                                onChange={(e) => {
                                                    setAssignable(e.target.checked);
                                                }}
                                            />
                                            <label className='ml-2 mt-2'>Assignable</label>
                                        </div>
                                    </FormGroup>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button disabled={isLoading} type='submit' className='btn btn-dark mt-4'>
                                                {!isLoading ? (
                                                    <span>Add package</span>
                                                ) : (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PackageAddForm;
