import { gql } from '@apollo/client';

export const GET_PRODUCT_PACKAGES = gql`
    query GetProductPackages {
        packages: product_package(where: { deleted_at: { _is_null: true } }, order_by: { price: asc }) {
            id
            name
            price
            description
            unit
            priceType: price_type {
                title
                value
            }
        }
    }
`;

export const GET_PRODUCT_PACKAGE = gql`
    query GetProductPackage($id: uuid!) {
        package: product_package_by_pk(id: $id) {
            id
            name
            description
            price
            unit
            featuredImage: featured_image
            product {
                id
                name
            }
            status {
                value
                title
            }
            images(where: { deleted_at: { _is_null: true } }) {
                id
                path
            }
            priceType: price_type {
                value
                title
            }
            isAssignable: is_assignable
        }
    }
`;

export const GET_PRICE_TYPES = gql`
    query GetPriceTypes {
        priceTypes: product_price_type {
            title
            value
        }
    }
`;

export const ADD_PRODUCT_PACKAGE = gql`
    mutation AddPackage($package: product_package_insert_input!) {
        package: insert_product_package_one(object: $package) {
            id
        }
    }
`;

export const UPDATE_PACKAGE_PRODUCT = gql`
    mutation UpdatePackage($id: uuid!, $package: product_package_set_input!) {
        update_product_package_by_pk(pk_columns: { id: $id }, _set: $package) {
            id
        }
    }
`;

export const REMOVE_PRODUCT_PACKAGE = gql`
    mutation RemovePackage($id: uuid!) {
        update_product_package_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_PACKAGE_IMAGE = gql`
    mutation RemoveProductPackage($id: uuid!) {
        update_product_package_image_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_PACKAGE_IMAGE = gql`
    mutation AddPackageImage($image: product_package_image_insert_input!) {
        insert_product_package_image_one(object: $image) {
            id
        }
    }
`;

export const UPDATE_PRODUCT_PACKAGE = gql`
    mutation UpdatePackage($id: uuid!, $package: package_booking_set_input!) {
        update_package_booking_by_pk(pk_columns: { id: $id }, _set: $package) {
            id
        }
    }
`;

export const GET_PACKAGE_BOOKING = gql`
    query GetPackageBooking($id: uuid!) {
        package_booking_by_pk(id: $id) {
            id
            from
            to
            member_id
            booking_status {
                value
                title
            }
        }
    }
`;
