import { useMutation } from '@apollo/client';
import { Box, Checkbox, FormControlLabel, List, ListItem, Tab, Tabs, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { REMOVE_BOOKING_CHECKLIST_ADD_ON } from '../graphql/bookings.graphql';
import { Booking, BookingRoomExtra, ConferenceBookingDocument, ConferenceBookingNote } from '../models/bookings.model';
import { CheckListAddOn, MeetingRoomItem, RoomItemConfiguration } from '../models/meeting.room.model';
import { AfterHourResource, BookingResource } from '../models/resources.model';
import { MenuType, Vendor, VendorMenuPackageItem } from '../models/vendor.model';
import BookingAfterHourResourcesView from '../views/pages/booking/components/BookingAfterHourResourcesView';
import BookingConfigurationView from './Booking Configurations/BookingConfigurationView';
import CateringMenuView from './Booking Vendors/CateringMenuView';
import BookingAddOnsView from '../views/pages/booking/components/booking-add-ons/BookingAddOnsView';
import { RoomExtra } from '../models/room.extra.model';
import BookingDocumentsView from '../views/pages/booking/components/BookingDocumentsView';
import BookingNotesView from '../views/pages/booking/components/BookingNotesView';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface BookingAddOnOptionProps {
    addOns: CheckListAddOn[];
    vendors?: Vendor[];
    vendorMenuTypes?: MenuType[];
    roomItemId?: string;
    rooms?: MeetingRoomItem[];
    selectedBooking?: Booking;
    getChecklistItems?: (list: string[]) => void;
    configurationId?: string;
    getConfigurationId?: (id: string) => void;
    selectedMenuItems?: VendorMenuPackageItem[];
    getSelectedMenuItems?: (items: VendorMenuPackageItem[]) => void;
    afterHourResources?: AfterHourResource[];
    getBookingResources?: (resources: BookingResource[]) => void;
    bookingResources: BookingResource[];
    bookingHours: number;
    isDateClosed?: boolean;
    roomExtras?: RoomExtra[];
    bookingRoomAdOns: BookingRoomExtra[];
    getBookingRoomAdOns: (addOns: BookingRoomExtra[]) => void;
    documents: ConferenceBookingDocument[];
    getDocuments: (documents: ConferenceBookingDocument[]) => void;
    notes: ConferenceBookingNote[];
    getNotes: (notes: ConferenceBookingNote[]) => void;
}

const BookingAddOnOption = ({
    addOns,
    vendors,
    vendorMenuTypes,
    roomItemId,
    rooms,
    selectedBooking,
    getChecklistItems,
    selectedMenuItems,
    getSelectedMenuItems,
    configurationId,
    getConfigurationId,
    afterHourResources,
    getBookingResources,
    bookingResources,
    bookingHours,
    isDateClosed,
    roomExtras,
    bookingRoomAdOns,
    getBookingRoomAdOns,
    documents,
    getDocuments,
    notes,
    getNotes,
}: BookingAddOnOptionProps) => {
    const [value, setValue] = React.useState(0);
    const [checklistItemIdList, setChecklistItemIdList] = useState<string[]>([]);

    const [removeBookingAddOn, { data, error }] = useMutation(REMOVE_BOOKING_CHECKLIST_ADD_ON);

    const isItemChecked = (addOn: CheckListAddOn): boolean => {
        if (!selectedBooking) return false;

        const checklistIndex: number = selectedBooking?.checklistAddons.findIndex(
            (checklistAddOn) => checklistAddOn.checklistItemId === addOn.checkListItem.id,
        );
        return checklistIndex !== -1;
    };

    const onCheckboxChanged = (e: any, addOn?: CheckListAddOn) => {
        if (!e.target.checked) {
            const itemId = addOn?.checkListItem?.id;
            const filteredItem = checklistItemIdList.filter((id) => id !== itemId);
            setChecklistItemIdList(filteredItem);
            getChecklistItems(filteredItem);
            if (selectedBooking) {
                const addOnId = selectedBooking?.checklistAddons?.find((e) => e.checklistItemId === itemId)?.id;

                removeBookingAddOn({
                    variables: {
                        id: addOnId,
                    },
                });
            }
            return;
        }

        setChecklistItemIdList([...checklistItemIdList, addOn.checkListItem.id]);
        getChecklistItems([...checklistItemIdList, addOn.checkListItem.id]);
    };

    useEffect(() => {
        if (data && !error) {
            console.log('Successfully deleted the item');
        }

        if (error && !data) {
            console.log('Error', error);
        }
    }, [data, error]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const itemConfigurations: RoomItemConfiguration[] =
        rooms.find((room) => room.id === roomItemId)?.itemConfigurations || [];

    return (
        <Row className='mx-2' sx={{ maxWidth: '100%' }}>
            <Col>
                <Row>
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                                <Tab sx={{ textTransform: 'capitalize' }} label='Checklist' {...a11yProps(0)} />
                                <Tab
                                    sx={{ textTransform: 'capitalize' }}
                                    label='Configuration'
                                    disabled={itemConfigurations?.length === 0}
                                    {...a11yProps(1)}
                                />

                                <Tab sx={{ textTransform: 'capitalize' }} label='Catering' {...a11yProps(2)} />
                                <Tab
                                    sx={{ textTransform: 'capitalize' }}
                                    label='Resources'
                                    style={{
                                        display: !isDateClosed ? 'none' : 'block',
                                    }}
                                    {...a11yProps(3)}
                                />
                                <Tab
                                    sx={{ textTransform: 'capitalize' }}
                                    style={{
                                        display: roomExtras.length === 0 ? 'none' : 'block',
                                    }}
                                    label='Add-ons'
                                    {...a11yProps(4)}
                                />
                                <Tab sx={{ textTransform: 'capitalize' }} label='Documents' {...a11yProps(5)} />
                                <Tab sx={{ textTransform: 'capitalize' }} label='Notes' {...a11yProps(6)} />
                            </Tabs>
                        </Box>
                        <Box>
                            <TabPanel value={value} index={0}>
                                {(!roomItemId || roomItemId === 'empty') && (
                                    <p className='mt-4'>Please select a room.</p>
                                )}
                                {addOns.length === 0 && roomItemId && roomItemId !== 'empty' && (
                                    <p className='mt-4'>This room does not have checklist options.</p>
                                )}
                                {addOns.length > 0 && (
                                    <List>
                                        {addOns.map((addOn) => {
                                            return (
                                                <ListItem
                                                    key={addOn.id}
                                                    sx={{
                                                        padding: 0,
                                                        margin: 0,
                                                    }}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                onChange={(e) => onCheckboxChanged(e, addOn)}
                                                                defaultChecked={isItemChecked(addOn)}
                                                            />
                                                        }
                                                        label={addOn.checkListItem.name}
                                                    />
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                )}
                            </TabPanel>
                            {itemConfigurations.length > 0 && (
                                <TabPanel value={value} index={1}>
                                    <BookingConfigurationView
                                        itemConfigurations={itemConfigurations}
                                        getConfigurationId={getConfigurationId}
                                        configurationId={configurationId}
                                    />
                                </TabPanel>
                            )}
                            <TabPanel value={value} index={2}>
                                <div className='mt-4'>
                                    <CateringMenuView
                                        vendors={vendors}
                                        vendorMenuTypes={vendorMenuTypes}
                                        selectedMenuItems={selectedMenuItems}
                                        selectedBooking={selectedBooking}
                                        getSelectedMenuItems={getSelectedMenuItems}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <div
                                    className='mt-4'
                                    style={{
                                        display: !isDateClosed ? 'none' : 'block',
                                    }}>
                                    <BookingAfterHourResourcesView
                                        afterHourResources={afterHourResources}
                                        getBookingResources={getBookingResources}
                                        bookingResources={bookingResources}
                                        bookingHours={bookingHours}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <div
                                    style={{
                                        display: roomExtras.length === 0 ? 'none' : 'block',
                                    }}
                                    className='mt-4'>
                                    <BookingAddOnsView
                                        roomExtras={roomExtras}
                                        bookingHours={bookingHours}
                                        bookingRoomAdOns={bookingRoomAdOns}
                                        getBookingRoomAdOns={getBookingRoomAdOns}
                                    />
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <BookingDocumentsView documents={documents} getDocuments={getDocuments} />
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                <BookingNotesView notes={notes} getNotes={getNotes} />
                            </TabPanel>
                        </Box>
                    </Box>
                </Row>
            </Col>
        </Row>
    );
};

export default BookingAddOnOption;
