/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FormGroup, Spinner } from 'reactstrap';
import FeaturedImage from '../../../../components/Image component/ImageContainer';
import { ADD_MEETING_ROOM_CHECKLIST_ITEM } from '../../../../graphql/meeting-room-checklist.graphql';
import { GET_MEETING_ROOM } from '../../../../graphql/meeting-rooms.graphql';
import useToast from '../../../../hooks/useToast';
import { uploadImage } from '../../../../hooks/upload-image.hook';
import { RoomCheckListItem } from '../../../../models/meeting.room.model';

interface Props {
    handleClose?: () => void;
    isOpen?: boolean;
    checkListItem?: RoomCheckListItem;
}

type FormData = {
    name: string;
    description: string;
};

const AddCheckListItemDialog = ({ isOpen, handleClose, checkListItem }: Props) => {
    const [image, setImage] = useState<string>(
        checkListItem ? `${process.env.REACT_APP_IMAGE_URL}/${checkListItem.featuredImage}` : '',
    );
    const [imageFile, setImageFile] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const params: any = useParams();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();
    const [addRoomChecklistItem, { data: addedItem, error: errorAdding }] = useMutation(
        ADD_MEETING_ROOM_CHECKLIST_ITEM,
        {
            refetchQueries: [{ query: GET_MEETING_ROOM }, 'GetMeetingRoom'],
        },
    );
    const { showToast, Notification } = useToast();
    const fileRef = useRef<any>();
    const onImageChange = (e) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    useEffect(() => {
        if (addedItem && !errorAdding) {
            showToast(
                checkListItem ? 'Successfully updated a checklist item' : 'Successfully added a checklist item',
                'success',
            );
            setIsLoading(false);
        }

        if (errorAdding && !addedItem) {
            showToast(
                checkListItem
                    ? 'An error has occurred while trying to update this checklist item'
                    : 'An error has occurred while trying to add a checklist item',
                'danger',
            );
            setIsLoading(false);
        }
    }, [addedItem, errorAdding]);

    const onSubmit = async (data: FormData) => {
        await addItem(data);
    };

    const addItem = async (data: FormData) => {
        setIsLoading(true);
        let imageKey: string;
        if (!imageFile && !checkListItem) {
            showToast('Please make sure that a file is selected', 'danger');
            setIsLoading(false);
            return;
        }

        if (checkListItem) {
            imageKey = checkListItem?.featuredImage;
        }

        if (imageFile) {
            imageKey = await uploadImage(imageFile, 'checklist_items');
        }

        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload the image', 'danger');
            setIsLoading(false);
        }

        const item = {
            id: checkListItem?.id,
            name: data.name,
            description: data.description,
            featured_image: imageKey,
            meeting_room_id: params.id,
        };

        await addRoomChecklistItem({
            variables: {
                item: item,
            },
        });

        handleClose();
    };

    return (
        <div>
            {Notification}
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>{checkListItem ? 'Update' : 'Add'} Checklist Item</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <DialogContentText className='mb-4'>
                            Enter the details to add a checklist item to this room
                        </DialogContentText>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>
                                Image
                            </label>
                            <FeaturedImage imageSource={image} fileInputRef={fileRef} />
                            <input
                                onChange={(e) => onImageChange(e)}
                                type='file'
                                accept='image/*'
                                ref={fileRef}
                                style={{ display: 'none' }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>
                                Name
                            </label>
                            <input
                                className='form-control'
                                id='discount-name'
                                {...register('name', {
                                    required: true,
                                    value: checkListItem?.name || '',
                                })}
                                placeholder='Enter name...'
                                type='text'
                            />
                            {errors.name && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='description'>
                                Description(Optional)
                            </label>
                            <textarea
                                className='form-control'
                                id='description'
                                {...register('description', {
                                    required: false,
                                    value: checkListItem?.description || '',
                                })}
                                placeholder='Enter description...'
                                rows={3}></textarea>
                            {errors.description && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={handleClose}>
                            Cancel
                        </Button>
                        {!isLoading && <Button type='submit'>{checkListItem ? 'Update' : 'Add'}</Button>}
                        {isLoading && (
                            <Button disabled={isLoading}>
                                <span>
                                    <Spinner />
                                </span>
                            </Button>
                        )}
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default AddCheckListItemDialog;
