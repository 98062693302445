import { gql } from '@apollo/client';

export const GET_SURVEY_LIST = gql`
    query GetSurveyList {
        survey(where: { deleted_at: { _is_null: true } }) {
            id
            inrtoDescription: intro_description
            outroDescription: outro_description
            createdAt: created_at
            title
            status {
                value
                title
            }
        }
    }
`;

export const ADD_SURVEY = gql`
    mutation AddSurvey($survey: survey_insert_input!) {
        survey: insert_survey_one(
            object: $survey
            on_conflict: {
                constraint: survey_pkey
                update_columns: [intro_description, outro_description, status_enum, title, is_open]
            }
        ) {
            id
        }
    }
`;

export const REMOVE_SURVEY = gql`
    mutation RemoveSurvey($id: uuid!) {
        survey: update_survey_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_SURVEY_ITEM_TYPE_LIST = gql`
    query GetSurveyItemTypeList {
        typeList: survey_question_item_type {
            value
            title
        }
        statusList: survey_status {
            value
            title
        }
    }
`;

export const GET_SURVEY = gql`
    query GetSurveyDetails($id: uuid!) {
        survey: survey_by_pk(id: $id) {
            id
            title
            isOpen: is_open
            introDescription: intro_description
            outroDescription: outro_description
            status {
                value
                title
            }
            questions(where: { deleted_at: { _is_null: true } }, order_by: { order: asc }) {
                id
                order
                title
                subtitle
                items: question_items(where: { deleted_at: { _is_null: true } }) {
                    id
                    title
                    required
                    dropdownOptions: dropdown_options(where: { deleted_at: { _is_null: true } }) {
                        id
                        value
                    }
                    listOptions: list_options(where: { deleted_at: { _is_null: true } }) {
                        id
                        value
                    }
                    type {
                        value
                        title
                    }
                }
            }
        }
        statusList: survey_status {
            value
            title
        }
        typeList: survey_question_item_type {
            value
            title
        }
    }
`;

export const REMOVE_QUESTION_ITEM = gql`
    mutation RemoveQuestionItem($id: uuid!) {
        item: update_survey_question_item_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_DROPDOWN_OPTION = gql`
    mutation RemovedDropDownOption($id: uuid!) {
        dropdown: update_survey_question_item_dropdown_option_by_pk(
            pk_columns: { id: $id }
            _set: { deleted_at: now }
        ) {
            id
        }
    }
`;

export const REMOVE_LIST_OPTION = gql`
    mutation RemoveListOption($id: uuid!) {
        listItem: update_survey_question_item_list_option_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_QUESTION = gql`
    mutation RemoveQuestion($id: uuid!) {
        question: update_survey_question_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;
