/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
    Card,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import { ProductItem } from '../../../models/product.model';
import { REMOVE_PRODUCT_ITEM, GET_PRODUCT } from '../../../graphql/products.graphql';
import { useMutation } from '@apollo/client';
import useToast from '../../../hooks/useToast';
import ProductItemAddForm from './ProductItemAddForm';
import _ from 'lodash';

interface ItemViewProps {
    productItems: ProductItem[];
}

export default function ItemView({ productItems }: ItemViewProps) {
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [alert, setAlert] = useState<any>(null);
    const [displayForm, setDisplayForm] = useState<boolean>(false);
    const { showToast, Notification } = useToast();
    const [searchKey, setSearchKey] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [sortFiled, setSortField] = useState<string>('name');
    const [selectedItem, setSelectedItem] = useState<ProductItem>();
    const [removeItemMutation, { error, data }] = useMutation(REMOVE_PRODUCT_ITEM, {
        refetchQueries: [{ query: GET_PRODUCT }, 'GetProduct'],
    });

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeProductItem(props.item.id);
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This item will be removed from this product.
            </SweetAlert>,
        );
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to remove the item', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            showToast('Successfully updated the product item', 'success');
        }
    }, [data]);

    const getRowValue = (value: string) => {
        setRowsPerPage(parseInt(value));
    };

    const sortBy: any = (items: ProductItem[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['name', _.orderBy(items, (item) => item?.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['pax', _.orderBy(items, (item) => parseInt(item?.pax), [direction === 'asc' ? 'asc' : 'desc'])],
            ['status', _.orderBy(items, (item) => item?.status.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['code', _.orderBy(items, (item) => item?.code, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    const removeProductItem = (id: string) => {
        removeItemMutation({
            variables: {
                id: id,
            },
        });
    };

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    if (displayForm) {
        return <ProductItemAddForm backToItems={() => setDisplayForm(false)} selectedItem={selectedItem} />;
    }

    if (productItems.length === 0) {
        return (
            <>
                {alert}
                {Notification}
                <Row className='mt-4'>
                    <Col>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Items</h3>
                                    </Col>
                                    <Col className='text-right' xs='6'>
                                        <button
                                            className='btn btn-round btn-dark btn-sm'
                                            color='default'
                                            id='tooltip969372949'
                                            onClick={() => {
                                                setDisplayForm(true);
                                            }}>
                                            <span className='btn-inner--text'>Add item</span>
                                        </button>
                                        <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                            Add product
                                        </UncontrolledTooltip>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col>
                                        <h3>This product has no items</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }

    let filteredItems = productItems.filter((item) => {
        if (searchKey === '') {
            return item;
        }

        return (
            item.name.toLowerCase().includes(searchKey.toLowerCase()) ||
            item.status.title.toLowerCase().includes(searchKey.toLowerCase())
        );
    });

    filteredItems = sortBy(filteredItems, sortFiled, sortDirection);

    return (
        <>
            {alert}
            {Notification}
            <Row className='mt-4'>
                <Col>
                    <Card>
                        <CardHeader className='border-0'>
                            <Row>
                                <Col xs='6'>
                                    <h3 className='mb-0'>Items</h3>
                                </Col>
                                <Col className='text-right' xs='6'>
                                    <button
                                        className='btn btn-round btn-dark btn-sm'
                                        color='default'
                                        id='tooltip969372949'
                                        onClick={() => {
                                            setDisplayForm(true);
                                        }}>
                                        <span className='btn-inner--text'>Add item</span>
                                    </button>
                                    <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                        Add product
                                    </UncontrolledTooltip>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>
                                    <Input
                                        className='w-25'
                                        placeholder='Search keyword...'
                                        onChange={(e) => {
                                            setSearchKey(e.target.value);
                                            setPage(0);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </CardHeader>
                        <Table className='align-items-center table-flush' responsive>
                            <thead className='thead-light'>
                                <tr>
                                    <th
                                        onClick={() => {
                                            setSortField('name');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='name'
                                        scope='col'>
                                        Name
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('pax');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='pax'
                                        scope='col'>
                                        Pax
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('code');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='pax'
                                        scope='col'>
                                        Code
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('status');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='status'
                                        scope='col'>
                                        Status
                                    </th>
                                    <th scope='col'></th>
                                </tr>
                            </thead>
                            <tbody className='list'>
                                {filteredItems
                                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                    .filter((item) => {
                                        if (searchKey === '') {
                                            return item;
                                        }

                                        return (
                                            item.name.toLowerCase().includes(searchKey.toLowerCase()) ||
                                            item.status.title.toLowerCase().includes(searchKey.toLowerCase())
                                        );
                                    })
                                    .map((item) => {
                                        return (
                                            <tr
                                                key={item.id}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setSelectedItem(item);
                                                    setDisplayForm(true);
                                                }}>
                                                <td>{item.name}</td>
                                                <td>{item.pax}</td>
                                                <td>{item.code ? item.code : ''}</td>
                                                <td>{item.status.title}</td>
                                                <td className='text-right'>
                                                    <button
                                                        onClick={() => {
                                                            setSelectedItem(item);
                                                            setDisplayForm(true);
                                                        }}
                                                        className='btn btn-info btn-icon-only rounded-circle btn-sm'>
                                                        <i className='fa-solid fa-pencil'></i>
                                                    </button>
                                                    <button
                                                        className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            displayAlert({ warning: true, item });
                                                        }}>
                                                        <i className='fa-solid fa-trash-can'></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                        <CardFooter className='py-4'>
                            <Row>
                                <div className='col-4 d-flex'>
                                    <span className='min-text'>Rows per page:</span>
                                    <BasicMenu
                                        getValue={getRowValue}
                                        options={['10', '20', '100']}
                                        value={rowPerPage.toString()}
                                    />
                                </div>
                                <div className='col-4 d-flex'>
                                    <span className='min-text'>Page:</span>
                                    <span className='min-text pl-2'>
                                        {page + 1} of {Math.ceil(filteredItems.length / rowPerPage)}
                                    </span>
                                </div>
                                <div className='col-4 d-flex'>
                                    <Pagination>
                                        <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                            <PaginationLink onClick={(e) => previousPage()}>
                                                <i className='fas fa-angle-left' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem
                                            className={
                                                page >= Math.ceil(filteredItems.length / rowPerPage) - 1
                                                    ? 'disabled'
                                                    : ''
                                            }>
                                            <PaginationLink onClick={(e) => nextPage()}>
                                                <i className='fas fa-angle-right' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </Row>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
