import React, { useState } from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import AddDocumentDialog from '../dialogs/AddDocumentDialog';
import { ConferenceBookingDocument } from '../../../../models/bookings.model';
import DocumentItem from './DocumentItem';

interface BookingDocumentsViewProps {
    documents: ConferenceBookingDocument[];
    getDocuments: (documents: ConferenceBookingDocument[]) => void;
}

export default function BookingDocumentsView({ documents, getDocuments }: BookingDocumentsViewProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            {isOpen && (
                <AddDocumentDialog
                    documents={documents}
                    getDocuments={getDocuments}
                    isOpen={isOpen}
                    handleClose={() => setIsOpen(false)}
                />
            )}
            <Row className='mt-4'>
                <Col>
                    <Row>
                        <Col xs='6'>
                            <h3 className='mb-0'>Documents</h3>
                        </Col>
                        <Col className='text-right' xs='6'>
                            <div
                                className='btn btn-dark btn-sm'
                                color='default'
                                id='tooltip969372949'
                                onClick={() => {
                                    setIsOpen(true);
                                }}>
                                Add
                            </div>
                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                Add Document
                            </UncontrolledTooltip>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col className='menu-items'>
                            {documents.map((doc, index) => {
                                return (
                                    <DocumentItem
                                        documents={documents}
                                        getDocuments={getDocuments}
                                        key={index}
                                        document={doc}
                                    />
                                );
                            })}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}
