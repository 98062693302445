/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import ReactDatetimeClass from 'react-datetime';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row, UncontrolledTooltip } from 'reactstrap';
import Spinner from '../../../../components/Spinner/Spinner';
import useToast from '../../../../hooks/useToast';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_OR_UPDATE_CAMPAIGN, GET_CAMPAIGN_DETAILS, GET_CAMPAIGNS } from '../../../../graphql/campain.graphql';
import { useNavigate, useParams } from 'react-router-dom';
import { Campaign, CampaignCategory } from '../../../../models/campain.model';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';

type FormData = {
    title: string;
    description: string;
    startDate: string;
    pageLink: string;
    endDate: string;
    category: string;
};

export default function CampaignDetailsView() {
    const { showToast, Notification } = useToast();
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const params = useParams();

    const { data, loading, error } = useQuery(GET_CAMPAIGN_DETAILS, {
        variables: {
            id: params?.id,
        },
    });

    const [addCampaign, { loading: updating, data: updatedCampaign, error: updatingError }] = useMutation(
        ADD_OR_UPDATE_CAMPAIGN,
        { refetchQueries: [{ query: GET_CAMPAIGNS }, GET_CAMPAIGN_DETAILS] },
    );

    useEffect(() => {
        if (updatedCampaign) {
            showToast('Successfully updated campaign details', 'success');
        }
    }, [updatedCampaign]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load the page', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (updatingError) {
            showToast('An error has occurred while trying to update the details', 'danger');
        }
    }, [updatingError]);

    const navigate = useNavigate();
    const backToCampaignView = () => navigate(-1);
    const onSubmit = async (formData: FormData) => {
        const campaign = {
            id: params?.id,
            title: formData.title,
            start_date: formData.startDate,
            end_date: formData.endDate,
            page_link: formData.pageLink,
            description: formData.description,
            category_enum: formData.category,
            code: '',
        };

        await addCampaign({
            variables: {
                campaign,
            },
        });
    };

    if (loading) {
        return <LinearProgress />;
    }

    const campaign: Campaign = data?.campaign;
    const campaignCategories: CampaignCategory[] = data?.categories;

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card className='mt-4'>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={backToCampaignView}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>
                                    <h3 className='m-0'>Campaign details</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-name'>
                                                    Title
                                                </label>
                                                <input
                                                    {...register('title', { required: true, value: campaign.title })}
                                                    className='form-control'
                                                    id='p-member-name'
                                                    placeholder='Enter campaign title...'
                                                    type='text'
                                                />
                                                {errors.title && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Start date
                                                </label>
                                                <ReactDatetimeClass
                                                    inputProps={{
                                                        placeholder: 'Enter starting date...',
                                                        autoComplete: 'off',
                                                        ...register('startDate', {
                                                            required: true,
                                                            value: campaign.startDate,
                                                        }),
                                                    }}
                                                    initialValue={
                                                        campaign.startDate
                                                            ? moment(campaign.startDate).format('YYYY-MM-DD')
                                                            : ''
                                                    }
                                                    dateFormat={'YYYY-MM-DD'}
                                                    onChange={(date: moment.Moment) => {
                                                        setValue('startDate', date.format('YYYY-MM-DD'));
                                                    }}
                                                    timeFormat={false}
                                                />
                                                {errors.startDate && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>

                                        <div className='col-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    End date
                                                </label>
                                                <ReactDatetimeClass
                                                    inputProps={{
                                                        placeholder: 'Enter ending date...',
                                                        autoComplete: 'off',
                                                        ...register('endDate', { required: true }),
                                                    }}
                                                    initialValue={
                                                        campaign.startDate
                                                            ? moment(campaign.endDate).format('YYYY-MM-DD')
                                                            : ''
                                                    }
                                                    dateFormat={'YYYY-MM-DD'}
                                                    onChange={(date: moment.Moment) => {
                                                        setValue('endDate', date.format('YYYY-MM-DD'));
                                                    }}
                                                    timeFormat={false}
                                                />
                                                {errors.endDate && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-name'>
                                                    Page Link
                                                </label>
                                                <div className='row'>
                                                    <div className='col-10'>
                                                        <input
                                                            {...register('pageLink', {
                                                                required: true,
                                                                value: campaign.pageLink,
                                                            })}
                                                            className='form-control'
                                                            id='p-member-name'
                                                            placeholder='Paste the desired link for the campaign...'
                                                            type='text'
                                                        />
                                                    </div>

                                                    <div className='col-2 text-right'>
                                                        <CopyToClipboard
                                                            text={`${campaign.pageLink}?campaign=${campaign.code}`}
                                                            onCopy={() => {
                                                                showToast('Campaign link copied to clipboard', 'info');
                                                            }}>
                                                            <button
                                                                className='btn btn-primary'
                                                                id='tooltip982655500'
                                                                type='button'>
                                                                <div>Copy</div>
                                                            </button>
                                                        </CopyToClipboard>
                                                        <UncontrolledTooltip delay={0} target='tooltip982655500'>
                                                            Copy Link
                                                        </UncontrolledTooltip>
                                                    </div>
                                                </div>
                                                {errors.pageLink && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-name'>
                                                    Description
                                                </label>
                                                <textarea
                                                    {...register('description', {
                                                        required: true,
                                                        value: campaign.description,
                                                    })}
                                                    className='form-control'
                                                    rows={5}
                                                    id='p-member-name'
                                                    placeholder='Enter campaign description...'
                                                />
                                                {errors.description && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <label className='form-control-label' htmlFor='status'>
                                                Category
                                            </label>
                                            <Select
                                                id='status'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                placeholder='Select a category'
                                                defaultValue={campaign?.category?.value ?? 'empty'}
                                                inputProps={{
                                                    ...register('category', {
                                                        required: true,
                                                        value: campaign?.category?.value,
                                                    }),
                                                }}>
                                                <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                    Select a campaign category...
                                                </MenuItem>
                                                {campaignCategories?.map((status) => {
                                                    return (
                                                        <MenuItem key={status.value} value={status.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.category && (
                                                <span className='invalid'>{errors.category.message}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={updating}>
                                                {!updating && <span>Update</span>}
                                                {updating && <Spinner />}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
