import moment from 'moment';
import React from 'react';
import { OrderItem } from '../../../../models/order.model';

interface ItemProps {
    item: OrderItem;
    isAccountInArrears: boolean;
}

export const OrderItemInfo = ({ item, isAccountInArrears }: ItemProps) => {
    const imagePath = item.booking
        ? item.booking.meetingRoomItem.featuredImage
        : item.packageBooking.package.featuredImage;

    const getItemPrice = (): string => {
        if (item.booking) {
            if (isAccountInArrears) {
                return `R${item.totalPrice?.toFixed(2)}`;
            }

            return `R${item.quotedPrice?.toFixed(2) || item.totalPrice?.toFixed(2)}`;
        } else if (item.packageBooking) {
            return `R${item.totalPrice.toFixed(2)}`;
        }
        return '';
    };

    const getCateringTotalPrice = (): number => {
        let totalPrice = 0;
        item?.booking?.menuPackages?.forEach((menu) => {
            totalPrice += menu.totalPrice;
        });

        return totalPrice;
    };

    const getResourceTotalPrice = (): number => {
        let totalPrice = 0;

        item?.booking?.bookingResources?.forEach((resource) => {
            totalPrice += resource.totalPrice;
        });

        return totalPrice;
    };

    const getRoomExtrasTotalPrice = (): number => {
        let totalPrice = 0;
        item?.booking?.roomExtras?.forEach((extra) => {
            totalPrice += extra.totalPrice;
        });

        return totalPrice;
    };

    console.log('IMAGE', imagePath);

    return (
        <div className='row py-2'>
            <div className='col-3'>
                <img className='order-image' src={`${process.env.REACT_APP_IMAGE_URL}/${imagePath}`} alt='item' />
            </div>
            {item.booking && (
                <div className='col-9'>
                    <h4>{item.booking.meetingRoomItem.name}</h4>
                    <p className='card-text'>Reference: {item.booking.reference}</p>
                    <p className='card-text'>
                        Date: {moment(item.booking.from).format('yyyy-MM-DD')} (
                        {moment(item.booking.from).format('HH:mm')} - {moment(item.booking.to).format('HH:mm')})
                    </p>
                    {item.discount > 0 && (
                        <>
                            <p className='card-text'>Price: R{item.totalPrice.toFixed(2)}</p>
                            <p
                                style={{ textDecoration: isAccountInArrears ? 'line-through' : 'none' }}
                                className='card-text small'>
                                Discount: R{item.discount.toFixed(2)}
                            </p>
                        </>
                    )}
                    <p className='card-text text-strong'>Total Price: {getItemPrice()}</p>
                    {item.booking?.menuPackages?.length > 0 && (
                        <div>
                            <h4>Catering</h4>
                            <p className='card-text'>Total price : {`R ${getCateringTotalPrice().toFixed(2)}`}</p>
                        </div>
                    )}
                    {item.booking?.bookingResources?.length > 0 && (
                        <div>
                            <h4>Resources</h4>
                            <p className='card-text'>Total price : {`R ${getResourceTotalPrice().toFixed(2)}`}</p>
                        </div>
                    )}
                    {item.booking?.roomExtras?.length > 0 && (
                        <div>
                            <h4>Add-ons</h4>
                            <p className='card-text'>Total price : {`R ${getRoomExtrasTotalPrice().toFixed(2)}`}</p>
                        </div>
                    )}
                </div>
            )}
            {item.packageBooking && (
                <div className='col-9'>
                    <h4>{item.packageBooking.package.name}</h4>
                    <p className='card-text'>Reference: {item.packageBooking.reference}</p>
                    <p className='card-text'>Price: {getItemPrice()}</p>
                </div>
            )}
        </div>
    );
};
