import React, { useState } from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import AddNoteDialog from '../dialogs/AddNoteDialog';
import { ConferenceBookingNote } from '../../../../models/bookings.model';
import NoteItem from './NoteItem';
import { useSelector } from 'react-redux';

interface BookingNotesViewProps {
    notes: ConferenceBookingNote[];
    getNotes: (notes: ConferenceBookingNote[]) => void;
}

export default function BookingNotesView({ notes, getNotes }: BookingNotesViewProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const user = useSelector((state: any) => state.user.userInfo);
    const [selectedNote, setSelectedNote] = useState<ConferenceBookingNote>();

    return (
        <>
            {isOpen && (
                <AddNoteDialog
                    selectedNote={selectedNote}
                    notes={notes}
                    getNotes={getNotes}
                    isOpen={isOpen}
                    handleClose={() => setIsOpen(false)}
                />
            )}
            <Row className='mt-4'>
                <Col>
                    <Row>
                        <Col xs='6'>
                            <h3 className='mb-0'>Notes</h3>
                        </Col>
                        <Col className='text-right' xs='6'>
                            <div
                                className='btn btn-dark btn-sm'
                                color='default'
                                id='tooltip969372949'
                                onClick={() => {
                                    setIsOpen(true);
                                    setSelectedNote(undefined);
                                }}>
                                Add note
                            </div>
                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                Add Note
                            </UncontrolledTooltip>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col className='menu-items'>
                            {notes.map((note, index) => {
                                return (
                                    <NoteItem
                                        userId={user.id}
                                        key={index}
                                        onClick={() => {
                                            setSelectedNote(note);
                                            setIsOpen(true);
                                        }}
                                        notes={notes}
                                        note={note}
                                        getNotes={getNotes}
                                    />
                                );
                            })}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}
