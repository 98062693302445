import { gql } from '@apollo/client';

export const GET_CAMPAIGNS = gql`
    query GetCampaigns {
        campaign(where: { deleted_at: { _is_null: true } }) {
            id
            title
            pageClicks: page_click_count
            interactionsAggregate: interactions_aggregate(where: { deleted_at: { _is_null: true } }) {
                aggregate {
                    sum {
                        salesTotal: sales_total
                        totalBookings: total_bookings
                        completedBookings: total_completed_bookings
                    }
                }
            }
            startDate: start_date
            endDate: end_date
            code
            category {
                value
                title
            }
        }
    }
`;

export const GET_CAMPAIGN_CATEGORIES = gql`
    query GetCategories {
        categories: campaign_category {
            value
            title
        }
    }
`;

export const ADD_OR_UPDATE_CAMPAIGN = gql`
    mutation AddOrUpdateCampaign($campaign: campaign_insert_input!) {
        campaign: insert_campaign_one(
            object: $campaign
            on_conflict: {
                constraint: campaign_pkey
                update_columns: [category_enum, end_date, start_date, title, page_link, description, page_link]
            }
        ) {
            id
        }
    }
`;

export const REMOVE_CAMPAIGN = gql`
    mutation removeCampaign($id: uuid!) {
        campaign: update_campaign_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_CAMPAIGN_DETAILS = gql`
    query GetCampaignDetails($id: uuid!) {
        campaign: campaign_by_pk(id: $id) {
            id
            title
            startDate: start_date
            endDate: end_date
            description
            category {
                value
                title
            }
            code
            pageLink: page_link
        }
        categories: campaign_category {
            value
            title
        }
    }
`;
