/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import { FormControl, LinearProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import { GET_ORDER_DETAILS, UPDATE_ORDER_PAYMENT_STATUS, UPDATE_ORDER_STATUS } from '../../../graphql/orders.graphql';
import useToast from '../../../hooks/useToast';
import { FullOrder, OrderItem, OrderStatus, PaymentStatus } from '../../../models/order.model';
import { OrderItemInfo } from './ components/OrderItemInfo';
import { GENERATE_PROFORMA_INVOICE } from '../../../graphql/quotes.graphql';
import Spinner from '../../../components/Spinner/Spinner';

interface OrderPrice {
    subTotal: string;
    vat: string;
    total: string;
    discount?: string;
}

export const OrderDetails = () => {
    // let history = useHistory();
    const navigate = useNavigate();
    const params: any = useParams();
    const { showToast, Notification } = useToast();

    const [generateProformaInvoice, { data: invoice, error: invoiceError, loading: generatingInvoice }] =
        useMutation(GENERATE_PROFORMA_INVOICE);

    const { loading, data } = useQuery(GET_ORDER_DETAILS, {
        variables: { orderId: params.id },
    });

    const [updateOrderStatus, { called: updatedStatus, loading: isUpdatingStatus }] = useMutation(UPDATE_ORDER_STATUS, {
        // refetchQueries: [{ query: GET_ORDER_DETAILS }, "GetOrderDetails"],
        refetchQueries: ['GetOrderDetails'],
    });

    const [updatePaymentStatus, { called: updatedPaymentStatus, loading: isUpdatingPaymentStatus }] = useMutation(
        UPDATE_ORDER_PAYMENT_STATUS,
        {
            // refetchQueries: [{ query: GET_ORDER_DETAILS }, "GetOrderDetails"],
            refetchQueries: ['GetOrderDetails'],
        },
    );

    useEffect(() => {
        if (invoiceError) {
            showToast('An error has occurred while trying to generate an invoice', 'danger');
        }
    }, [invoiceError]);

    useEffect(() => {
        if (invoice) {
            console.log('PDF data', invoice);
            const byteArray = new Uint8Array(
                atob(invoice.proformaInvoice.pdf)
                    .split('')
                    .map((char) => char.charCodeAt(0)),
            );

            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        }
    }, [invoice]);

    useEffect(() => {
        if (updatedStatus && !isUpdatingStatus) {
            showToast('Successfully updated order status', 'success');
        }
    }, [updatedStatus, isUpdatingStatus]);

    useEffect(() => {
        if (updatedPaymentStatus && !isUpdatingPaymentStatus) {
            showToast('Successfully updated payment status', 'success');
        }
    }, [updatedPaymentStatus, isUpdatingPaymentStatus]);

    if (loading) {
        return <LinearProgress />;
    }

    const order: FullOrder = data.order;

    console.log('ORDER', order);
    const statusses: OrderStatus[] = data.statusses;
    const paymentStatusses: PaymentStatus[] = data.paymentStatusses;

    const getTotalCateringPrice = (items: OrderItem[]): number => {
        let totalPrice = 0;

        items.forEach((item) => {
            item.booking?.menuPackages?.forEach((menu) => {
                totalPrice += menu.totalPrice;
            });
        });

        return totalPrice;
    };

    const getTotalResources = (items: OrderItem[]): number => {
        let totalPrice = 0;
        items.forEach((item) => {
            item.booking?.bookingResources?.forEach((resource) => {
                totalPrice += resource.totalPrice;
            });
        });

        return totalPrice;
    };

    const getTotalRoomAddOnsPrice = (items: OrderItem[]): number => {
        let totalPrice = 0;

        items.forEach((item) => {
            item.booking?.roomExtras?.forEach((extra) => {
                totalPrice += extra.totalPrice;
            });
        });

        return totalPrice;
    };

    const getOrderPrice = (): OrderPrice => {
        let subtotal = 0;
        const filteredItems = order.items.filter((item) => {
            return (
                item.booking?.status?.value !== 'transferred' &&
                item.booking?.status?.value !== 'expired' &&
                item.booking?.status?.value !== 'cancelled'
            );
        });
        filteredItems?.forEach((item) => {
            if (order.account.status.value === 'in_arrears') {
                subtotal += item.totalPrice;
                return;
            }
            subtotal += item.quotedPrice || item.totalPrice;
        });

        subtotal += getTotalCateringPrice(filteredItems);
        subtotal += getTotalResources(filteredItems);
        subtotal += getTotalRoomAddOnsPrice(filteredItems);
        const vat = subtotal * 0.15;
        const total = subtotal + vat;
        return {
            subTotal: `R${subtotal.toFixed(2)}`,
            vat: `R${vat.toFixed(2)}`,
            total: `R${total.toFixed(2)}`,
        };
    };

    const orderPrice: OrderPrice = getOrderPrice();
    const handleStatusChange = (value: SelectChangeEvent) => {
        console.log('STATUS CHANGE', value);
        updateOrderStatus({
            variables: { orderId: order.id, status: value.target.value },
        });
    };

    const handlePaymentStatusChange = (value: SelectChangeEvent) => {
        updatePaymentStatus({
            variables: { orderId: order.id, status: value.target.value },
        });
    };

    return (
        <>
            {Notification}
            <Container fluid={true}>
                <Row className='mt-4'>
                    <Col md='12' lg='8'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <Col xs='3'>
                                        <button
                                            className='btn btn-outline-primary btn-sm mr-4'
                                            onClick={(e) => navigate(-1)}>
                                            <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                            <span className='btn-inner-text'>Back</span>
                                        </button>
                                    </Col>
                                    <Col xs='3'>
                                        <h3 className='m-0'>Order Details - {order.reference}</h3>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md='12' lg='6'>
                                        <CardTitle>Order Information</CardTitle>
                                        <CardText>Date: {moment(order.createdAt).format('yyyy-MM-DD')}</CardText>
                                        <CardText>
                                            Due By: {order.dueBy ? moment(order.dueBy).format('yyyy-MM-DD') : 'Not Set'}
                                        </CardText>
                                        <CardText>
                                            Status:{' '}
                                            <FormControl variant='standard' className='ml-1'>
                                                <Select
                                                    className='bare-dropdown'
                                                    labelId='demo-simple-select-label'
                                                    id='demo-simple-select'
                                                    value={order.status.value}
                                                    label='Status'
                                                    onChange={handleStatusChange}>
                                                    {statusses.map((status) => (
                                                        <MenuItem key={status.value} value={status.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </CardText>
                                        <CardText>
                                            Payment Status:{' '}
                                            <FormControl variant='standard' className='ml-1'>
                                                <Select
                                                    className='bare-dropdown'
                                                    labelId='demo-simple-select-label'
                                                    id='demo-simple-select'
                                                    value={order.paymentStatus?.value}
                                                    label='Payment Status'
                                                    onChange={handlePaymentStatusChange}>
                                                    {paymentStatusses.map((status) => (
                                                        <MenuItem key={status.value} value={status.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </CardText>
                                    </Col>
                                    <Col md='12' lg='6'>
                                        <CardTitle>Account Information</CardTitle>
                                        <CardText> Company: {order.account.company}</CardText>
                                        <CardText> VAT Number: {order.account.vatNumber}</CardText>
                                        <CardText>
                                            {' '}
                                            Name: {`${order.account.user.name} ${order.account.user.surname}`}
                                        </CardText>
                                        <CardText> Email: {order.account.user.email}</CardText>
                                        <CardText> Contact Number: {order.account.user.contactNumber}</CardText>
                                    </Col>
                                    <Col className='mt-4' md='12' lg='12'>
                                        <CardTitle>Order Items</CardTitle>
                                        {order.items
                                            .filter(
                                                (item) =>
                                                    item.booking?.status?.value !== 'transferred' &&
                                                    item.booking?.status?.value !== 'expired' &&
                                                    item.booking?.status?.value !== 'cancelled',
                                            )
                                            .map((item) => {
                                                return (
                                                    <OrderItemInfo
                                                        key={item.id}
                                                        item={item}
                                                        isAccountInArrears={order.account.status.value === 'in_arrears'}
                                                    />
                                                );
                                            })}
                                        <div className='mt-4 text-right'>
                                            <CardText className='order-subtotal'>
                                                Sub Total: {orderPrice.subTotal}
                                            </CardText>
                                            <CardText className='order-subtotal'>VAT: {orderPrice.vat}</CardText>
                                            <h3 className='order-total'>Total: {orderPrice.total}</h3>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
