/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import {
    GET_MANUAL_ORDERS,
    GET_ONLINE_ORDERS,
    // GET_ORDERS,
    REMOVE_ORDER,
} from '../../../graphql/orders.graphql';
import useToast from '../../../hooks/useToast';
import { Order } from '../../../models/order.model';
import { User } from '../../../models/user.model';
import { downloadPdf } from '../../../utils/file.utils';
import { getPermission } from '../../../utils/user.utils';
import _ from 'lodash';
import { useLocalStorage } from '../../../hooks/local-storage.hook';
import { GENERATE_PROFORMA_INVOICE } from '../../../graphql/quotes.graphql';
import LoaderDialog from '../quotes/dialogs/LoaderDialog';

interface OrderRouteData {
    keyWord: string;
    page: number;
    pageSize: number;
    orderTypeFilter: string;
}

export const OrdersList = () => {
    const { showToast, Notification } = useToast();
    const [getLocalStorage, setLocalStorage] = useLocalStorage('order');
    const [alert, setAlert] = useState<any>(null);

    const [getOnlineOrders, { data: onlineOrders, error, loading: loadingOnlineOrders }] = useLazyQuery(
        GET_ONLINE_ORDERS,
        { fetchPolicy: 'network-only' },
    );
    const [getManualOrders, { data: manualOrders, loading: loadingManualOrders }] = useLazyQuery(GET_MANUAL_ORDERS, {
        fetchPolicy: 'network-only',
    });
    const [page, setPage] = useState<number>(0);
    const [orderFilter, setOrderFilter] = useState<string>('');
    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [sortFiled, setSortField] = useState<string>('date');
    const [orderTypeFilter, setOrderTypeFilter] = useState<string>('');
    const [removeOrder, { loading: isRemoving, error: removeError, called }] = useMutation(REMOVE_ORDER, {
        // refetchQueries: ['GetOrders'],
        refetchQueries: ['GetOnlineOrders', 'GetManualOrders'],
    });
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    // const [generateProformaInvoice, { data: invoice, error: invoiceError, loading: generatingInvoice }] =
    //     useMutation(GENERATE_PROFORMA_INVOICE);

    useEffect(() => {
        if (error || removeError) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [error, removeError]);

    // useEffect(() => {
    //     if (invoiceError) {
    //         showToast('An error has occurred while trying to generate an invoice', 'danger');
    //     }
    // }, [invoiceError]);

    // useEffect(() => {
    //     if (invoice) {
    //         console.log('PDF data', invoice);
    //         const byteArray = new Uint8Array(
    //             atob(invoice.proformaInvoice.pdf)
    //                 .split('')
    //                 .map((char) => char.charCodeAt(0)),
    //         );

    //         const blob = new Blob([byteArray], { type: 'application/pdf' });
    //         const url = window.URL.createObjectURL(blob);
    //         window.open(url, '_blank');
    //     }
    // }, [invoice]);

    // const history = useHistory();
    const navigate = useNavigate();

    useEffect(() => {
        const routeData: OrderRouteData = getLocalStorage();

        // loadOrders();
        if (routeData) {
            setPage(routeData?.page || 0);
            setRowsPerPage(routeData?.pageSize || 10);
            setOrderFilter(routeData?.keyWord || '');
            setOrderTypeFilter(routeData?.orderTypeFilter || 'online');
        } else {
            setOrderTypeFilter('online');
        }
    }, []);

    useEffect(() => {
        if (!isRemoving && called && !removeError) {
            showToast('Successfully removed  order', 'success');
        }
    }, [isRemoving, called]);

    useEffect(() => {
        console.log('ALERT', alert);
    }, [alert]);

    const sortBy: any = (orders: Order[], field: string, direction: string) => {
        const orderedOrders = new Map([
            ['client', _.orderBy(orders, (order) => order?.account.company, [direction === 'asc' ? 'asc' : 'desc'])],
            ['date', _.orderBy(orders, (order) => moment(order?.createdAt), [direction === 'asc' ? 'asc' : 'desc'])],
            ['reference', _.orderBy(orders, (order) => order?.reference, [direction === 'asc' ? 'asc' : 'desc'])],
            ['status', _.orderBy(orders, (order) => order?.status?.title, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'paymentStatus',
                _.orderBy(orders, (order) => order.paymentStatus?.title, [direction === 'asc' ? 'asc' : 'desc']),
            ],
        ]);
        return orderedOrders.get(field);
    };

    let orders: Order[] = [];

    if (orderTypeFilter === 'online') {
        orders = onlineOrders?.orders || [];
    } else if (orderTypeFilter === 'manual') {
        orders = manualOrders?.orders || [];
    } else {
        orders = [];
    }

    let filteredOrders = orders?.filter((order: Order) => {
        const isOrderFiltered =
            order?.reference?.toLowerCase().includes(orderFilter.toLowerCase()) ||
            order?.account?.company?.toLowerCase().includes(orderFilter.toLowerCase()) ||
            order?.account?.user?.email.toLowerCase().includes(orderFilter.toLowerCase());
        return isOrderFiltered;
    });

    filteredOrders = sortBy(filteredOrders, sortFiled, sortDirection);

    // console.log('Orders', orders);

    useEffect(() => {
        if (orderTypeFilter === 'online') {
            const orderRouteData: OrderRouteData = getLocalStorage() || {};
            orderRouteData.orderTypeFilter = 'online';
            setLocalStorage(orderRouteData);
            getOnlineOrders();
            return;
        }

        if (orderTypeFilter === 'manual') {
            const orderRouteData: OrderRouteData = getLocalStorage() || {};
            orderRouteData.orderTypeFilter = 'manual';
            setLocalStorage(orderRouteData);
            getManualOrders();
            return;
        }
    }, [orderTypeFilter]);

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeOrder({
                        variables: { orderId: props.order.id },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This order will be removed from the system.
            </SweetAlert>,
        );
    };

    const getRowValue = (value: string) => {
        setRowsPerPage(parseInt(value));
        const newOrderRouteData: OrderRouteData = getLocalStorage() || {};
        newOrderRouteData.pageSize = parseInt(value);
        if (parseInt(value) !== rowPerPage) {
            setPage(0);
            newOrderRouteData.page = 0;
        }
        setLocalStorage(newOrderRouteData);
    };

    const nextPage = () => {
        setPage(page + 1);
        const newOrderRouteData: OrderRouteData = getLocalStorage() || {};
        newOrderRouteData.page = page + 1;
        setLocalStorage(newOrderRouteData);
    };
    const previousPage = () => {
        setPage(page - 1);
        const newOrderRouteData: OrderRouteData = getLocalStorage() || {};
        newOrderRouteData.page = page - 1;
        setLocalStorage(newOrderRouteData);
    };

    const downloadInvoice = (path: string, orderId: string) => {
        const url = `${process.env.REACT_APP_IMAGE_URL}/${path}`;
        downloadPdf(url, `${orderId}.pdf`);
    };

    if (loadingManualOrders || loadingOnlineOrders) {
        return <LinearProgress />;
    }

    return (
        <>
            {Notification}
            {alert}
            {/* <LoaderDialog isOpen={generatingInvoice} /> */}
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Orders</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Orders</h3>
                                    </Col>
                                    <Col xs='6' className='text-right'>
                                        <div>
                                            <button
                                                className={
                                                    orderTypeFilter === 'online'
                                                        ? 'btn btn-round btn-sm btn-dark'
                                                        : ' btn btn-sm btn-outline-primary'
                                                }
                                                onClick={() => {
                                                    setOrderTypeFilter('online');
                                                }}
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'>Online Orders</span>
                                            </button>
                                            <button
                                                className={
                                                    orderTypeFilter === 'manual'
                                                        ? 'btn btn-round btn-sm btn-dark'
                                                        : ' btn btn-sm btn-outline-primary'
                                                }
                                                onClick={() => {
                                                    setOrderTypeFilter('manual');
                                                }}
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'>Manual Orders</span>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-4 border-0'>
                                    <Col>
                                        <Input
                                            className='form-control w-25'
                                            type='text'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setOrderFilter(e.target.value);
                                                setPage(0);

                                                const newOrderRouteData: OrderRouteData = getLocalStorage() || {};
                                                newOrderRouteData.keyWord = e.target.value;
                                                newOrderRouteData.page = 0;
                                                setLocalStorage(newOrderRouteData);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('client');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='client'
                                            scope='col'>
                                            Client
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('date');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='date'
                                            scope='col'>
                                            Date
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('reference');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='reference'
                                            scope='col'>
                                            Reference
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Status
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('paymentStatus');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='paymentStatus'
                                            scope='col'>
                                            Payment Status
                                        </th>
                                        <th scope='col' />
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredOrders
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((order: Order) => {
                                            return (
                                                <tr
                                                    key={order.id}
                                                    onClick={() => navigate(`/admin/orders/details/${order.id}`)}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}>
                                                    <td>{order?.account?.company || order.account?.individualName}</td>
                                                    <td>{moment(order.createdAt).format('yyyy-MM-DD')}</td>
                                                    <td>{order.reference}</td>
                                                    <td>{order.status?.title ?? ''}</td>
                                                    <td>{order.paymentStatus?.title ?? ''}</td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'orders', 'read') && (
                                                            <Link
                                                                className='btn btn-info btn-sm'
                                                                to={`/admin/orders/details/${order.id}`}>
                                                                View
                                                            </Link>
                                                        )}
                                                        {getPermission(user, 'orders', 'read') && (
                                                            <>
                                                                <Button
                                                                    className='btn btn-outlined-info btn-sm'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();

                                                                        if (order.invoicePath) {
                                                                            downloadInvoice(
                                                                                order.invoicePath,
                                                                                order.id,
                                                                            );
                                                                        }
                                                                    }}
                                                                    disabled={!order.invoicePath}>
                                                                    Invoice
                                                                </Button>

                                                                {/* {order.paymentStatus.value === 'pending' &&
                                                                    order.quote && (
                                                                        <Button
                                                                            className='btn btn-outlined-info btn-sm'
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();

                                                                                generateProformaInvoice({
                                                                                    variables: {
                                                                                        quoteId: order.quote.id,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            disabled={!order.invoicePath}>
                                                                            Proforma Invoice
                                                                        </Button>
                                                                    )} */}
                                                            </>
                                                        )}
                                                        {getPermission(user, 'orders', 'delete') && (
                                                            <Button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, order });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredOrders.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredOrders.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
