import { useItem } from 'dnd-timeline';
import { Span } from 'dnd-timeline';
import React from 'react';

interface ItemProps {
    id: string;
    span: Span;
    children: React.ReactNode;
}

export default function Item({ id, span, children }: ItemProps) {
    const { setNodeRef, attributes, listeners, itemStyle, itemContentStyle } = useItem({
        id: id,
        span: span,
    });

    return (
        <div className='timeline-item' ref={setNodeRef} style={itemStyle} {...listeners} {...attributes}>
            <div style={itemContentStyle}>
                <div>{children}</div>
            </div>
        </div>
    );
}
