import { groupItemsToSubrows, ItemDefinition, RowDefinition, useTimelineContext } from 'dnd-timeline';
import React, { useMemo } from 'react';
import Row from './Row';
import Sidebar from './Sidebar';
import Item from './Item';
import SubRow from './Subrow';
import TimeAxis, { MarkerDefinition } from './TimeAxis';
import TimeCursor from './TimeCursor';
import { hoursToMilliseconds, format, minutesToMilliseconds } from 'date-fns';
const timeAxisMarkers: MarkerDefinition[] = [
    {
        value: hoursToMilliseconds(24),
        getLabel: (date: Date) => format(date, 'E'),
    },
    {
        value: hoursToMilliseconds(2),
        minRangeSize: hoursToMilliseconds(24),
        getLabel: (date: Date) => format(date, 'k'),
    },
    {
        value: hoursToMilliseconds(1),
        minRangeSize: hoursToMilliseconds(24),
    },
    {
        value: hoursToMilliseconds(1),
        maxRangeSize: hoursToMilliseconds(24),
        getLabel: (date: Date) => format(date, 'k'),
    },
    {
        value: minutesToMilliseconds(30),
        maxRangeSize: hoursToMilliseconds(24),
        minRangeSize: hoursToMilliseconds(12),
    },
    {
        value: minutesToMilliseconds(15),
        maxRangeSize: hoursToMilliseconds(12),
        getLabel: (date: Date) => format(date, 'm'),
    },
    {
        value: minutesToMilliseconds(5),
        maxRangeSize: hoursToMilliseconds(6),
        minRangeSize: hoursToMilliseconds(3),
    },
    {
        value: minutesToMilliseconds(5),
        maxRangeSize: hoursToMilliseconds(3),
        getLabel: (date: Date) => format(date, 'm'),
    },
    {
        value: minutesToMilliseconds(1),
        maxRangeSize: hoursToMilliseconds(2),
    },
];

interface Props {
    rows: RowDefinition[];
    items: ItemDefinition[];
}

export default function Timeline(props: Props) {
    const { setTimelineRef, style, range } = useTimelineContext();

    const groupedSubrows = useMemo(() => groupItemsToSubrows(props.items, range), [props.items, range]);

    return (
        <div ref={setTimelineRef} style={style}>
            <TimeAxis markers={timeAxisMarkers} />
            <TimeCursor />
            <div className='mb-4'></div>
            {props.rows.map((row) => (
                <Row id={row.id} key={row.id} sidebar={<Sidebar row={row} />}>
                    {groupedSubrows[row.id]?.map((subrow, index) => (
                        <SubRow key={`${row.id}-${index}`}>
                            {subrow.map((item) => {
                                const accountName = item.id;
                                return (
                                    <Item id={item.id} key={item.id} span={item.span}>
                                        <span className='mb-4'>{`${accountName}`}</span>
                                    </Item>
                                );
                            })}
                        </SubRow>
                    ))}
                </Row>
            ))}
        </div>
    );
}
