/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
// import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row, Spinner } from 'reactstrap';
import FeaturedImage from '../../../components/Image component/ImageContainer';
import { useMutation, useQuery } from '@apollo/client';
import {
    ADD_VENDOR,
    GET_VENDORS,
    // GET_VENDORS,
    GET_VENDOR_RESOURCES,
} from '../../../graphql/vendors.graphql';
import { uploadImage } from '../../../hooks/upload-image.hook';
import useToast from '../../../hooks/useToast';
import { VendorStatus, VendorType } from '../../../models/vendor.model';
import { Location } from '../../../models/location.model';
import { useNavigate } from 'react-router-dom';

type FormData = {
    name: string;
    registrationNumber: string;
    vatNumber: string;
    code?: string;
    location: string;
    deliveryFee?: number;
    description: string;
    status: string;
    type: string;
};

export default function AddVendorForm() {
    const navigate = useNavigate();
    const [imageFile, setImageFile] = useState<any>();
    const [image, setImage] = useState<string>();
    const fileInputRef: any = useRef(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showToast, Notification } = useToast();
    const { data, loading, error } = useQuery(GET_VENDOR_RESOURCES);
    const [addVendorMutation, { error: addingError, data: addedVendor }] = useMutation(ADD_VENDOR, {
        refetchQueries: [{ query: GET_VENDORS }],
    });

    useEffect(() => {
        if (error) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (addingError) {
            showToast('An error has occurred while trying to add this vendor', 'danger');
            setIsLoading(false);
        }
    }, [addingError]);

    useEffect(() => {
        if (addedVendor) {
            showToast('Vendor added successfully', 'success');
            reset();
            navigate(`/admin/vendors/manage/${addedVendor.vendor.id}`);
            setIsLoading(false);
        }
    }, [addedVendor]);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>();

    const onSubmit = async (data: FormData) => {
        console.log('data :', data);
        if (!imageFile) {
            showToast('Please make sure that an image file has been selected', 'danger');
            return;
        }

        setIsLoading(true);

        const imageKey = await uploadImage(imageFile, 'product_images');
        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload the image', 'danger');
            return;
        }

        setIsLoading(false);

        const vendor = {
            name: data.name,
            registration_number: data.registrationNumber,
            vat_number: data.vatNumber,
            description: data.description,
            location_id: data?.location,
            status_enum: data.status,
            type_enum: data.type,
            code: data?.code || null,
            delivery_fee: data?.deliveryFee || null,
            image_path: imageKey,
            deleted_at: null,
        };
        addVendorMutation({
            variables: { vendor },
        });
    };

    const redirectToVendors = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    const onImageChange = (e: any) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    const vendorTypes: VendorType[] = data?.vendorType;
    const vendorStatusList: VendorStatus[] = data?.vendorStatus;
    const locations: Location[] = data?.location;

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => redirectToVendors(e)}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>
                                    <h3 className='m-0'>Add Vendor</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <label className='form-control-label'>Featured Image</label>
                                        <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
                                        <input
                                            ref={fileInputRef}
                                            type='file'
                                            accept='image/*'
                                            onChange={(e) => {
                                                onImageChange(e);
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                    </FormGroup>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Name
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('name', { required: true })}
                                                    id='name'
                                                    placeholder="Enter vendor's name..."
                                                    type='text'
                                                />
                                                {errors.name && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='registrationNumber'>
                                                    Registration number
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('registrationNumber', {
                                                        required: true,
                                                    })}
                                                    id='registrationNumber'
                                                    placeholder="Enter vendor's registration number..."
                                                    type='text'
                                                />
                                                {errors.registrationNumber && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Code(Optional)
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('code', { required: false })}
                                                    id='name'
                                                    placeholder="Enter vendor's code..."
                                                    type='text'
                                                />
                                                {errors.name && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Delivery fee(Optional)
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('deliveryFee', { required: false })}
                                                    id='name'
                                                    placeholder="Enter vendor's delivery fee..."
                                                    type='number'
                                                />
                                                {errors.name && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='vatNumber'>
                                                    VAT number
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('vatNumber', { required: true })}
                                                    id='vatNumber'
                                                    placeholder="Enter vendor's vat number..."
                                                    type='text'
                                                />
                                                {errors.vatNumber && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='status'>
                                                    Status
                                                </label>
                                                <Select
                                                    id='status'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    className='form-control'
                                                    defaultValue={' '}
                                                    inputProps={{
                                                        ...register('status', {
                                                            required: true,
                                                        }),
                                                    }}>
                                                    <MenuItem disabled value=' '>
                                                        Select status...
                                                    </MenuItem>
                                                    {vendorStatusList?.map((status: any) => {
                                                        return (
                                                            <MenuItem key={status.value} value={status.value}>
                                                                {status.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='type'>
                                                    Type
                                                </label>
                                                <Select
                                                    id='type'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    className='form-control'
                                                    defaultValue={' '}
                                                    inputProps={{
                                                        ...register('type', {
                                                            required: true,
                                                        }),
                                                    }}>
                                                    <MenuItem disabled value=' '>
                                                        Select type...
                                                    </MenuItem>
                                                    {vendorTypes?.map((type) => {
                                                        return (
                                                            <MenuItem key={type.value} value={type.value}>
                                                                {type.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='type'>
                                                    Location
                                                </label>
                                                <Select
                                                    id='type'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    className='form-control'
                                                    defaultValue={' '}
                                                    inputProps={{
                                                        ...register('location', {
                                                            required: true,
                                                        }),
                                                    }}>
                                                    <MenuItem disabled value=' '>
                                                        Select location...
                                                    </MenuItem>
                                                    {locations?.map((location) => {
                                                        return (
                                                            <MenuItem key={location.id} value={location.id}>
                                                                {location.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='description'>
                                                    Description
                                                </label>
                                                <textarea
                                                    className='form-control'
                                                    {...register('description', { required: true })}
                                                    id='description'
                                                    placeholder="Enter vendor's description..."></textarea>
                                                {errors.description && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={isLoading}>
                                                {!isLoading ? (
                                                    <span>Add Vendor</span>
                                                ) : (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
