/* eslint-disable react-hooks/exhaustive-deps */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
import { Autocomplete, LinearProgress, MenuItem, Select, TextField } from '@mui/material';
import ReactDatetimeClass from 'react-datetime';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ADD_OR_UPDATE_VOUCHER, GET_VOUCHERS, GET_VOUCHER_ADD_VIEW_DATA } from '../../../../graphql/vouchers.graphql';
import { useForm } from 'react-hook-form';
import { VoucherCategory, VoucherDiscountType, VoucherStatus } from '../../../../models/voucher.models';
import Spinner from '../../../../components/Spinner/Spinner';
import useToast from '../../../../hooks/useToast';
// import { useHistory } from "react-router-dom";
import { AccountSearchQueryResult } from '../../../../models/client.model';
import { SEARCH_ACCOUNTS } from '../../../../graphql/clients.graphql';
import { MeetingRoom } from '../../../../models/meeting.room.model';
import { Product } from '../../../../models/product.model';
import { useNavigate } from 'react-router-dom';

type FormData = {
    title: string;
    startingDate: string;
    expiryDate: string;
    status: string;
    discountType: string;
    discount: number;
    useLimit: number;
    category: string;
    clientId: string;
    termsAndConditions: string;
    meetingRoomId: string;
    productPackageId: string;
};

const VoucherAddForm = () => {
    const [addVoucher, { data, loading, error }] = useMutation(ADD_OR_UPDATE_VOUCHER, {
        refetchQueries: [{ query: GET_VOUCHERS }],
    });

    const [accounts, setAccounts] = useState<AccountSearchQueryResult[]>([]);
    const [query, setQuery] = useState<string>();
    const [searchAccounts, { data: accountData, called }] = useLazyQuery(SEARCH_ACCOUNTS);

    const { showToast, Notification } = useToast();
    const navigate = useNavigate();

    const { data: viewData, loading: loadingData, error: viewDataError } = useQuery(GET_VOUCHER_ADD_VIEW_DATA);

    const [description, setDescription] = useState<string>('');

    useEffect(() => {
        if (viewDataError) {
            console.log('An error', viewDataError);
        }

        if (error) {
            console.log('Adding error', error);
        }

        if (data) {
            showToast('Successfully added a voucher', 'success');
            reset();
            navigate(`/admin/vouchers/manage/${data.voucher.id}`);
        }
    }, [viewDataError, error, data]);

    useEffect(() => {
        if (query && query.length >= 3) {
            searchAccounts({ variables: { query: `%${query}%` } });
        }

        if (called && accountData) {
            setAccounts(accountData.accounts);
        }
    }, [query, accountData]);

    const redirectToVouchers = () => {
        navigate(-1);
    };

    const onSubmit = (data: FormData) => {
        if (data.discountType === 'empty') {
            setError('discountType', {
                type: 'custom',
                message: '*This field is required',
            });
        }

        if (data.status === 'empty') {
            setError('status', {
                type: 'custom',
                message: '*This field is required',
            });
        }

        // if (data.clientId === "") {
        //   setError("clientId", {
        //     type: "custom",
        //     message: "*This field is required",
        //   });
        // }

        if (data.category === 'empty') {
            setError('category', {
                type: 'custom',
                message: '*This field is required',
            });
        }

        if (data.productPackageId === 'empty' && data.category === 'spaces') {
            setError('productPackageId', {
                type: 'custom',
                message: '*This field is required',
            });
        }

        if (data.meetingRoomId === 'empty' && data.category === 'conference') {
            setError('productPackageId', {
                type: 'custom',
                message: '*This field is required',
            });
        }

        const voucherData = {
            title: data.title,
            expiry_date: data.expiryDate,
            status_enum: data.status,
            use_limit: data.useLimit,
            discount_value: data.discount,
            discount_type_enum: data.discountType,
            voucher_catergory_enum: data.category,
            starting_date: data.startingDate,
            terms_and_conditions: data.termsAndConditions,
            description: description,
            account_id: data.clientId || undefined,
            meeting_room_id: data.category === 'conference' ? data.meetingRoomId : undefined,
            product_package_id: data.category === 'spaces' ? data.productPackageId : undefined,
        };

        addVoucher({
            variables: {
                voucher: voucherData,
            },
        });
    };

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        setError,
        watch,
        formState: { errors },
    } = useForm<FormData>();

    if (loadingData) {
        return <LinearProgress />;
    }

    const statusList: VoucherStatus[] = viewData?.status;
    const voucherDiscountTypes: VoucherDiscountType[] = viewData?.types;
    const categories: VoucherCategory[] = viewData?.categories;
    const meetingRooms: MeetingRoom[] = [...viewData?.meetingRoom];
    const productPackages: Product[] = viewData?.productPackages;

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => redirectToVouchers()}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>
                                    <h3 className='m-0'>Add Voucher</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Title
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('title', { required: true })}
                                            id='product'
                                            placeholder="Enter voucher's title..."
                                            type='text'
                                        />
                                        {errors.title && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Client(Optional)
                                        </label>
                                        <Autocomplete
                                            id='client'
                                            {...register('clientId', {
                                                required: false,
                                            })}
                                            onChange={(event, value) => {
                                                const _value: any = value?.id;
                                                setValue('clientId', _value);
                                            }}
                                            onInputChange={(event, value) => {
                                                setQuery(value);
                                            }}
                                            filterOptions={(options) => options}
                                            options={accounts}
                                            getOptionLabel={(option) => option.companyName || option.individualName}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder='Assign client to voucher...'
                                                    fullWidth={true}
                                                    variant='outlined'
                                                    style={{ fontSize: '14px' }}
                                                />
                                            )}
                                        />
                                        {/* {errors.clientId && (
                      <span className="invalid">{errors.clientId.message}</span>
                    )} */}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='description'>
                                            Description
                                        </label>
                                        <CKEditor
                                            id='description'
                                            editor={ClassicEditor}
                                            data={''}
                                            config={{
                                                removePlugins: ['Heading'],
                                                toolbar: [
                                                    'bold',
                                                    'italic',
                                                    'bulletedList',
                                                    'numberedList',
                                                    'blockQuote',
                                                    'link',
                                                ],
                                            }}
                                            onChange={(event: any, editor: any) => {
                                                setDescription(editor.getData());
                                            }}
                                            onReady={(editor: any) => {}}
                                        />
                                    </FormGroup>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Starting date
                                                </label>
                                                <ReactDatetimeClass
                                                    inputProps={{
                                                        placeholder: 'Enter starting date...',
                                                        autoComplete: 'off',
                                                        ...register('startingDate', { required: true }),
                                                    }}
                                                    dateFormat={'YYYY-MM-DD'}
                                                    onChange={(date: moment.Moment) => {
                                                        setValue('startingDate', date.format('YYYY-MM-DD'));
                                                    }}
                                                    timeFormat={false}
                                                />
                                                {errors.startingDate && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Expiry date
                                                </label>
                                                <ReactDatetimeClass
                                                    inputProps={{
                                                        placeholder: 'Enter expiry date...',
                                                        autoComplete: 'off',
                                                        ...register('expiryDate', { required: true }),
                                                    }}
                                                    dateFormat={'YYYY-MM-DD'}
                                                    onChange={(date: moment.Moment) => {
                                                        setValue('expiryDate', date.format('YYYY-MM-DD'));
                                                    }}
                                                    timeFormat={false}
                                                />
                                                {errors.expiryDate && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='status'>
                                                    Status
                                                </label>
                                                <Select
                                                    id='status'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    className='form-control'
                                                    placeholder='Select a role'
                                                    defaultValue={'empty'}
                                                    inputProps={{
                                                        ...register('status', {
                                                            required: true,
                                                        }),
                                                    }}>
                                                    <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                        Select voucher status...
                                                    </MenuItem>
                                                    {statusList?.map((status) => {
                                                        return (
                                                            <MenuItem key={status.value} value={status.value}>
                                                                {status.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                {errors.status && (
                                                    <span className='invalid'>{errors.status.message}</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='type'>
                                                    Discount type
                                                </label>
                                                <Select
                                                    id='type'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    className='form-control'
                                                    placeholder='Select a role'
                                                    defaultValue={'empty'}
                                                    inputProps={{
                                                        ...register('discountType', {
                                                            // required: true,
                                                        }),
                                                    }}>
                                                    <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                        Select voucher discount type...
                                                    </MenuItem>
                                                    {voucherDiscountTypes.map((type) => {
                                                        return (
                                                            <MenuItem key={type.value} value={type.value}>
                                                                {type.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                {errors.discountType && (
                                                    <span className='invalid'>{errors.discountType.message}</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Discount
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id='product'
                                                    placeholder="Enter voucher's title..."
                                                    step={0.01}
                                                    {...register('discount', { required: true })}
                                                    type='number'
                                                />
                                                {errors.discount && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Use limit
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id='product'
                                                    {...register('useLimit', { required: true })}
                                                    placeholder="Enter voucher's title..."
                                                    type='number'
                                                />
                                                {errors.useLimit && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='status'>
                                            Category
                                        </label>
                                        <Select
                                            id='status'
                                            sx={{ borderRadius: '12px', padding: '0' }}
                                            className='form-control'
                                            placeholder='Select a role'
                                            defaultValue={'empty'}
                                            inputProps={{
                                                ...register('category', {
                                                    required: true,
                                                }),
                                            }}>
                                            <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                Select voucher category...
                                            </MenuItem>
                                            {categories.map((category) => {
                                                return (
                                                    <MenuItem key={category.value} value={category.value}>
                                                        {category.title}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {errors.category && <span className='invalid'>{errors.category.message}</span>}
                                    </FormGroup>
                                    {watch('category') === 'spaces' && (
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='status'>
                                                Package
                                            </label>
                                            <Select
                                                id='status'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                placeholder='Select a role'
                                                defaultValue={'empty'}
                                                inputProps={{
                                                    ...register('productPackageId', {
                                                        required: watch('category') === 'spaces',
                                                    }),
                                                }}>
                                                <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                    Select a product package...
                                                </MenuItem>
                                                {productPackages.map((productPackage) => {
                                                    return (
                                                        <MenuItem key={productPackage.id} value={productPackage.id}>
                                                            {productPackage.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.productPackageId && (
                                                <span className='invalid'>{errors.category.message}</span>
                                            )}
                                        </FormGroup>
                                    )}

                                    {watch('category') === 'conference' && (
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='status'>
                                                Meeting room
                                            </label>
                                            <Select
                                                id='status'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                placeholder='Select a role'
                                                defaultValue={'empty'}
                                                inputProps={{
                                                    ...register('meetingRoomId', {
                                                        required: watch('category') === 'conference',
                                                    }),
                                                }}>
                                                <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                    Select a meeting room...
                                                </MenuItem>
                                                {meetingRooms
                                                    ?.sort((a, b) => {
                                                        return a.name.localeCompare(b.name, undefined, {
                                                            numeric: true,
                                                            sensitivity: 'base',
                                                        });
                                                    })
                                                    .map((room) => {
                                                        return (
                                                            <MenuItem key={room.id} value={room.id}>
                                                                {room.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                            {errors.meetingRoomId && (
                                                <span className='invalid'>{errors.category.message}</span>
                                            )}
                                        </FormGroup>
                                    )}

                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Terms and Conditions
                                        </label>
                                        <input
                                            className='form-control'
                                            id='product'
                                            {...register('termsAndConditions', { required: true })}
                                            placeholder="Enter voucher's terms and conditions..."
                                            type='text'
                                        />
                                        {errors.termsAndConditions && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>

                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button type='submit' className='btn btn-dark mt-4' disabled={loading}>
                                                {!loading && <span>Add</span>}
                                                {loading && <Spinner />}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default VoucherAddForm;
