/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import useToast from '../../../hooks/useToast';
import { User } from '../../../models/user.model';

import { getPermission } from '../../../utils/user.utils';
import _ from 'lodash';
import { Vouchers } from '../../../models/voucher.models';
import { DELETE_VOUCHERS, GET_VOUCHERS } from '../../../graphql/vouchers.graphql';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useLocalStorage } from '../../../hooks/local-storage.hook';

interface VoucherRouteData {
    keyWord: string;
    page: number;
    pageSize: number;
}

const VoucherListView = () => {
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const { data, loading, error } = useQuery(GET_VOUCHERS);
    const [getLocalStorage, setLocalStorage] = useLocalStorage('voucher');
    const [removeVoucher, { data: removedVoucher, error: removeError }] = useMutation(DELETE_VOUCHERS, {
        refetchQueries: [{ query: GET_VOUCHERS }],
    });
    const { showToast, Notification } = useToast();
    const [alert, setAlert] = useState<any>(null);
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [searchKey, setSearchKey] = useState<string>('');
    const [sortFiled, setSortField] = useState<string>('title');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const navigate = useNavigate();

    const sortBy: any = (vouchers: Vouchers[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['title', _.orderBy(vouchers, (voucher) => voucher.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['code', _.orderBy(vouchers, (voucher) => voucher.title, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'expiryDate',
                _.orderBy(vouchers, (voucher) => voucher.expiryDate, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'discountType',
                _.orderBy(vouchers, (voucher) => voucher.discountType.title, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'category',
                _.orderBy(vouchers, (voucher) => voucher.category.title, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            ['status', _.orderBy(vouchers, (voucher) => voucher.status.title, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'discountValue',
                _.orderBy(vouchers, (voucher) => voucher.discountValue, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'useCounter',
                _.orderBy(vouchers, (voucher) => voucher.useCounter, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            ['useLimit', _.orderBy(vouchers, (voucher) => voucher.useLimit, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    useEffect(() => {
        const routeData: VoucherRouteData = getLocalStorage();

        if (routeData) {
            setPage(routeData?.page || 0);
            setRowPerPage(routeData?.pageSize || 10);
            setSearchKey(routeData?.keyWord || '');
        }
    }, []);

    useEffect(() => {
        if (error) {
            console.log('Error', error);
        }

        if (removeError) {
            showToast('An error has occurred while trying to remove this voucher', 'danger');
        }
    }, [error, removeError]);

    useEffect(() => {
        if (removedVoucher) {
            showToast('Successfully removed voucher', 'success');
        }
    }, [removedVoucher]);

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeVoucher({
                        variables: {
                            id: props.voucher.id,
                        },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This voucher will be removed.
            </SweetAlert>,
        );
    };

    const nextPage = () => {
        setPage(page + 1);
        const routeData: VoucherRouteData = getLocalStorage() || {};
        routeData.pageSize = page + 1;
        setLocalStorage(routeData);
    };

    const previousPage = () => {
        setPage(page - 1);
        const routeData: VoucherRouteData = getLocalStorage() || {};
        routeData.pageSize = page - 1;
        setLocalStorage(routeData);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
        const routeData: VoucherRouteData = getLocalStorage() || {};
        routeData.pageSize = parseInt(value);
        setLocalStorage(routeData);
    };

    const formatDiscountAmount = (amount: number, discountType: string): string => {
        if (discountType === 'fixed_value') {
            return `R${amount.toFixed(2)}`;
        }

        return `${amount}%`;
    };

    const vouchers: Vouchers[] = data?.voucher || [];

    let filteredVouchers = vouchers?.filter((vendor) => {
        if (searchKey === '') {
            return vendor;
        }

        return (
            vendor.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            vendor.category.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            vendor.code.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            vendor.discountType.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            vendor.category.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            vendor.status.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });

    filteredVouchers = sortBy(filteredVouchers, sortFiled, sortDirection);

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <>
            {alert}
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Vouchers</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Vouchers</h3>
                                    </Col>
                                    {getPermission(user, 'locations', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <Link
                                                to={'/admin/vouchers/new'}
                                                className=' btn btn-round btn-dark btn-sm'
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'>Add voucher</span>
                                            </Link>
                                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                Add vendor
                                            </UncontrolledTooltip>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col>
                                        <Input
                                            className='w-25'
                                            value={searchKey}
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                                setPage(0);

                                                const routeData: VoucherRouteData = getLocalStorage() || {};
                                                routeData.keyWord = e.target.value;
                                                routeData.page = 0;
                                                setLocalStorage(routeData);
                                            }}></Input>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('title');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='name'
                                            scope='col'>
                                            Title
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('code');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='description'
                                            scope='col'>
                                            Code
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('expiryDate');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='registration_number'
                                            scope='col'>
                                            Expiry Date
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='vat_number'
                                            scope='col'>
                                            Status
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('discountType');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Type
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('category');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Category
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('discountValue');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Amount
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('useCounter');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Used
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('useLimit');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Use limit
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredVouchers
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((voucher: Vouchers) => {
                                            return (
                                                <tr
                                                    key={voucher.id}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => navigate(`/admin/vouchers/manage/${voucher.id}`)}>
                                                    <td>{`${voucher.title}`}</td>
                                                    <td>{voucher.code}</td>
                                                    <td>{moment(voucher.expiryDate).format('LL')}</td>
                                                    <td>{voucher.status.title}</td>
                                                    <td>{voucher.discountType.title}</td>
                                                    <td>{voucher.category.title}</td>
                                                    <td>
                                                        {formatDiscountAmount(
                                                            voucher.discountValue,
                                                            voucher.discountType.value,
                                                        )}
                                                    </td>
                                                    <td>{voucher.useCounter}</td>
                                                    <td>{voucher.useLimit}</td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'locations', 'update') && (
                                                            <Link
                                                                className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                                to={`/admin/vouchers/manage/${voucher.id}`}>
                                                                <i className='fa-solid fa-pencil'></i>
                                                            </Link>
                                                        )}
                                                        {getPermission(user, 'locations', 'delete') && (
                                                            <Button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, voucher });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredVouchers.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredVouchers.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default VoucherListView;
