import React, { useEffect, useRef, useState } from 'react';
import { ADD_SURVEY, GET_SURVEY } from '../../../../graphql/survey.graphql';
import { Button, Card, CardBody, CardHeader, Col, Row, UncontrolledTooltip } from 'reactstrap';
import Spinner from '../../../../components/Spinner/Spinner';
import { useForm } from 'react-hook-form';
import { Checkbox, FormControlLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import { Survey, SurveyQuestion, SurveyQuestionItemType, SurveyStatus } from '../../../../models/survey.modesl';
import QuestionSection from './QuestionSection';
import { v4 } from 'uuid';
import { useMutation, useQuery } from '@apollo/client';
import useToast from '../../../../hooks/useToast';
import { useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type FormData = {
    title: string;
    intro: string;
    outro: string;
    status: string;
};

export default function SurveyEditFormView() {
    const {
        handleSubmit,
        register,
        setError,
        formState: { errors },
    } = useForm<FormData>();
    const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
    const params: any = useParams();
    const { showToast, Notification } = useToast();
    const { data, loading, error } = useQuery(GET_SURVEY, {
        variables: {
            id: params?.id,
        },
    });

    const [isAnOpenSurvey, setIsAnOpenSurvey] = useState<boolean>(false);
    const [surveyLink, setSurveyLink] = useState<string>();
    const urlInputRef = useRef(null);
    const [updateSurvey, { data: updatedSurvey, loading: updatingSUrvey, error: updateError }] = useMutation(
        ADD_SURVEY,
        {
            refetchQueries: [GET_SURVEY],
        },
    );

    useEffect(() => {
        if (updatedSurvey) {
            showToast('Successfully update survey', 'success');
        }
    }, [updatedSurvey]);

    useEffect(() => {
        if (updateError) {
            showToast('An error has occurred while trying to update this survey', 'danger');
        }
    }, [updateError]);

    const onQuestionChange = (questions: SurveyQuestion[]) => {
        setQuestions(questions);
    };

    useEffect(() => {
        if (data) {
            setIsAnOpenSurvey(data?.survey?.isOpen);
            const questions: SurveyQuestion[] = data?.survey.questions?.map((question) => {
                return {
                    id: question?.id,
                    key: question?.id,
                    order: question?.order,
                    title: question?.title,
                    subtitle: question?.subtitle,
                    items: question?.items?.map((item: any) => {
                        return {
                            id: item?.id,
                            key: item?.id,
                            title: item?.title,
                            required: item?.required,
                            type: item.type,
                            listOptions: item?.listOptions?.map((option) => {
                                return {
                                    id: option?.id,
                                    key: option?.id,
                                    value: option?.value,
                                };
                            }),
                            dropDownOptions: item?.dropdownOptions?.map((option) => {
                                return {
                                    id: option?.id,
                                    key: option?.id,
                                    value: option?.value,
                                };
                            }),
                        };
                    }),
                };
            });

            setQuestions(questions);
            setSurveyLink(`https://colab-web-dev.vercel.app/survey/${data?.survey?.id}`);
        }
    }, [data]);

    const onAddQuestion = () => {
        const newQuestion: SurveyQuestion = {
            title: '',
            items: [],
            order: questions.length + 1,
            key: v4(),
        };

        setQuestions([...questions, newQuestion]);
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying load this page', 'danger');
        }
    }, [error]);

    const onSubmit = (data: FormData) => {
        console.log('Form data', data);

        if (data.status === ' ' || data.status === '') {
            setError('status', { type: 'custom' });
            return;
        }

        const survey = {
            id: params?.id,
            intro_description: data.intro,
            outro_description: data.outro,
            status_enum: data.status,
            title: data.title,
            is_open: isAnOpenSurvey,
            questions: {
                on_conflict: {
                    constraint: 'survey_question_pkey',
                    update_columns: ['title', 'subtitle', 'order'],
                },
                data: questions?.map((question) => {
                    return {
                        id: question.id ?? undefined,
                        title: question.title ?? '',
                        subtitle: question.subtitle ?? '',
                        order: question.order,
                        question_items: {
                            on_conflict: {
                                constraint: 'survey_question_item_pkey',
                                update_columns: ['type_enum', 'deleted_at', 'title', 'required'],
                            },
                            data: question.items?.map((item) => {
                                return {
                                    id: item?.id ?? undefined,
                                    type_enum: item.type.value ?? 'text',
                                    title: item.title ?? '',
                                    required: item.required ?? false,
                                    dropdown_options: {
                                        on_conflict: {
                                            constraint: 'survey_question_item_dropdown_option_pkey',
                                            update_columns: ['value'],
                                        },
                                        data: item.dropDownOptions?.map((option) => {
                                            return {
                                                id: option?.id ?? undefined,
                                                value: option.value ?? '',
                                            };
                                        }),
                                    },
                                    list_options: {
                                        on_conflict: {
                                            constraint: 'survey_question_item_list_option_pkey',
                                            update_columns: ['value'],
                                        },
                                        data: item.listOptions?.map((option) => {
                                            return {
                                                id: option?.id ?? undefined,
                                                value: option.value ?? '',
                                            };
                                        }),
                                    },
                                };
                            }),
                        },
                    };
                }),
            },
        };

        updateSurvey({
            variables: {
                survey,
            },
        });
    };

    if (loading) {
        return <LinearProgress />;
    }

    const typeList: SurveyQuestionItemType[] = data?.typeList;
    const statusList: SurveyStatus[] = data?.statusList;
    const survey: Survey = data?.survey;

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <Col xs='6'>
                                    <h3 className='m-0'>Survey Details</h3>
                                </Col>
                                <Col xs='6' className='text-right'>
                                    <button className='btn btn-round btn-sm btn-dark' onClick={onAddQuestion}>
                                        <span className='btn-inner-text'>Add question</span>
                                    </button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row mb-4'>
                                    <div className='col-lg-12'>
                                        <label className='form-control-label'>Title</label>
                                        <input
                                            placeholder='Add survey title'
                                            className='form-control'
                                            {...register('title', { required: true, value: survey?.title ?? '' })}
                                        />
                                        {errors.title && <span className='invalid'>*This field is required</span>}
                                    </div>
                                </div>
                                <div className='row mb-4'>
                                    <div className='col-lg-12'>
                                        <label className='form-control-label'>Intro Description</label>
                                        <textarea
                                            placeholder='Survey intro description'
                                            className='form-control'
                                            {...register('intro', {
                                                required: true,
                                                value: survey?.introDescription ?? '',
                                            })}
                                        />
                                        {errors.intro && <span className='invalid'>*This field is required</span>}
                                    </div>
                                </div>
                                <div className='row mb-4'>
                                    <div className='col-lg-12'>
                                        <label className='form-control-label'>Outro Description</label>
                                        <textarea
                                            placeholder='Survey outro description'
                                            className='form-control'
                                            {...register('outro', {
                                                required: true,
                                                value: survey?.outroDescription ?? '',
                                            })}
                                        />
                                    </div>
                                    {errors.outro && <span className='invalid'>*This field is required</span>}
                                </div>
                                <div className='row mb-4'>
                                    <div className='col-lg-12'>
                                        <label className='form-control-label'>Status</label>
                                        <Select
                                            id='title'
                                            variant='outlined'
                                            className='form-control'
                                            defaultValue={survey?.status.value ?? ' '}
                                            {...register('status', {
                                                required: true,
                                                value: survey?.status.value ?? ' ',
                                            })}
                                            placeholder='Add attribute title'
                                            fullWidth={true}
                                            style={{ fontSize: '14px', borderRadius: '12px' }}>
                                            <MenuItem disabled value={' '}>
                                                <span className='placeholder-text'>Select survey status...</span>
                                            </MenuItem>
                                            {statusList?.map((type) => {
                                                return (
                                                    <MenuItem key={type.value} value={type.value}>
                                                        {type.title}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {errors.status && <span className='invalid'>*This field is required</span>}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={isAnOpenSurvey}
                                                    onChange={(e) => setIsAnOpenSurvey(e.target.checked)}
                                                />
                                            }
                                            label='Open Survey'
                                        />
                                    </div>
                                </div>

                                <div className='row mt-4'>
                                    <div className='col-lg-1'>
                                        <ContentCopyIcon
                                            id={'copy_link_button'}
                                            onClick={async () => {
                                                navigator.clipboard.writeText(urlInputRef.current.value);
                                                showToast('Link copied', 'dark');
                                            }}
                                            className='mt-3'
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <UncontrolledTooltip delay={0} target='copy_link_button'>
                                            Copy Link
                                        </UncontrolledTooltip>
                                    </div>
                                    <div className='col-lg-11'>
                                        <input
                                            placeholder='Add survey title'
                                            disabled
                                            ref={urlInputRef}
                                            value={surveyLink}
                                            className='form-control'
                                        />
                                    </div>
                                </div>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4'
                                            disabled={questions.length === 0 || updatingSUrvey}>
                                            {!updatingSUrvey && <span>Save</span>}
                                            {updatingSUrvey && (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    {questions?.map((question) => {
                        return (
                            <QuestionSection
                                onQuestionChange={onQuestionChange}
                                typeList={typeList}
                                question={question}
                                key={question.key}
                                questions={questions}
                            />
                        );
                    })}
                </Col>
            </Row>
        </>
    );
}
