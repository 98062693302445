import { RowDefinition, useRow } from 'dnd-timeline';
import React from 'react';

interface RowProps extends RowDefinition {
    children: React.ReactNode;
    sidebar: React.ReactNode;
}

export default function Row({ children, sidebar, id }: RowProps) {
    const { setNodeRef, setSidebarRef, rowWrapperStyle, rowStyle, rowSidebarStyle } = useRow({ id: id });

    return (
        <div style={{ ...rowWrapperStyle }}>
            <div ref={setSidebarRef} style={rowSidebarStyle}>
                {sidebar}
            </div>
            <div ref={setNodeRef} style={{ ...rowStyle, border: '1px solid #F0F2F5' }}>
                {children}
            </div>
        </div>
    );
}
