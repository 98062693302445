import React, { memo, useRef, useLayoutEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useTimelineContext } from 'dnd-timeline';

interface TimeCursorProps {
    interval?: number;
}

function TimeCursor(props: TimeCursorProps) {
    const timeCursorRef = useRef<HTMLDivElement>(null);
    const arrowRef = useRef(null);

    const { range, direction, sidebarWidth, valueToPixels } = useTimelineContext();

    const side = direction === 'rtl' ? 'right' : 'left';

    const isVisible = new Date().getTime() > range.start && new Date().getTime() < range.end;

    useLayoutEffect(() => {
        if (!isVisible) return;

        const offsetCursor = () => {
            if (!timeCursorRef.current) return;
            const timeDelta = new Date().getTime() - range.start;
            const timeDeltaInPixels = valueToPixels(timeDelta);

            const sideDelta = sidebarWidth + timeDeltaInPixels;
            timeCursorRef.current.style[side] = `${sideDelta}px`;

            timeCursorRef.current.style.top = '47px';

            arrowRef.current.style[side] = `${sideDelta - 11}px`;
            arrowRef.current.style.top = '25px';
        };

        offsetCursor();

        const interval = setInterval(offsetCursor, props.interval || 1000);

        return () => {
            clearInterval(interval);
        };
    }, [side, sidebarWidth, props.interval, range.start, valueToPixels, isVisible]);

    if (!isVisible) return null;

    return (
        <>
            <ArrowDropDownIcon ref={arrowRef} sx={{ position: 'absolute', color: '#11CDEF' }} />
            <div
                ref={timeCursorRef}
                style={{
                    height: '100%',
                    width: '2px',
                    zIndex: 3,
                    backgroundColor: '#11CDEF',
                    position: 'absolute',
                }}
            />
        </>
    );
}

export default memo(TimeCursor);
