import moment from 'moment';
import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { currencyFormatter } from '../../../../utils/format.utils';

interface TodaysDifferenceCardProps {
    chartInfo?: any;
}

export default function TodaysDifferenceCard({ chartInfo }: TodaysDifferenceCardProps) {
    const differenceForToday = (): number => {
        const chartData = chartInfo;
        if (!chartData) return 0;
        console.log('Difference', chartInfo);
        return Math.abs(chartData?.difference);
    };

    const textColor = (styleName: string): string => {
        const styleMap = new Map<string, string>();
        styleMap.set('bg-success', 'text-success');
        styleMap.set('bg-danger', 'text-danger');
        if (!styleMap.get(styleName)) return 'text-primary';
        return styleMap.get(styleName);
    };

    const todayDifferenceBackgroundColor = (): string => {
        const chartData = chartInfo;
        if (!chartData) return 'bg-primary';
        return chartData?.difference < 0 ? 'bg-success' : 'bg-danger';
    };

    const cardTitle = (date: string) => {
        if (moment(date).format('LL') === moment().format('LL')) {
            return 'Difference Today';
        }
        if (moment(date).format('LL') === moment().add(1, 'day').format('LL')) {
            return 'Difference Tomorrow';
        }
        if (moment(date).format('LL') === moment().subtract(1, 'day').format('LL')) {
            return 'Difference Yesterday';
        }
        return `Difference on ${moment(date).format('ll')}`;
    };
    return (
        <Card className='card-stats'>
            <CardBody>
                <Row className='mt-auto'>
                    <div className='col'>
                        <CardTitle tag='h5' className='text-uppercase text-muted mb-2'>
                            {cardTitle(chartInfo?.date)}
                        </CardTitle>
                        <span className={`h2 font-weight-bold mb-0 ${textColor(todayDifferenceBackgroundColor())}`}>
                            {currencyFormatter(differenceForToday())}
                        </span>
                    </div>
                    <Col className='col-auto'>
                        <div
                            className={`icon icon-shape ${todayDifferenceBackgroundColor()} text-white rounded-circle shadow`}>
                            {todayDifferenceBackgroundColor() === 'bg-success' && (
                                <i className='fa-solid fa-arrow-up'></i>
                            )}
                            {todayDifferenceBackgroundColor() === 'bg-danger' && (
                                <i className='fa-solid fa-arrow-down'></i>
                            )}
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}
