/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, LinearProgress, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import { useQuery, useMutation } from '@apollo/client';
import { GET_PACKAGES, ADD_PACKAGE, REMOVE_PACKAGE } from '../../../graphql/clients.graphql';
import useToast from '../../../hooks/useToast';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';
import SweetAlert from 'react-bootstrap-sweetalert';
import BasicMenu from '../../../components/Menu';
import ClientPackageManageForm from './ClientPackageManageForm';
import { CHECK_BOOKING_AVAILABILITY } from '../../../graphql/availability.graphql';
import ReactDatetimeClass from 'react-datetime';
import moment, { isMoment } from 'moment';
import { User } from '../../../models/user.model';
import { useSelector } from 'react-redux';
import { getPermission } from '../../../utils/user.utils';
import _ from 'lodash';
import { ProductItem } from '../../../models/product.model';

type FormData = {
    package: string;
    from: string;
    to: string;
    member: string;
    status: string;
};

const PackageAddForm = ({ backToPackages, clientMembers }: any) => {
    const { loading, error, data } = useQuery(GET_PACKAGES);
    const [buttonText, setButtonText] = useState<string>('Check availability');
    const [addPackageMutation, { loading: addingPackage, error: errorAdding, data: addedPackage }] = useMutation(
        ADD_PACKAGE,
        {
            // refetchQueries: [{ query: GET_CLIENT }, "GetClient"],
            refetchQueries: ['GetClient'],
        },
    );
    const [checkAvailability, { loading: checking, error: errorChecking, data: checkedBooking }] =
        useMutation(CHECK_BOOKING_AVAILABILITY);
    const [bookingAvailable, setBookingAvailable] = useState<boolean>(false);
    const [startingDate, setStartingDate] = useState<string>('');
    const [endingDate, setEndingDate] = useState<string>('');
    const params: any = useParams();
    const { showToast, Notification } = useToast();
    const [packageId, setPackageId] = useState<string>();
    const [assignedItemId, setAssignedItemId] = useState<string | null>(null);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }

        if (errorAdding) {
            showToast('An error has occurred while trying to add a package, please try again', 'danger');
        }

        if (errorChecking) {
            showToast('An error has occurred while trying to check for an available booking.', 'danger');
        }
    }, [error, errorAdding, errorChecking]);

    useEffect(() => {
        if (addedPackage && !error && !errorAdding) {
            showToast('Successfully added a package', 'success');
            reset();
        }
    }, [addedPackage]);

    useEffect(() => {
        if (checkedBooking) {
            if (!checkedBooking.booking.is_available) {
                setButtonText('Not available');
            }

            setBookingAvailable(checkedBooking.booking.is_available);
        }
    }, [checkedBooking]);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm<FormData>();

    const onSubmit = (data: FormData) => {
        if (!bookingAvailable) {
            checkAvailability({
                variables: {
                    packageId: data.package,
                    from: data.from,
                    to: data.to,
                },
            });
            return;
        }

        const productPackage = {
            account_id: params.id,
            member_id: data.member === '' || 'empty' ? null : data.member,
            from: data.from,
            to: data.to,
            package_id: data.package,
            status_enum: data.status,
            product_item_id: assignedItemId,
        };

        addPackageMutation({
            variables: {
                package: productPackage,
            },
        });
    };

    // const isStartDateValid = (current: moment.Moment) => {
    //   const yesterday: moment.Moment = moment().subtract(1, "day");
    //   return current.isAfter(yesterday);
    // };

    // const isEndingDateValid = (selectedDate: moment.Moment) => {
    //   const today = moment(startingDate);
    //   return selectedDate.isSameOrAfter(today);
    // };

    const onInputChange = () => {
        setBookingAvailable(false);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setPackageId(event.target.value as string);
    };

    if (loading) {
        return <LinearProgress />;
    }

    const packages: any[] = data?.packages;
    const bookingStatus: any[] = data?.status;
    let productItems: ProductItem[] | undefined = packages.find((_package) => _package.id === packageId)?.product
        .productItems;

    productItems = productItems?.filter((item) => {
        if (item.sharable) {
            return item;
        }

        return item.status.value !== 'assigned';
    });

    // console.log('Items', productItems);

    const isAssignable = packages.find((_package) => _package.id === packageId)?.isAssignable;

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    onClick={() => backToPackages()}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-md-12 col-lg-6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='name'>
                                                Package
                                            </label>
                                            <Select
                                                id='package'
                                                className='form-control'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                placeholder='Select a role'
                                                onChange={(event: SelectChangeEvent) => {
                                                    onInputChange();
                                                    handleChange(event);
                                                }}
                                                defaultValue={'empty'}
                                                disabled={checking}
                                                inputProps={{
                                                    ...register('package', {
                                                        required: true,
                                                        value: '',
                                                    }),
                                                }}>
                                                <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                    Select package...
                                                </MenuItem>
                                                {packages.map((_package) => {
                                                    return (
                                                        <MenuItem key={_package.id} value={_package.id}>
                                                            {_package.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.package && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-12 col-lg-6'>
                                        {isAssignable === true && (
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Package item
                                                </label>

                                                <Autocomplete
                                                    id='items'
                                                    filterOptions={(options, state) =>
                                                        options.filter((element) => {
                                                            return (
                                                                element.name
                                                                    .toLowerCase()
                                                                    .includes(state.inputValue.toLowerCase()) ||
                                                                element.code
                                                                    ?.toLowerCase()
                                                                    .includes(state.inputValue.toLowerCase())
                                                            );
                                                        })
                                                    }
                                                    onChange={(event, value) => {
                                                        setAssignedItemId(value?.id ? value.id : '');
                                                    }}
                                                    options={productItems ? productItems : []}
                                                    getOptionLabel={(option) =>
                                                        `${option.name} - ${option.code ? option.code : ''}`
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder='Assign package item...'
                                                            fullWidth={true}
                                                            variant='outlined'
                                                            style={{
                                                                fontSize: '14px',
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </FormGroup>
                                        )}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 col-lg-6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='email'>
                                                From
                                            </label>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    disabled: checking,
                                                    placeholder: 'Select start date...',
                                                    ...register('from', {
                                                        required: true,
                                                        value: startingDate
                                                            ? moment(startingDate).format('yyyy-MM-DD')
                                                            : '',
                                                    }),
                                                    autoComplete: 'off',
                                                }}
                                                timeFormat={false}
                                                value={startingDate ? moment(startingDate).format('yyyy-MM-DD') : ''}
                                                dateFormat={'YYYY-MM-DD'}
                                                // isValidDate={isStartDateValid}
                                                onChange={(value) => {
                                                    if (value && isMoment(value)) {
                                                        setValue('from', value.format('yyyy-MM-DD'));
                                                    }
                                                    onInputChange();
                                                    setStartingDate(getValues('from'));
                                                    // setEndingDate(getValues('from'));
                                                }}
                                            />
                                            {errors.from && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-12 col-lg-6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='contact'>
                                                To
                                            </label>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    disabled: checking || startingDate === '',
                                                    value: endingDate ? moment(endingDate).format('yyyy-MM-DD') : '',
                                                    placeholder: 'Select end date...',
                                                    ...register('to', {
                                                        required: true,
                                                        value: endingDate,
                                                    }),
                                                    autoComplete: 'off',
                                                }}
                                                timeFormat={false}
                                                dateFormat={'YYYY-MM-DD'}
                                                // isValidDate={isEndingDateValid}
                                                value={endingDate ? moment(endingDate).format('yyyy-MM-DD') : ''}
                                                onChange={(value) => {
                                                    if (value && isMoment(value)) {
                                                        setValue('to', value.format('yyyy-MM-DD'));
                                                    }
                                                    onInputChange();
                                                    setEndingDate(getValues('to'));
                                                }}
                                            />
                                            {errors.to && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                </div>

                                {!bookingAvailable && (
                                    <div className='d-flex h-100'>
                                        {/*  <div className="d-flex h-25 w-25">
                  {/* //     <input */}
                                        {/* //       type="checkbox"
                  //       // checked={assignable}
                  //       onChange={(e) => { */}
                                        {/* //         setAssignable(e.target.checked);
                  //       }}
                  //     />
                      // <label className="ml-2 mt-2">Assignable</label> */}
                                        {/* </div>  */}
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={checking}>
                                                {checking ? (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                ) : (
                                                    <span>{buttonText}</span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                {bookingAvailable && (
                                    <>
                                        <div className='row'>
                                            <div className='col-md-12 col-lg-6'>
                                                <FormGroup>
                                                    <label className='form-control-label' htmlFor='p-member-name'>
                                                        Member (optional)
                                                    </label>
                                                    <Select
                                                        id='package'
                                                        className='form-control'
                                                        sx={{ borderRadius: '12px', padding: '0' }}
                                                        placeholder='Select a member'
                                                        defaultValue={'empty'}
                                                        inputProps={{
                                                            ...register('member', {
                                                                required: false,
                                                                value: '',
                                                            }),
                                                        }}>
                                                        <MenuItem disabled value={'empty'}>
                                                            Select a member...
                                                        </MenuItem>
                                                        {clientMembers.map((member: any) => {
                                                            return (
                                                                <MenuItem key={member.id} value={member.id}>
                                                                    {`${member.user.name} ${member.user.surname}`}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                    {errors.member && (
                                                        <span className='invalid'>*This field is required</span>
                                                    )}
                                                </FormGroup>
                                            </div>
                                            <div className='col-md-12 col-lg-6'>
                                                <FormGroup>
                                                    <label className='form-control-label' htmlFor='p-member-name'>
                                                        Status
                                                    </label>
                                                    <Select
                                                        id='package'
                                                        className='form-control'
                                                        placeholder='Select a status'
                                                        sx={{ borderRadius: '12px', padding: '0' }}
                                                        defaultValue={'active'}
                                                        inputProps={{
                                                            ...register('status', {
                                                                required: true,
                                                                value: 'active',
                                                            }),
                                                        }}>
                                                        {bookingStatus.map((status: any) => {
                                                            return (
                                                                <MenuItem key={status.value} value={status.value}>
                                                                    {status.title}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                    {errors.status && (
                                                        <span className='invalid'>*This field is required</span>
                                                    )}
                                                </FormGroup>
                                            </div>
                                        </div>

                                        <div className='d-flex h-100'>
                                            <div className='align-self-end ml-auto'>
                                                <Button
                                                    type='submit'
                                                    className='btn btn-dark mt-4 btn-block'
                                                    disabled={addingPackage}>
                                                    {addingPackage ? (
                                                        <span>
                                                            <Spinner />
                                                        </span>
                                                    ) : (
                                                        <span>Add package</span>
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

const ClientPackagesView: any = ({ packages, clientMembers }: any) => {
    const [displayForm, setDisplayForm] = useState<boolean>(false);
    const [displayManageForm, setDisplayManageForm] = useState<boolean>(false);
    const [clientPackage, setClientPackage] = useState<any>(null);
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [sortFiled, setSortField] = useState<string>('from');
    const { showToast, Notification } = useToast();
    const [alert, setAlert] = useState<any>(null);
    const [filterKey, setFilterKey] = useState<string>('');
    const [deletePackageMutation, { error: errorDeleting, data: deleted }] = useMutation(REMOVE_PACKAGE, {
        refetchQueries: ['GetClient'],
    });
    const user: User | null = useSelector((state: any) => state.user.userInfo);

    const getRowValue = (value: string) => {
        setRowsPerPage(parseInt(value));
    };
    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    useEffect(() => {
        if (errorDeleting) {
            showToast('An error has occurred while trying to remove this package', 'danger');
        }
    }, [errorDeleting]);

    useEffect(() => {
        if (deleted) {
            showToast('Package successfully removed', 'success');
        }
    }, [deleted]);

    if (displayForm) {
        return <PackageAddForm backToPackages={() => setDisplayForm(false)} clientMembers={clientMembers} />;
    }

    const deletedPackage = (_id: string) => {
        deletePackageMutation({
            variables: {
                id: _id,
            },
        });
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    deletedPackage(props._package.id);
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This package will be removed from the system.
            </SweetAlert>,
        );
    };

    if (displayManageForm) {
        return (
            <ClientPackageManageForm
                backToPackages={() => setDisplayManageForm(false)}
                clientPackage={clientPackage}
                clientMembers={clientMembers}
            />
        );
    }

    if (packages?.length === 0 && !displayForm) {
        return (
            <Card className='mt-4'>
                <CardHeader className='border-0'>
                    <Row>
                        <Col xs='6'>
                            <h3 className='mb-0'>Package</h3>
                        </Col>
                        <Col className='text-right' xs='6'>
                            <button
                                className='btn btn-round btn-sm btn-dark'
                                color='default'
                                id='tooltip969372949'
                                onClick={() => {
                                    setDisplayForm(true);
                                }}>
                                <span className='btn-inner--text'>Add package</span>
                            </button>
                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                Add package
                            </UncontrolledTooltip>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <h2>This client has no packages</h2>
                </CardBody>
            </Card>
        );
    }

    const sortBy: any = (packages: any[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['name', _.orderBy(packages, (p) => p.productPackage.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['from', _.orderBy(packages, (p) => moment(p.from), [direction === 'asc' ? 'asc' : 'desc'])],
            ['to', _.orderBy(packages, (p) => moment(p.to), [direction === 'asc' ? 'asc' : 'desc'])],
            ['status', _.orderBy(packages, (p) => p.status.title, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    let filteredPackages = packages.filter((productPackage: any) => {
        const filtered =
            productPackage.productPackage.name.toLowerCase().includes(filterKey?.toLocaleLowerCase()) ||
            productPackage.from.toLowerCase().includes(filterKey?.toLocaleLowerCase()) ||
            productPackage.to.toLowerCase().includes(filterKey?.toLocaleLowerCase()) ||
            productPackage.member.user.name.toLowerCase().includes(filterKey?.toLocaleLowerCase()) ||
            productPackage.member.user.surname.toLowerCase().includes(filterKey?.toLocaleLowerCase());
        if (filterKey === '') {
            return productPackage;
        }
        return filtered;
    });

    filteredPackages = sortBy(filteredPackages, sortFiled, sortDirection);

    // console.log("Client package ", clientPackage);

    return (
        packages.length > 0 &&
        !displayForm && (
            <>
                {alert}
                {Notification}
                <Card className='mt-4'>
                    <Row>
                        <div className='col'>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Packages</h3>
                                    </Col>
                                    {getPermission(user, 'client_packages', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <button
                                                className='btn btn-dark btn-sm'
                                                onClick={() => {
                                                    setDisplayForm(true);
                                                }}>
                                                Add package
                                            </button>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col xs='6'>
                                        <Input
                                            className='form-control w-50'
                                            type='text'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setFilterKey(e.target.value);
                                                setPage(0);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Table className='align-items-center table-flush' responsive>
                                    <thead className='thead-light'>
                                        <tr>
                                            <th
                                                onClick={() => {
                                                    setSortField('name');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='name'
                                                scope='col'>
                                                Package name
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('from');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='from'
                                                scope='col'>
                                                From
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('to');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='to'
                                                scope='col'>
                                                To
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('status');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='status'
                                                scope='col'>
                                                Status
                                            </th>
                                            <th
                                                onClick={() => {
                                                    // setSortField('member');
                                                    // setSortDirection(sortDirection === 'asc'? 'desc': 'asc');
                                                }}
                                                className='sort'
                                                data-sort='member'
                                                scope='col'>
                                                Assigned member
                                            </th>
                                            <th scope='col'></th>
                                        </tr>
                                    </thead>
                                    <tbody className='list'>
                                        {filteredPackages
                                            .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                            .map((productPackage: any) => {
                                                return (
                                                    <tr
                                                        key={productPackage.id}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setDisplayManageForm(true);
                                                            setClientPackage(productPackage);
                                                        }}>
                                                        <td>{productPackage.productPackage.name}</td>
                                                        <td>{moment(productPackage.from).format('yyyy/MM/DD')}</td>
                                                        <td>{moment(productPackage.to).format('yyyy/MM/DD')}</td>
                                                        <td>{productPackage.status.title}</td>
                                                        <td>
                                                            {productPackage.member
                                                                ? `${productPackage.member.user.name} ${productPackage.member.user.surname}`
                                                                : 'Not set'}
                                                        </td>
                                                        <td className='text-right'>
                                                            {getPermission(user, 'client_packages', 'update') && (
                                                                <button
                                                                    className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                                    onClick={() => {
                                                                        setDisplayManageForm(true);
                                                                        setClientPackage(productPackage);
                                                                    }}>
                                                                    <i className='fa-solid fa-pencil'></i>
                                                                </button>
                                                            )}
                                                            {getPermission(user, 'client_packages', 'delete') && (
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        displayAlert({
                                                                            warning: true,
                                                                            _package: productPackage,
                                                                        });
                                                                    }}
                                                                    className='btn btn-danger btn-icon-only rounded-circle btn-sm'>
                                                                    <i className='fa-solid fa-trash-can'></i>
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </CardBody>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredPackages.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredPackages?.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </div>
                    </Row>
                </Card>
            </>
        )
    );
};

export default ClientPackagesView;
