import React from 'react';
import type { RowDefinition } from 'dnd-timeline';

interface SidebarProps {
    row: RowDefinition;
}

function Sidebar(props: SidebarProps) {
    return <div className='timeline-sidebar'>{`${props.row.id}`}</div>;
}

export default Sidebar;
