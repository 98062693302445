/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CLIENTS, REMOVE_CLIENT } from '../../../graphql/clients.graphql';
import useToast from '../../../hooks/useToast';
import { LinearProgress } from '@mui/material';
import { Client } from '../../../models/client.model';
import SweetAlert from 'react-bootstrap-sweetalert';
import { GET_ADD_CLIENT_DATA } from '../../../graphql/clients.graphql';
import { useSelector } from 'react-redux';
import { User } from '../../../models/user.model';
import { getPermission } from '../../../utils/user.utils';
import _ from 'lodash';
import { useLocalStorage } from '../../../hooks/local-storage.hook';

import ClientExportDialog from './dialogs/ClientExportDialog';

interface ClientRouteData {
    keyWord: string;
    statusFilter: string;
    page: number;
    pageSize: number;
}

const ClientView = () => {
    const [statusFilter, setStatusFilter] = useState('All');
    const [getLocalStorage, setLocalStorage] = useLocalStorage('client');
    const [searchValue, setSearchValue] = useState('');
    const [alert, setAlert] = useState<any>(null);
    const { showToast, Notification } = useToast();
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [sortFiled, setSortField] = useState<string>('companyName');
    const { loading, error, data } = useQuery(GET_CLIENTS);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const navigate = useNavigate();
    const { loading: loadingClientStatus, error: statusError, data: loadedStatus } = useQuery(GET_ADD_CLIENT_DATA);
    const [clientRemoveMutation, { error: errorRemoving, data: clientRemoved }] = useMutation(REMOVE_CLIENT, {
        refetchQueries: [{ query: GET_CLIENTS }, 'GetClients'],
    });
    const user: User | null = useSelector((state: any) => state.user.userInfo);

    useEffect(() => {
        if (error || statusError) {
            showToast('An error has occurred, please reload the page', 'danger');
        }

        if (errorRemoving) {
            showToast('An error has occurred while trying to remove this client, please try again', 'danger');
        }
    }, [error, errorRemoving, statusError]);

    useEffect(() => {
        const routeData: ClientRouteData = getLocalStorage();

        if (routeData) {
            setStatusFilter(routeData?.statusFilter || 'All');
            setPage(routeData?.page || 0);
            setRowsPerPage(routeData?.pageSize || 10);
            setSearchValue(routeData?.keyWord || '');
        }
    }, []);

    useEffect(() => {
        if (clientRemoved) {
            showToast('Client successfully removed', 'success');
        }
    }, [clientRemoved]);

    const getStatus = (statusValue: string) => {
        setStatusFilter(statusValue);
        setSearchValue('');
        setPage(0);

        const newQuoteRouteData: ClientRouteData = getLocalStorage() || {};
        newQuoteRouteData.statusFilter = statusValue;
        newQuoteRouteData.keyWord = '';
        newQuoteRouteData.page = 0;
        setLocalStorage(newQuoteRouteData);
    };

    const getRowValue = (value: string) => {
        setRowsPerPage(parseInt(value));
        const newQuoteRouteData: ClientRouteData = getLocalStorage() || {};
        newQuoteRouteData.pageSize = parseInt(value);
        if (parseInt(value) !== rowPerPage) {
            setPage(0);
            newQuoteRouteData.page = 0;
        }
        setLocalStorage(newQuoteRouteData);
    };

    const removeClient = (_id: string) => {
        clientRemoveMutation({
            variables: {
                id: _id,
            },
        });
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeClient(props.client.id);
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This client will be removed.
            </SweetAlert>,
        );
    };

    const sortBy: any = (clients: Client[], field: string, direction: string) => {
        const orderedBookings = new Map([
            [
                'companyName',
                _.orderBy(clients, (client) => client?.companyName || client?.individualName, [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
            ['name', _.orderBy(clients, (client) => client.user?.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['email', _.orderBy(clients, (client) => client.user?.email, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'contactNumber',
                _.orderBy(clients, (client) => client.user?.contactNumber, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            ['isMember', _.orderBy(clients, (client) => client?.isColabMember, [direction === 'asc' ? 'asc' : 'desc'])],
            ['status', _.orderBy(clients, (client) => client.status?.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['type', _.orderBy(clients, (client) => client.type?.title, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    const nextPage = () => {
        setPage(page + 1);
        const newQuoteRouteData: ClientRouteData = getLocalStorage() || {};
        newQuoteRouteData.page = page + 1;
        setLocalStorage(newQuoteRouteData);
    };
    const previousPage = () => {
        setPage(page - 1);
        const newQuoteRouteData: ClientRouteData = getLocalStorage() || {};
        newQuoteRouteData.page = page - 1;
        setLocalStorage(newQuoteRouteData);
    };

    if (loading || loadingClientStatus) {
        return <LinearProgress />;
    }

    const clients: Client[] = data?.clients;
    let filteredClients: Client[] = clients.filter((key: Client) => {
        const checkForSearch =
            key?.companyName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            key?.individualName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            key?.type?.value?.toLowerCase().includes(searchValue.toLowerCase()) ||
            key?.user?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            key?.user?.email.toLowerCase().includes(searchValue.toLowerCase()) ||
            key?.user?.contactNumber?.toLowerCase().includes(searchValue.toLowerCase());

        if (statusFilter === 'All') {
            return key && checkForSearch;
        }

        if (searchValue !== 'All' && searchValue === '' && key.status !== null) {
            return key.status?.title === statusFilter && checkForSearch;
        }

        return checkForSearch;
    });

    filteredClients = sortBy(filteredClients, sortFiled, sortDirection);

    const clientStatusList: string[] = [];
    clientStatusList[0] = 'All';

    for (let i: number = 0; i < loadedStatus?.status?.length; i++) {
        clientStatusList.push(loadedStatus?.status[i]?.title);
    }

    return (
        <>
            {Notification}
            {alert}

            {isOpen && <ClientExportDialog isOpen={isOpen} handleClose={() => setIsOpen(false)} />}
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Clients</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Clients</h3>
                                    </Col>
                                    {getPermission(user, 'clients', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <span
                                                onClick={() => setIsOpen(true)}
                                                className='btn btn-round btn-sm btn-dark'>
                                                Export
                                            </span>
                                            <Link
                                                to={'/admin/clients/new'}
                                                className='btn btn-round btn-sm btn-dark'
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'>Add client</span>
                                            </Link>
                                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                Add client
                                            </UncontrolledTooltip>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col xs='6'>
                                        <Input
                                            className='form-control w-50'
                                            value={searchValue}
                                            type='text'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                                setStatusFilter('All');
                                                setPage(0);

                                                const newQuoteRouteData: ClientRouteData = getLocalStorage() || {};
                                                newQuoteRouteData.keyWord = e.target.value;
                                                newQuoteRouteData.statusFilter = 'All';
                                                newQuoteRouteData.page = 0;
                                                setLocalStorage(newQuoteRouteData);
                                            }}
                                        />
                                    </Col>
                                    <Col xs='6'>
                                        <div className='row'>
                                            <div className='col-6 d-flex'>
                                                <span className='min-text'>Status</span>
                                                <BasicMenu
                                                    getValue={getStatus}
                                                    options={clientStatusList}
                                                    value={statusFilter}
                                                />
                                            </div>
                                            <div className='col-6 d-flex'></div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('companyName');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='companyName'
                                            scope='col'>
                                            Business name
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('name');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='name'
                                            scope='col'>
                                            Principle member
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('email');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='email'
                                            scope='col'>
                                            Email
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('type');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='type'
                                            scope='col'>
                                            Type
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Status
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredClients
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((client: Client) => {
                                            return (
                                                <tr
                                                    key={client.id}
                                                    onClick={() => navigate(`/admin/clients/manage/${client.id}`)}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}>
                                                    <td>{client?.companyName || client?.individualName}</td>
                                                    <td>{client?.user?.name + ' ' + client?.user?.surname}</td>
                                                    <td>{client?.user?.email}</td>
                                                    <td>{client?.type?.title || 'Not set'}</td>
                                                    <td>{client?.status?.title || 'Not set'}</td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'clients', 'read') && (
                                                            <Link
                                                                to={`/admin/clients/manage/${client.id}`}
                                                                className='btn btn-info btn-icon-only rounded-circle btn-sm'>
                                                                <i className='fa-solid fa-pencil'></i>
                                                            </Link>
                                                        )}
                                                        {getPermission(user, 'clients', 'delete') && (
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, client });
                                                                }}
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredClients?.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredClients?.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default ClientView;
