/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { ProductStatus, ProductType } from '../../../models/product.model';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { LinearProgress, MenuItem, Select } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_PRODUCT, GET_PRODUCTS, GET_PRODUCT_STATUS, GET_PRODUCT_TYPES } from '../../../graphql/products.graphql';
import useToast from '../../../hooks/useToast';
import Spinner from '../../../components/Spinner/Spinner';
import FeaturedImage from '../../../components/Image component/ImageContainer';
import { uploadImage } from '../../../hooks/upload-image.hook';
import { useNavigate } from 'react-router-dom';

const AddProductForm = () => {
    let navigate = useNavigate();
    const { loading: loadingStatus, error: statusError, data: statusData } = useQuery(GET_PRODUCT_STATUS);
    const { loading, error, data: productTypes } = useQuery(GET_PRODUCT_TYPES);
    const fileInputRef: any = useRef(null);
    const [addProduct, { loading: isAdding, error: addingError, data: loadedProducts }] = useMutation(ADD_PRODUCT, {
        refetchQueries: [{ query: GET_PRODUCTS }],
    });
    const [imageFile, setImageFile] = useState<any>();
    const [image, setImage] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const redirectToProducts = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };
    const { showToast, Notification } = useToast();

    type FormTypes = {
        name: string;
        description: string;
        status: string;
        type: string;
    };

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        trigger,
        formState: { errors },
    } = useForm<FormTypes>({});

    useEffect(() => {
        if (addingError || statusError || error) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [addingError, statusData, error]);

    useEffect(() => {
        if (!addingError && !statusError && loadedProducts) {
            showToast('Successfully add a product', 'success');
            reset();
            setIsLoading(false);
        }
    }, [isAdding, loadedProducts]);

    useEffect(() => {
        register('description', { required: true });
    });

    const onSubmit = async (data: FormTypes) => {
        if (!imageFile) {
            showToast('Please make sure that an image file has been selected', 'danger');
            return;
        }

        if (getValues('status') === 'empty') {
            setValue('status', '');
            return;
        }

        if (getValues('type') === 'empty') {
            setValue('type', '');
            return;
        }

        setIsLoading(true);

        const imageKey = await uploadImage(imageFile, 'product_images');
        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload the image', 'danger');
            setIsLoading(false);
            return;
        }

        const product = {
            name: data.name,
            description: data.description,
            product_status_enum: data.status,
            product_type_enum: data.type,
            featured_image: imageKey,
        };
        addProduct({ variables: { product } });
    };

    const onImageChange = (e: any) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    if (loading || loadingStatus) {
        return <LinearProgress />;
    }

    const prodStatuses: ProductStatus[] = statusData?.status;
    const prodTypes: ProductType[] = productTypes?.types;
    return (
        prodStatuses &&
        prodTypes && (
            <>
                {Notification}
                <Container className='mt-4' fluid>
                    <Row>
                        <Col md='12' lg='6'>
                            <Card>
                                <CardHeader>
                                    <Row noGutters={true}>
                                        <button
                                            className='btn btn-outline-primary btn-sm mr-4'
                                            onClick={(e) => redirectToProducts(e)}>
                                            <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                            <span className='btn-inner-text'>Back</span>
                                        </button>
                                        <h3 className='m-0'>Add product</h3>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <FormGroup>
                                            <label className='form-control-label'>Featured Image</label>
                                            <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
                                            <input
                                                ref={fileInputRef}
                                                type='file'
                                                accept='image/*'
                                                onChange={(e) => {
                                                    onImageChange(e);
                                                }}
                                                style={{ display: 'none' }}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='name'>
                                                Name
                                            </label>
                                            <input
                                                className='form-control'
                                                {...register('name', { required: true })}
                                                id='product'
                                                placeholder="Enter product's name..."
                                                type='text'
                                            />
                                            {errors.name && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='description'>
                                                Description
                                            </label>
                                            <CKEditor
                                                id='description'
                                                editor={ClassicEditor}
                                                data={''}
                                                config={{
                                                    removePlugins: ['Heading'],
                                                    toolbar: [
                                                        'bold',
                                                        'italic',
                                                        'bulletedList',
                                                        'numberedList',
                                                        'blockQuote',
                                                        'link',
                                                    ],
                                                }}
                                                onChange={(event: any, editor: any) => {
                                                    setValue('description', editor.getData());
                                                    trigger('description');
                                                }}
                                                onReady={(editor: any) => {}}
                                            />
                                            {errors.description && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </FormGroup>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='status'>
                                                Status
                                            </label>
                                            <Select
                                                id='status'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                placeholder='Select a role'
                                                defaultValue={'active'}
                                                inputProps={{
                                                    ...register('status', {
                                                        required: true,
                                                        value: 'active',
                                                    }),
                                                }}>
                                                <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                    Select product status...
                                                </MenuItem>
                                                {prodStatuses.map((status: ProductStatus) => (
                                                    <MenuItem key={status.value} value={status.value}>
                                                        {status.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.status && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='type'>
                                                Type
                                            </label>
                                            <Select
                                                id='type'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                placeholder='Select a role'
                                                defaultValue={'empty'}
                                                inputProps={{
                                                    ...register('type', {
                                                        required: true,
                                                        value: 'empty',
                                                    }),
                                                }}>
                                                <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                    Select product status...
                                                </MenuItem>
                                                {prodTypes.map((prod: ProductType) => (
                                                    <MenuItem key={prod.value} value={prod.value}>
                                                        {prod.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.type && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                        <div className='d-flex h-100'>
                                            <div className='align-self-end ml-auto'>
                                                <Button
                                                    type='submit'
                                                    className='btn btn-dark mt-4'
                                                    disabled={isLoading}>
                                                    {!isLoading ? (
                                                        <span>Add product</span>
                                                    ) : (
                                                        <span>
                                                            <Spinner />
                                                        </span>
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    );
};

export default AddProductForm;
