import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminLayout from './layouts/Admin';
import RTLLayout from './layouts/RTL';
import SignIn from './views/pages/Auth/SignInPage';
import PasswordRecoverView from './views/pages/Auth/PasswordRecovery';
import { Hub } from 'aws-amplify';
import { ApolloProvider } from '@apollo/client';
import { configureApolloClient } from './graphql/apollo-config';
import { Helmet } from 'react-helmet';
import routes from './routes';
import ConferenceDayTimelineView from './views/pages/booking/ConferenceDayTimelineView';

export default function App(): any {
    const redirectToAdmin = () => (window.location.href = '/admin/dashboard');
    const redirectToLogIn = () => (window.location.href = '/');

    const [client] = useState(configureApolloClient());

    useEffect(() => {
        Hub.listen('auth', (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    redirectToAdmin();
                    break;

                case 'signOut':
                    redirectToLogIn();
                    break;
            }
        });
    });

    const getRoutes = (routes: any) => {
        return routes.map((prop: any, key: any) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === '/admin') {
                // console.log("Render!");
                return (
                    <Route path={prop.layout + prop.path} element={prop.component} key={key}>
                        {prop.children &&
                            prop.children.map((child: any, childKey: any) => (
                                <Route
                                    path={prop.layout + prop.path + child.path}
                                    element={<child.component />}
                                    key={key + '_' + childKey}
                                />
                            ))}
                    </Route>
                );
            } else {
                return null;
            }
        });
    };

    return (
        <div>
            <Helmet>
                <meta name='viewport' content='width=device-width, initial-scale=1, shrink-to-fit=no' />
            </Helmet>
            <ApolloProvider client={client}>
                <BrowserRouter>
                    <Routes>
                        <Route path='/password-recovery' element={<PasswordRecoverView />} />
                        <Route path='/rtl' element={<RTLLayout />} />
                        <Route path='/conference-timeline' element={<ConferenceDayTimelineView />} />
                        <Route path='/admin' element={<AdminLayout />}>
                            {getRoutes(routes)}
                        </Route>
                        <Route path='/' element={<SignIn />} />
                    </Routes>
                </BrowserRouter>
            </ApolloProvider>
        </div>
    );
}
