import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
    query GetProducts {
        products: product(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
            id
            name
            description
            status: product_status {
                value
                title
            }
            type: product_type {
                value
                title
            }
        }
    }
`;

export const GET_PRODUCT = gql`
    query GetProduct($id: uuid!) {
        product: product_by_pk(id: $id) {
            id
            name
            description
            status: product_status {
                value
                title
            }
            type: product_type {
                value
                title
            }
            productImage: featured_image
            images: product_images(where: { deleted_at: { _is_null: true } }) {
                id
                path
            }
            productItems: product_items(where: { deleted_at: { _is_null: true } }) {
                id
                name
                pax
                code
                sharable: is_sharable
                status {
                    value
                    title
                }
                location: location_id
                area
                floor
                productLocation: product_item_location {
                    id
                    name
                }
            }
        }
    }
`;

export const ADD_PRODUCT = gql`
    mutation AddProduct($product: product_insert_input!) {
        product: insert_product_one(object: $product) {
            id
        }
    }
`;

export const GET_PRODUCT_TYPES = gql`
    query GetProductType {
        types: product_type {
            value
            title
        }
    }
`;

export const GET_PRODUCT_STATUS = gql`
    query GetProductStatus {
        status: product_status {
            value
            title
        }
    }
`;

export const UPDATE_PRODUCT = gql`
    mutation UpdateProduct($id: uuid!, $product: product_set_input!) {
        update_product_by_pk(pk_columns: { id: $id }, _set: $product) {
            id
            featuredImage: featured_image
        }
    }
`;

export const REMOVE_PRODUCT = gql`
    mutation RemoveUser($id: uuid!) {
        update_product_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const UPDATE_PRODUCT_IMAGE = gql`
    mutation updateImage($id: uuid!, $image: product_image_set_input!) {
        update_product_image_by_pk(pk_columns: { id: $id }, _set: $image) {
            id
        }
    }
`;

export const REMOVE_PRODUCT_IMAGE = gql`
    mutation RemoveProductImage($id: uuid!) {
        update_product_image_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_IMAGE_PRODUCT = gql`
    mutation AddProductImage($image: product_image_insert_input!) {
        insert_product_image_one(object: $image) {
            id
        }
    }
`;

export const ADD_PRODUCT_ITEM = gql`
    mutation AddProductItemStatus($item: product_item_insert_input!) {
        insert_product_item_one(
            object: $item
            on_conflict: {
                constraint: product_item_pkey
                update_columns: [name, pax, status_enum, code, is_sharable, location_id, floor, area]
            }
        ) {
            id
        }
    }
`;

export const REMOVE_PRODUCT_ITEM = gql`
    mutation RemoveProductItem($id: uuid!) {
        update_product_item_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_PRODUCT_ITEM_STATUS = gql`
    query GetProductItemStatus {
        status: product_item_status {
            value
            title
        }
    }
`;
