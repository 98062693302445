/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { ADD_SURVEY, GET_SURVEY_ITEM_TYPE_LIST, GET_SURVEY_LIST } from '../../../graphql/survey.graphql';
import { useMutation, useQuery } from '@apollo/client';
import useToast from '../../../hooks/useToast';
import QuestionSection from './components/QuestionSection';
import { SurveyQuestion, SurveyQuestionItemType, SurveyStatus } from '../../../models/survey.modesl';
import { v4 } from 'uuid';
import Spinner from '../../../components/Spinner/Spinner';
import { useForm } from 'react-hook-form';

type FormData = {
    title: string;
    intro: string;
    outro: string;
    status: string;
};

export default function SurveyAddFormView() {
    const navigate = useNavigate();

    const { data, loading, error } = useQuery(GET_SURVEY_ITEM_TYPE_LIST);
    const { showToast, Notification } = useToast();
    const [isAnOpenSurvey, setIsAnOpenSurvey] = useState(false);

    const [questions, setQuestions] = useState<SurveyQuestion[]>([]);
    const [addSurvey, { data: addedSurvey, loading: addingSurvey, error: addingError }] = useMutation(ADD_SURVEY, {
        refetchQueries: [{ query: GET_SURVEY_LIST }],
    });

    const {
        handleSubmit,
        register,
        setError,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load the page', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (addedSurvey) {
            showToast('Successfully added survey', 'success');
            navigate(`/admin/survey/manage/${addedSurvey.survey.id}`);
        }
    }, [addedSurvey]);

    useEffect(() => {
        if (addingError) {
            showToast('An error has occurred while trying to add a survey', 'error');
        }
    }, [addingError]);

    const onAddQuestion = () => {
        const newQuestion: SurveyQuestion = {
            title: '',
            items: [],
            order: questions.length + 1,
            key: v4(),
        };

        setQuestions([...questions, newQuestion]);
    };

    const onQuestionChange = (questions: SurveyQuestion[]) => {
        setQuestions(questions);
    };

    const onSubmit = (data: FormData) => {
        console.log('Form data', data);

        if (data.status === ' ' || data.status === '') {
            setError('status', { type: 'custom' });
            return;
        }

        const survey = {
            intro_description: data.intro,
            outro_description: data.outro,
            status_enum: data.status,
            title: data.title,
            is_open: isAnOpenSurvey,
            questions: {
                data: questions.map((question) => {
                    return {
                        title: question.title ?? '',
                        subtitle: question.subtitle ?? '',
                        order: question.order,
                        question_items: {
                            data: question.items.map((item) => {
                                return {
                                    type_enum: item.type.value ?? 'text',
                                    title: item.title ?? '',
                                    required: item.required ?? false,
                                    dropdown_options: {
                                        data: item.dropDownOptions.map((option) => {
                                            return {
                                                value: option.value ?? '',
                                            };
                                        }),
                                    },
                                    list_options: {
                                        data: item.listOptions.map((option) => {
                                            return {
                                                value: option.value ?? '',
                                            };
                                        }),
                                    },
                                };
                            }),
                        },
                    };
                }),
            },
        };

        addSurvey({
            variables: {
                survey,
            },
        });
    };

    if (loading) {
        return <LinearProgress />;
    }

    const typeList: SurveyQuestionItemType[] = data?.typeList;
    const statusList: SurveyStatus[] = data?.statusList;

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <Col xs='3'>
                                        <button
                                            className='btn btn-outline-primary btn-sm mr-4'
                                            onClick={() => navigate(-1)}>
                                            <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                            <span className='btn-inner-text'>Back</span>
                                        </button>
                                    </Col>
                                    <Col xs='3'>
                                        <h3 className='m-0'>Add Survey</h3>
                                    </Col>
                                    <Col xs='6' className='text-right'>
                                        <button className='btn btn-round btn-sm btn-dark' onClick={onAddQuestion}>
                                            <span className='btn-inner-text'>Add question</span>
                                        </button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='row mb-4'>
                                        <div className='col-lg-12'>
                                            <label className='form-control-label'>Title</label>
                                            <input
                                                placeholder='Add survey title'
                                                className='form-control'
                                                {...register('title', { required: true })}
                                            />
                                            {errors.title && <span className='invalid'>*This field is required</span>}
                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-lg-12'>
                                            <label className='form-control-label'>Intro Description</label>
                                            <textarea
                                                placeholder='Survey intro description'
                                                className='form-control'
                                                {...register('intro', { required: true })}
                                            />
                                            {errors.intro && <span className='invalid'>*This field is required</span>}
                                        </div>
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-lg-12'>
                                            <label className='form-control-label'>Outro Description</label>
                                            <textarea
                                                placeholder='Survey outro description'
                                                className='form-control'
                                                {...register('outro', { required: true })}
                                            />
                                        </div>
                                        {errors.outro && <span className='invalid'>*This field is required</span>}
                                    </div>
                                    <div className='row mb-4'>
                                        <div className='col-lg-12'>
                                            <label className='form-control-label'>Status</label>
                                            <Select
                                                id='title'
                                                variant='outlined'
                                                className='form-control'
                                                defaultValue={' '}
                                                {...register('status', { required: true })}
                                                placeholder='Add attribute title'
                                                fullWidth={true}
                                                style={{ fontSize: '14px', borderRadius: '12px' }}>
                                                <MenuItem disabled value={' '}>
                                                    <span className='placeholder-text'>Select survey status...</span>
                                                </MenuItem>
                                                {statusList.map((type) => {
                                                    return (
                                                        <MenuItem key={type.value} value={type.value}>
                                                            {type.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.status && <span className='invalid'>*This field is required</span>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={isAnOpenSurvey}
                                                        onChange={(e) => setIsAnOpenSurvey(e.target.checked)}
                                                    />
                                                }
                                                label='Open Survey'
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4'
                                                disabled={questions.length === 0 || addingSurvey}>
                                                {!addingSurvey && <span>Add</span>}
                                                {addingSurvey && (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md='12' lg='6'>
                        {questions.map((question) => {
                            return (
                                <QuestionSection
                                    onQuestionChange={onQuestionChange}
                                    typeList={typeList}
                                    question={question}
                                    key={question.key}
                                    questions={questions}
                                />
                            );
                        })}
                    </Col>
                </Row>
            </Container>
        </>
    );
}
