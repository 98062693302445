/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import ReactDatetimeClass from 'react-datetime';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
import { ADD_OR_UPDATE_CAMPAIGN, GET_CAMPAIGNS, GET_CAMPAIGN_CATEGORIES } from '../../../../graphql/campain.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import useToast from '../../../../hooks/useToast';
import { CampaignCategory } from '../../../../models/campain.model';
import Spinner from '../../../../components/Spinner/Spinner';
import { generate } from 'randomstring';

type FormData = {
    title: string;
    description: string;
    startDate: string;
    pageLink: string;
    endDate: string;
    category: string;
};

export default function CampaignAddForm() {
    const navigate = useNavigate();
    const { data, loading, error } = useQuery(GET_CAMPAIGN_CATEGORIES);

    const [addCampaign, { loading: adding, data: addedCampaign, error: addingError }] = useMutation(
        ADD_OR_UPDATE_CAMPAIGN,
        { refetchQueries: [{ query: GET_CAMPAIGNS }] },
    );
    const { showToast, Notification } = useToast();
    const {
        register,
        setValue,
        setError,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        if (error) {
            showToast('AN error has occurred while trying to load the page', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (addedCampaign) {
            showToast('Successfully added campaign', 'success');
        }
    }, [addedCampaign]);

    useEffect(() => {
        if (addingError) {
            showToast('An error has occurred while trying to add the campaign', 'error');
        }
    }, [addingError]);

    const onSubmit = async (formData: FormData) => {
        if (formData.category === 'empty' || formData.category === '') {
            setError('category', { message: '*This field is required', type: 'custom' });
            return;
        }

        const campaign = {
            title: formData.title,
            start_date: formData.startDate,
            end_date: formData.endDate,
            page_link: formData.pageLink,
            description: formData.description,
            category_enum: formData.category,
            code: generate(8).toUpperCase(),
        };

        await addCampaign({
            variables: {
                campaign,
            },
        });
    };

    const backToCampaignView = () => navigate(-1);

    if (loading) {
        return <LinearProgress />;
    }

    const campaignCategories: CampaignCategory[] = data?.categories;

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card className='mt-4'>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={backToCampaignView}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>
                                    <h3 className='m-0'>Add campaign</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-name'>
                                                    Title
                                                </label>
                                                <input
                                                    {...register('title', { required: true })}
                                                    className='form-control'
                                                    id='p-member-name'
                                                    placeholder='Enter campaign title...'
                                                    type='text'
                                                />
                                                {errors.title && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Start date
                                                </label>
                                                <ReactDatetimeClass
                                                    inputProps={{
                                                        placeholder: 'Enter starting date...',
                                                        autoComplete: 'off',
                                                        ...register('startDate', { required: true }),
                                                    }}
                                                    dateFormat={'YYYY-MM-DD'}
                                                    onChange={(date: moment.Moment) => {
                                                        setValue('startDate', date.format('YYYY-MM-DD'));
                                                    }}
                                                    timeFormat={false}
                                                />
                                                {errors.startDate && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>

                                        <div className='col-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    End date
                                                </label>
                                                <ReactDatetimeClass
                                                    inputProps={{
                                                        placeholder: 'Enter ending date...',
                                                        autoComplete: 'off',
                                                        ...register('endDate', { required: true }),
                                                    }}
                                                    dateFormat={'YYYY-MM-DD'}
                                                    onChange={(date: moment.Moment) => {
                                                        setValue('endDate', date.format('YYYY-MM-DD'));
                                                    }}
                                                    timeFormat={false}
                                                />
                                                {errors.startDate && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-name'>
                                                    Page Link
                                                </label>
                                                <input
                                                    {...register('pageLink', { required: true })}
                                                    className='form-control'
                                                    id='p-member-name'
                                                    placeholder='Paste the desired link for the campaign...'
                                                    type='text'
                                                />
                                                {errors.pageLink && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-name'>
                                                    Description
                                                </label>
                                                <textarea
                                                    {...register('description', { required: true })}
                                                    className='form-control'
                                                    rows={5}
                                                    id='p-member-name'
                                                    placeholder='Enter campaign description...'
                                                />
                                                {errors.description && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <label className='form-control-label' htmlFor='status'>
                                                Category
                                            </label>
                                            <Select
                                                id='status'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                placeholder='Select a category'
                                                defaultValue={'empty'}
                                                inputProps={{
                                                    ...register('category', {
                                                        required: true,
                                                    }),
                                                }}>
                                                <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                    Select a campaign category...
                                                </MenuItem>
                                                {campaignCategories?.map((status) => {
                                                    return (
                                                        <MenuItem key={status.value} value={status.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.category && (
                                                <span className='invalid'>{errors.category.message}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={adding}>
                                                {!adding && <span>Add</span>}
                                                {adding && <Spinner />}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
