/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import BookingTimeLine from './BookingTimeLine';
import { GET_DAY_MEETING_ITEM_BOOKINGS, GET_TODAY_INCOME_DIFFERENCE } from '../../../graphql/meeting-rooms.graphql';
import { useQuery } from '@apollo/client';
import { MeetingRoomItem } from '../../../models/meeting.room.model';
import { LinearProgress } from '@mui/material';
import moment from 'moment';
import BookingDialog from '../../../components/BookingDialog';
import { Booking, BookingStatus } from '../../../models/bookings.model';
import { GET_BOOKING_STATUS } from '../../../graphql/clients.graphql';
import TodaysDifferenceCard from './components/TodaysDifferenceCard';
import ActualAndExpectedIncomeCards from './components/ActualAndExpectedIncomeCards';

function BookingsView() {
    const startDate = moment().startOf('day').utc(false);
    const endDate = moment().endOf('day').utc(false);
    const [showBookingDialog, setShowBookingDialog] = useState<boolean>(false);
    const [selectedBooking, setSelectedBooking] = useState<Booking | undefined>();
    const [meetingRoomItemId, setMeetingRoomItemId] = useState<string | undefined>();

    const isFirstRenderRef = useRef<boolean>(true);

    const [bookingTime, setBookingTime] = useState<any>({
        start: startDate,
        end: endDate,
    });
    const { loading, data, refetch } = useQuery(GET_DAY_MEETING_ITEM_BOOKINGS, {
        variables: {
            start: startDate,
            end: endDate,
        },
        fetchPolicy: 'network-only',
    });

    const { loading: loadingStatus, data: statusList } = useQuery(GET_BOOKING_STATUS);
    const {
        data: income,
        loading: loadingIncome,
        refetch: refetchIncome,
    } = useQuery(GET_TODAY_INCOME_DIFFERENCE, {
        variables: {
            args: {
                selected_date: moment(bookingTime?._d).format('YYYY-MM-DD'),
            },
        },
    });

    useEffect(() => {
        isFirstRenderRef.current = false;
    }, []);

    useEffect(() => {
        if (bookingTime?.start) {
            refetch({
                start: bookingTime['start'],
                end: bookingTime['end'],
            });
        }
    }, [bookingTime]);

    const refetchIncomeData = (date: any) => {
        if (bookingTime?.start) {
            refetchIncome({
                args: {
                    selected_date: date,
                },
            });
        }
    };

    const openTimeline = () => {
        window.open('/conference-timeline');
    };

    useEffect(() => {
        if (selectedBooking) setShowBookingDialog(true);
    }, [selectedBooking]);

    if (loading || loadingStatus || loadingIncome) {
        return <LinearProgress />;
    }

    const rooms: MeetingRoomItem[] = data?.meetingRoomItem;

    const status: BookingStatus[] = statusList?.status;

    return (
        <div>
            {showBookingDialog && (
                <BookingDialog
                    open={showBookingDialog}
                    handleClose={() => setShowBookingDialog(false)}
                    rooms={rooms}
                    bookingId={selectedBooking?.id}
                    statusList={status}
                    roomItemId={meetingRoomItemId}
                />
            )}
            <Container className='mt-4' fluid>
                <div className='col'>
                    <Row>
                        <Col xs='6' className='d-flex p-0'>
                            <h1 className='mb-4'>Calendar</h1>
                        </Col>
                        <Col xs='6' className='text-right'>
                            <Button onClick={openTimeline} className='btn btn-round btn-sm btn-dark'>
                                View timeline
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <TodaysDifferenceCard chartInfo={income?.incomeData} />
                        <div>
                            <ActualAndExpectedIncomeCards chartInfo={income?.incomeData} />
                        </div>
                    </Row>
                </div>
                <Row>
                    <Col>
                        <BookingTimeLine
                            rooms={rooms}
                            changeDate={setBookingTime}
                            getMeetingRoomItemId={setMeetingRoomItemId}
                            getSelectedBooking={setSelectedBooking}
                            onDialogOpen={() => setShowBookingDialog(true)}
                            refetchIncomeData={refetchIncomeData}
                        />
                    </Col>

                    <Col></Col>
                </Row>
            </Container>
        </div>
    );
}

export default BookingsView;
