import React from 'react';

interface SubrowProps {
    children: React.ReactNode;
}

function SubRow(props: SubrowProps) {
    return <div style={{ height: 40, position: 'relative', padding: '12px' }}>{props.children}</div>;
}

export default SubRow;
