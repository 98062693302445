/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FormGroup } from 'reactstrap';
// import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    DialogContent,
    MenuItem,
    Select,
    Button,
    DialogActions,
    CircularProgress,
    Autocomplete,
    TextField,
} from '@mui/material';

import { ADD_QUOTE } from '../../../../graphql/quotes.graphql';
import useToast from '../../../../hooks/useToast';
import { AccountSearchQueryResult, Client } from '../../../../models/client.model';
import { QuoteStatus, QuoteType } from '../../../../models/quotes.models';
import { generate } from 'randomstring';

import { useForm } from 'react-hook-form';
import { SEARCH_ACCOUNTS } from '../../../../graphql/clients.graphql';
import { useNavigate } from 'react-router-dom';

type FormData = {
    client: string;
    status: string;
    type: string;
};

interface AddQuoteFormProps {
    handleClose: () => void;
    onSuccess: () => void;
    clients: Client[];
    quoteTypes: QuoteType[];
    quoteStatus: QuoteStatus[];
}

const AddQuoteForm = ({ handleClose, onSuccess, quoteStatus, quoteTypes }: AddQuoteFormProps) => {
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormData>();
    const { showToast, Notification } = useToast();
    const [addQuote, { loading: addingQuote, error: addingQuoteError, data: addedQuoteData }] = useMutation(ADD_QUOTE, {
        refetchQueries: ['getQuotes'],
    });

    const [searchAccounts, { loading, data, error, called }] = useLazyQuery(SEARCH_ACCOUNTS);

    const [accounts, setAccounts] = useState<AccountSearchQueryResult[]>([]);
    const navigate = useNavigate();

    const [query, setQuery] = useState<string>();

    const onSubmit = async (data: FormData) => {
        const quoteData = {
            client_id: data.client,
            type_enum: data.type,
            status_enum: data.status,
            paystack_reference: generate(10).toUpperCase(),
        };

        await addQuote({ variables: { quote: quoteData } });
    };

    useEffect(() => {
        if (query && query.length >= 3) {
            searchAccounts({ variables: { query: `%${query}%` } });
        }
    }, [query, searchAccounts]);

    useEffect(() => {
        if (called && data) {
            setAccounts(data.accounts);
        }
    }, [loading, error, called, data]);

    useEffect(() => {
        if (addedQuoteData) {
            reset();
            onSuccess();
            navigate(`/admin/quotes/details/${addedQuoteData?.quote?.id}`);
            handleClose();
        }
    }, [addedQuoteData]);

    useEffect(() => {
        if (addingQuoteError) {
            showToast('An error has occurred while trying to add quote', 'danger');
        }
    }, [addingQuoteError]);

    return (
        <div className='w-100'>
            {Notification}
            <DialogContent>
                <form
                    style={{
                        width: 300,
                        height: '100%',
                    }}
                    onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <label className='form-control-label' htmlFor='name'>
                            Client
                        </label>
                        <Autocomplete
                            id='client'
                            {...register('client', {
                                required: true,
                            })}
                            onChange={(event, value) => {
                                const _value: any = value?.id;
                                setValue('client', _value);
                            }}
                            onInputChange={(event, value) => {
                                setQuery(value);
                            }}
                            filterOptions={(options) => options}
                            options={accounts}
                            getOptionLabel={(option) => option.companyName || option.individualName}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder='Select client...'
                                    fullWidth={true}
                                    variant='outlined'
                                    style={{ fontSize: '14px' }}
                                />
                            )}
                        />
                        {errors.client && <span className='invalid'>*This field is required</span>}
                    </FormGroup>
                    <FormGroup>
                        <label className='form-control-label' htmlFor='name'>
                            Status
                        </label>
                        <Select
                            id='status'
                            sx={{ borderRadius: '12px', padding: '0' }}
                            className='form-control'
                            inputProps={{
                                ...register('status', {
                                    required: true,
                                }),
                            }}
                            defaultValue={'draft'}>
                            {quoteStatus.map((status) => {
                                return (
                                    <MenuItem key={status.value} value={status.value}>
                                        {status.title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {errors.status && <span className='invalid'>*This field is required</span>}
                    </FormGroup>
                    <FormGroup>
                        <label className='form-control-label' htmlFor='name'>
                            Type
                        </label>
                        <Select
                            id='status'
                            sx={{ borderRadius: '12px', padding: '0' }}
                            className='form-control'
                            defaultValue={'empty'}
                            inputProps={{
                                ...register('type', {
                                    value: '',
                                    required: true,
                                }),
                            }}>
                            <MenuItem disabled value={'empty'}>
                                <span className='placeholder-text'>Select quote type...</span>
                            </MenuItem>
                            {quoteTypes.map((type) => {
                                return (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {errors.type && <span className='invalid'>*This field is required</span>}
                    </FormGroup>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type='submit' disabled={addingQuote}>
                            {addingQuote ? (
                                <span>
                                    <CircularProgress size={16} />
                                </span>
                            ) : (
                                <span>Add</span>
                            )}
                        </Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </div>
    );
};

export default AddQuoteForm;
