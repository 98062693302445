import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { ConferenceBookingDocument } from '../../../../models/bookings.model';
import useToast from '../../../../hooks/useToast';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

interface AddDocumentDialogProps {
    isOpen: boolean;
    getDocuments: (documents: ConferenceBookingDocument[]) => void;
    documents: ConferenceBookingDocument[];
    handleClose: () => void;
}

export default function AddDocumentDialog({ isOpen, handleClose, documents, getDocuments }: AddDocumentDialogProps) {
    const fileInputRef = useRef<HTMLInputElement>();
    const [selectedFile, setSelectedFile] = useState<File>();
    const [documentName, setDocumentName] = useState<string>('');

    const openFileExplorer = () => {
        if (fileInputRef.current) fileInputRef?.current?.click();
    };

    const { showToast, Notification } = useToast();

    const handleFileAttach = (e: any) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleAddDocument = () => {
        if (!selectedFile) {
            showToast('Please attach a file before adding a document', 'danger');
            return;
        }

        const document: ConferenceBookingDocument = {
            id: undefined,
            name: documentName,
            selectedFile: selectedFile,
        };

        getDocuments([...documents, document]);
        handleClose();
    };
    return (
        <>
            {Notification}
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>Add Document</DialogTitle>

                <DialogContent
                    sx={{
                        width: 600,
                    }}>
                    <Row>
                        <div className='col'>
                            <FormGroup>
                                <label className='form-control-label'>Name</label>
                                <Input
                                    onChange={(e) => {
                                        setDocumentName(e.target.value);
                                    }}
                                    className='form-control'
                                    placeholder='Enter document name...'
                                    type='text'
                                />
                            </FormGroup>
                            <FormGroup>
                                <span
                                    className=' mt-4 btn btn-round btn-dark btn-sm'
                                    color='default'
                                    onClick={openFileExplorer}
                                    id='tooltip969372949'>
                                    <span className='btn-inner--text'> Attach file</span>
                                </span>
                                <input
                                    onChange={handleFileAttach}
                                    style={{ display: 'none' }}
                                    multiple
                                    accept='.pdf, .docx, .xlsx'
                                    type='file'
                                    ref={fileInputRef}
                                />
                            </FormGroup>
                        </div>
                    </Row>
                    <Row className='mt-4'>
                        <Col>{selectedFile && <InsertDriveFileOutlinedIcon fontSize={'large'} />}</Col>
                    </Row>
                </DialogContent>

                <DialogActions>
                    <div className='mt-4 d-flex h-100 p-2'>
                        <div className='align-self-end ml-auto'>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                onClick={handleAddDocument}
                                disabled={documentName == '' || !documentName}
                                type='button'>
                                Add
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}
