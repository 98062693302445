/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Select } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row, Spinner } from 'reactstrap';
import { ADD_ACCOUNT_DOCUMENT, GET_DOCUMENT_TYPES } from '../../../graphql/clients.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import useToast from '../../../hooks/useToast';
import { uploadDocument } from '../../../hooks/upload-documents.hook';
import { useDropzone } from 'react-dropzone';
import { DocumentType } from '../../../models/client.model';

type FormData = {
    name: string;
    description: string;
    type: string;
};

export default function ClientDocumentForm({ backToDocuments, clientId }: any) {
    const [file, setFile] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [documentType, setDocumentType] = useState<any>();
    const { showToast, Notification } = useToast();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onDrop = useCallback((acceptedFile: File[]) => {
        setFile(acceptedFile[acceptedFile?.length - 1]);
    }, []);

    console.log('File : ', file);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        },
    });

    const {
        register,
        handleSubmit,
        setValue,
        reset: resetForm,
        formState: { errors },
    } = useForm<FormData>();

    const { data: typeData } = useQuery(GET_DOCUMENT_TYPES);

    const [addAccountDocument, { error, data, reset }] = useMutation(ADD_ACCOUNT_DOCUMENT, {
        // refetchQueries: [{ query: GET_CLIENT }, "GetClient"],
        refetchQueries: ['GetClient'],
    });

    useEffect(() => {
        if (typeData) {
            setDocumentType(typeData.documentTypes);
        }
    }, [typeData]);

    useEffect(() => {
        if (data && !error) {
            showToast('Successfully Added Document', 'success');
            reset();
            resetForm();
            setIsLoading(false);
        }

        if (error) {
            showToast('An error has occurred, please try again', 'danger');
            setIsLoading(false);
        }
    }, [data, error]);

    const onSubmit = async (data: FormData) => {
        if (!file) {
            showToast('Please make sure that a file is selected', 'danger');
            return;
        }

        setIsLoading(true);
        const documentKey = await uploadDocument(file, 'account_document');
        let documentData: any;

        if (data.type === 'empty') {
            setValue('type', '');
            return;
        }

        if (documentKey === 'error') {
            showToast('An error has occurred trying to upload the document', 'danger');
            setIsLoading(false);
            return;
        }

        documentData = {
            account_id: clientId,
            name: data.name,
            description: data.description,
            type_enum: data.type,
            path: documentKey,
            deleted_at: null,
        };

        addAccountDocument({
            variables: { document: documentData },
        });
    };

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    onClick={() => backToDocuments()}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14' }} />
                                    <span>Back</span>
                                </button>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='document-name'>
                                        Name
                                    </label>
                                    <input
                                        className='form-control'
                                        id='document-name'
                                        placeholder='Enter document name...'
                                        type='text'
                                        {...register('name', {
                                            required: true,
                                        })}
                                    />
                                    {errors.name && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label mt-4' htmlFor='document-type'>
                                        Type
                                    </label>
                                    <Select
                                        id='document-type'
                                        className='form-control'
                                        defaultValue={'empty'}
                                        sx={{ borderRadius: '12px', padding: '0' }}
                                        {...register('type', {
                                            required: true,
                                        })}>
                                        <MenuItem value={'empty'} disabled className='placeholder-text'>
                                            Select document type...
                                        </MenuItem>
                                        {documentType?.map((type: DocumentType) => {
                                            return (
                                                <MenuItem key={type.value} value={type.value}>
                                                    {type.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.type && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label mt-4' htmlFor='document-description'>
                                        Description
                                    </label>
                                    <textarea
                                        className='form-control'
                                        id='document-description'
                                        placeholder='Enter document description...'
                                        rows={3}
                                        {...register('description', {
                                            required: true,
                                        })}
                                    />
                                    {errors.description && <span className='invalid'>*This field is required</span>}
                                </FormGroup>

                                <FormGroup>
                                    <div {...getRootProps()} className='document-container'>
                                        {!file ? <h5 className='text-center'>Select Document</h5> : file.name}
                                        <div className='overlay'>
                                            <input {...getInputProps()} />
                                        </div>
                                    </div>
                                </FormGroup>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button className='btn btn-dark btn-block' type='submit' disabled={isLoading}>
                                            {isLoading ? <Spinner /> : 'Add'}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
