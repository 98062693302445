/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { QuoteItem } from '../../../../models/quotes.models';
import { useMutation } from '@apollo/client';
import { REMOVE_QUOTE_ITEM } from '../../../../graphql/quotes.graphql';
import SweetAlert from 'react-bootstrap-sweetalert';
import useToast from '../../../../hooks/useToast';
import { VendorMenuPackageItem } from '../../../../models/vendor.model';
import { BookingResource } from '../../../../models/resources.model';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { BookingRoomExtra } from '../../../../models/bookings.model';

interface QuoteItemInfoProps {
    item: QuoteItem;
    isInAreas: boolean;
    onClick: () => void;
}

const QuoteItemInfo = ({ item, onClick, isInAreas }: QuoteItemInfoProps) => {
    const [alert, setAlert] = useState<any>(null);
    const { showToast, Notification } = useToast();
    const [removeQuoteItem, { data: removedQuote, error: removedQuoteError }] = useMutation(REMOVE_QUOTE_ITEM, {
        refetchQueries: ['getQuote'],
    });

    const statusTextClassName = (status: string) => {
        let className = '';
        switch (status) {
            case 'pending':
                className = 'text-warning';
                break;
            case 'cancelled':
                className = 'text-danger';
                break;
            default:
                className = 'text-success';
        }

        return className;
    };

    useEffect(() => {
        if (removedQuoteError) {
            showToast('An error has occurred while trying to remove the item', 'danger');
        }
    }, [removedQuoteError]);

    useEffect(() => {
        if (removedQuote) {
            showToast('Quote item removed successfully', 'success');
        }
    }, [removedQuote]);

    const getMenuItemTotalPrice = (): number => {
        const menuPackages: VendorMenuPackageItem[] = item.booking?.menuPackages || [];
        let totalPrice = 0;

        menuPackages.forEach((item) => {
            totalPrice += item.totalPrice;
        });

        return totalPrice;
    };

    const getAfterHourTotalPrice = (): number => {
        let totalPrice = 0;

        const bookingResources: BookingResource[] = item.booking?.bookingResources || [];

        bookingResources.forEach((resource) => {
            totalPrice += resource?.totalPrice;
        });

        return totalPrice;
    };

    const getRoomAddOnsTotalPrice = (): number => {
        let totalPrice = 0;
        const roomExtras: BookingRoomExtra[] = item?.booking?.roomExtras || [];
        roomExtras.map((roomExtra) => {
            totalPrice += roomExtra.totalPrice;
            return roomExtra;
        });
        return totalPrice;
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeQuoteItem({
                        variables: { id: props.item.id },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This quote item will be removed from the system.
            </SweetAlert>,
        );
    };
    return (
        <>
            {alert}
            {Notification}
            <div className='row py-2'>
                <div
                    className='col-3'
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={onClick}>
                    <img
                        className='order-image'
                        src={`${process.env.REACT_APP_IMAGE_URL}/${item.booking.meetingRoomItem.featuredImage}`}
                        alt='item'
                    />
                </div>
                {item.booking && (
                    <div className='col-7'>
                        <h4 className='mb-1'>
                            {item.booking.requiresVerification && (
                                <FiberManualRecordIcon
                                    sx={{
                                        color: '#FF0000',
                                        width: 16,
                                        height: 16,
                                    }}
                                />
                            )}
                            {'  '}
                            {item.booking.subject}
                        </h4>

                        <p className='card-text mb-0'>{item.booking.meetingRoomItem.name}</p>
                        <p className='card-text mb-0'>
                            <span className='small light'>
                                {moment(item.booking.from).format('yyyy-MM-DD')} (
                                {moment(item.booking.from).format('HH:mm')} - {moment(item.booking.to).format('HH:mm')})
                            </span>
                        </p>
                        <p className='card-text text-strong mb-0 d-flex'>
                            Status{' '}
                            <p
                                className={`text-sm ${statusTextClassName(
                                    item?.booking?.status?.value,
                                )} ml-2`}>{` ${item?.booking?.status?.title}`}</p>
                        </p>
                        {item.booking.discount === 0 && item.booking.status?.value !== 'cancelled' && (
                            <p className='card-text text-strong mb-0'>
                                Room price {` R${item.booking.totalPrice?.toFixed(2)}`}
                            </p>
                        )}
                        {item.booking.discount > 0 && item.booking.status?.value !== 'cancelled' && (
                            <>
                                <p
                                    style={{ textDecoration: isInAreas ? 'line-through' : 'none' }}
                                    className='card-text'>
                                    Discount {` R${item.booking.discount?.toFixed(2)}`}
                                </p>
                                <p className='card-text text-strong mb-0'>
                                    Room price{' '}
                                    {` R${
                                        item.booking && !isInAreas
                                            ? item.booking.quotedPrice?.toFixed(2)
                                            : item.booking.totalPrice?.toFixed(2)
                                    }`}
                                </p>
                            </>
                        )}
                        {item.booking?.menuPackages.length > 0 && item.booking.status?.value !== 'cancelled' && (
                            <div>
                                <h5 className='mb-0'>Catering - {`R${getMenuItemTotalPrice()?.toFixed(2)}`}</h5>
                            </div>
                        )}
                        {item.booking?.bookingResources.length > 0 && item.booking.status?.value !== 'cancelled' && (
                            <div>
                                <h5>Resources - {`R${getAfterHourTotalPrice()?.toFixed(2)}`}</h5>
                            </div>
                        )}
                        {item.booking?.roomExtras.length > 0 && item.booking.status?.value !== 'cancelled' && (
                            <div>
                                <h5>Add-ons - {`R${getRoomAddOnsTotalPrice()?.toFixed(2)}`}</h5>
                            </div>
                        )}
                    </div>
                )}
                <div className='col-2'>
                    <Button
                        className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                        onClick={() => {
                            console.log('deleted');
                            displayAlert({ warning: true, item });
                        }}>
                        <i className='fa-solid fa-trash-can'></i>
                    </Button>
                </div>
            </div>
        </>
    );
};

export default QuoteItemInfo;
