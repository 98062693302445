import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import useToast from '../../../hooks/useToast';
import { useMutation, useQuery } from '@apollo/client';
import SweetAlert from 'react-bootstrap-sweetalert';
import { LinearProgress } from '@mui/material';
import moment from 'moment';
import { getPermission } from '../../../utils/user.utils';
import { Link, useNavigate } from 'react-router-dom';
import BasicMenu from '../../../components/Menu';
import { orderBy } from 'lodash';
import { GET_BOOKING_VERIFICATIONS, REMOVE_VERIFICATION } from '../../../graphql/meeting-rooms.graphql';
import { BookingVerification } from '../../../models/bookings.model';
import { User } from '../../../models/user.model';
import { useSelector } from 'react-redux';

export default function BookingVerifications() {
    const { showToast, Notification } = useToast();
    const [alert, setAlert] = useState<any>(null);
    const { loading, error, data } = useQuery(GET_BOOKING_VERIFICATIONS);
    const [page, setPage] = useState<number>(0);
    const [verificationsFilter, setVerificationsFilter] = useState<string>('');
    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [sortFiled, setSortField] = useState<string>('date');
    const navigate = useNavigate();
    const [removeVerification, { loading: isRemoving, error: removeError, called }] = useMutation(REMOVE_VERIFICATION, {
        refetchQueries: [{ query: GET_BOOKING_VERIFICATIONS }],
    });

    const user: User | null = useSelector((state: any) => state.user.userInfo);

    useEffect(() => {
        if (error || removeError) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [error, removeError, showToast]);

    useEffect(() => {
        if (!isRemoving && called && !removeError) {
            showToast('Successfully removed verification', 'success');
        }
    }, [isRemoving, called, removeError, showToast]);

    const sortBy: any = (verifications: BookingVerification[], field: string, direction: string) => {
        console.log('SORTING', verifications, field, direction);
        const orderedVerifications = new Map([
            [
                'subject',
                orderBy(
                    verifications,
                    (verification: BookingVerification) => verification?.newBooking.booking.subject,
                    [direction === 'asc' ? 'asc' : 'desc'],
                ),
            ],
            [
                'newBooking',
                orderBy(
                    verifications,
                    (verification: BookingVerification) => verification?.newBooking.booking.bookingReference,
                    [direction === 'asc' ? 'asc' : 'desc'],
                ),
            ],
            [
                'status',
                orderBy(verifications, (verification: BookingVerification) => verification?.status.title, [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
            [
                'type',
                orderBy(verifications, (verification: BookingVerification) => verification?.type.title, [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
            [
                'date',
                orderBy(verifications, (verification: BookingVerification) => verification?.createdAt, [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
        ]);
        console.log('ORDERED', orderedVerifications);
        return orderedVerifications.get(field);
    };

    const verifications: BookingVerification[] = data?.verifications || [];
    console.log('verifications', verifications);
    let filteredVerifications = verifications.filter((verification: BookingVerification) => {
        const isVerificationsFiltered =
            verification.newBooking.reference?.toLowerCase().includes(verificationsFilter.toLowerCase()) ||
            verification.status.title.toLowerCase().includes(verificationsFilter.toLowerCase()) ||
            verification.type.title.toLowerCase().includes(verificationsFilter.toLowerCase());
        return isVerificationsFiltered;
    });

    filteredVerifications = sortBy(filteredVerifications, sortFiled, sortDirection);

    console.log('FILETeDDDD', filteredVerifications);

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeVerification({
                        variables: { id: props.verification.id },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This order will be removed from the system.
            </SweetAlert>,
        );
    };

    const getRowValue = (value: string) => {
        setRowsPerPage(parseInt(value));
        if (parseInt(value) !== rowPerPage) setPage(0);
    };

    const nextPage = () => {
        setPage(page + 1);
    };
    const previousPage = () => {
        setPage(page - 1);
    };

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <>
            {Notification}
            {alert}
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Booking Verifications</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>All Verifications</h3>
                                    </Col>
                                </Row>
                                <Row className='mt-4 border-0'>
                                    <Col>
                                        <Input
                                            className='form-control w-25'
                                            type='text'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setVerificationsFilter(e.target.value);
                                                setPage(0);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('newBooking');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='newBooking'
                                            scope='col'>
                                            Reference
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('subject');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='newBooking'
                                            scope='col'>
                                            Subject
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('date');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='date'
                                            scope='col'>
                                            Date
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Status
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('type');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='type'
                                            scope='col'>
                                            Type
                                        </th>
                                        <th scope='col' />
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredVerifications
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((verification: BookingVerification) => {
                                            return (
                                                <tr
                                                    key={verification.id}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() =>
                                                        navigate(`/admin/booking-verifications/${verification.id}`)
                                                    }>
                                                    <td>{verification.newBooking.booking.bookingReference}</td>
                                                    <td>{verification.newBooking.booking.subject}</td>

                                                    <td>{moment(verification.createdAt).format('yyyy-MM-DD')}</td>

                                                    <td>{verification.status.title}</td>
                                                    <td>{verification.type.title}</td>

                                                    <td className='text-right'>
                                                        {getPermission(user, 'booking-verifications', 'read') && (
                                                            <Link
                                                                className='btn btn-info btn-sm'
                                                                to={`/admin/booking-verifications/${verification.id}`}>
                                                                View
                                                            </Link>
                                                        )}

                                                        {getPermission(user, 'booking-verifications', 'delete') && (
                                                            <Button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, verification });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredVerifications.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredVerifications.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}
