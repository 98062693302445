/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
// import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { FormGroup, Container, Card, CardHeader, CardBody, Button, Row, Col } from 'reactstrap';
import { CREATE_USER, GET_PORTAL_ROLES } from '../../../graphql/user.graphql';
import { Role } from '../../../models/user.model';
import { LinearProgress, MenuItem, Select } from '@mui/material';
import Spinner from '../../../components/Spinner/Spinner';
import useToast from '../../../hooks/useToast';
import { PhoneInput } from 'react-international-phone';
import { useNavigate } from 'react-router-dom';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { GET_USERS } from '../../../graphql/queries';

type FormData = {
    name: string;
    surname: string;
    email: string;
    contactNumber: string;
    role: string;
};

const AddUserForm = () => {
    const navigate = useNavigate();
    const { showToast, Notification } = useToast();
    const { loading, error, data } = useQuery(GET_PORTAL_ROLES);
    const [createUserMutation, { loading: isCreating, error: createError, called, data: userData }] = useMutation(
        CREATE_USER,
        {
            refetchQueries: [{ query: GET_USERS }],
        },
    );
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        setError,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            role: '',
        },
    });

    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = (phone: string) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
            return false;
        }
    };

    useEffect(() => {
        if (error || createError) {
            showToast('An error has occurred, please try again', 'danger');
            console.log('Error creating user', createError);
        }
    }, [error, createError]);

    useEffect(() => {
        if (!isCreating && called && !createError) {
            showToast('Successfully created user', 'success');
            reset();
        }
    }, [isCreating, called]);

    useEffect(() => {
        if (userData) {
            navigate(`/admin/users/edit/${userData.user.id}`);
        }
    }, [userData]);

    const onSubmit = async (data: FormData) => {
        if (getValues('role') === 'empty') {
            setValue('role', '');
            return;
        }

        if (!isPhoneValid(data.contactNumber)) {
            setError('contactNumber', { type: 'custom', message: '*Please enter a valid phone number' });
            return;
        }

        const _user = {
            name: data.name,
            surname: data.surname,
            email: data.email,
            contact_number: data.contactNumber,
            role_id: data.role,
        };
        createUserMutation({
            variables: { user: _user },
        });
    };

    const roles: Role[] = data?.role || [];

    const redirectToUsers = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => redirectToUsers(e)}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>

                                    <h3 className='m-0'>Add user</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Name
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('name', { required: true })}
                                            id='name'
                                            placeholder="Enter user's name..."
                                            type='text'
                                        />
                                        {errors.name && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Surname
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('surname', { required: true })}
                                            id='surname'
                                            placeholder="Enter user's surname..."
                                            type='text'
                                        />
                                        {errors.surname && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='email'>
                                            Email
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('email', { required: true })}
                                            id='email'
                                            placeholder="Enter the user's email..."
                                            type='email'
                                        />
                                        {errors.email && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='contact'>
                                            Contact number
                                        </label>
                                        <PhoneInput
                                            defaultCountry='za'
                                            placeholder='Enter phone number...'
                                            inputClassName='form-control'
                                            value={watch('contactNumber')}
                                            style={
                                                {
                                                    '--react-international-phone-width': '100%',
                                                    '--react-international-phone-height': '44px',
                                                    '--react-international-phone-border-radius': '12px',
                                                } as React.CSSProperties
                                            }
                                            {...register('contactNumber', {
                                                required: true,
                                            })}
                                            onChange={(phone) => setValue('contactNumber', phone)}
                                        />
                                        {errors.contactNumber && (
                                            <span className='invalid'>
                                                {errors.contactNumber.message ?? '*This field is required'}
                                            </span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='role'>
                                            Role
                                        </label>
                                        <Select
                                            id='role'
                                            className='form-control'
                                            sx={{ borderRadius: '12px', padding: '0' }}
                                            placeholder='Select a role'
                                            defaultValue={'empty'}
                                            inputProps={{
                                                ...register('role', {
                                                    required: true,
                                                    value: 'empty',
                                                }),
                                            }}>
                                            <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                Select user role...
                                            </MenuItem>
                                            {roles.map((roleItem) => (
                                                <MenuItem key={roleItem.id} value={roleItem.id}>
                                                    {roleItem.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.role && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={isCreating}>
                                                {!isCreating ? (
                                                    <span>Add user</span>
                                                ) : (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default AddUserForm;
