import { gql } from '@apollo/client';

export const ADD_VENDOR = gql`
    mutation AddVendor($vendor: vendor_insert_input!) {
        vendor: insert_vendor_one(
            object: $vendor
            on_conflict: {
                constraint: vendor_pkey
                update_columns: [
                    name
                    description
                    registration_number
                    vat_number
                    status_enum
                    delivery_fee
                    type_enum
                    image_path
                    code
                ]
            }
        ) {
            id
        }
    }
`;

export const GET_VENDOR_RESOURCES = gql`
    query getVendorViewResources {
        vendorType: vendor_type {
            value
            title
        }

        vendorStatus: vendor_status {
            value
            title
        }

        location(where: { deleted_at: { _is_null: true } }) {
            id
            name
        }
    }
`;

export const GET_VENDORS = gql`
    query getVendors {
        vendor(where: { deleted_at: { _is_null: true } }) {
            id
            name
            description

            registrationNumber: registration_number
            vatNumber: vat_number
            imagePath: image_path
            status: vendor_status {
                title
                value
            }
            type: vendor_type {
                title
                value
            }
        }
    }
`;

export const GET_VENDOR = gql`
    query getVendor($id: uuid!) {
        vendorDetails: vendor_by_pk(id: $id) {
            id
            name
            description
            location {
                id
                name
            }
            code
            deliveryFee: delivery_fee
            equipmentItems: equipment_items(where: { deleted_at: { _is_null: true } }) {
                id
                name
                price
                featuredImage: featured_image
                status {
                    value
                    title
                }
                equipmentUnit: equipment_unit {
                    value
                    title
                }
                itemCategory: item_category {
                    value
                    title
                }
            }
            registrationNumber: registration_number
            vatNumber: vat_number
            imagePath: image_path
            status: vendor_status {
                title
                value
            }
            type: vendor_type {
                title
                value
            }
        }
    }
`;

export const REMOVE_VENDOR = gql`
    mutation removeVendor($id: uuid!) {
        update_vendor_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_VENDOR_EQUIPMENT_ITEM = gql`
    mutation addEquipmentItem($item: vendor_equipment_item_insert_input!) {
        insert_vendor_equipment_item_one(
            object: $item
            on_conflict: {
                constraint: vendor_equipment_item_pkey
                update_columns: [name, price, featured_image, status_enum, unit, category, unit]
            }
        ) {
            id
        }
    }
`;

export const GET_VENDOR_EQUIPMENT_RESOURCES = gql`
    query getVendorEquipmentResources {
        equipmentCategory: vendor_equipment_item_category {
            value
            title
        }
        equipmentStatus: vendor_equipment_item_status {
            value
            title
        }

        equipmentUnit: vendor_equipment_unit {
            value
            title
        }
    }
`;

export const DELETE_VENDOR_EQUIPMENT_ITEM = gql`
    mutation deleteEquipmentItem($id: uuid!) {
        update_vendor_equipment_item_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_CATERING_VENDORS = gql`
    query searchVendors {
        vendor(where: { _and: [{ deleted_at: { _is_null: true } }, { type_enum: { _eq: catering } }] }) {
            id
            name
            MenuPackages: vendor_menu_packages(where: { deleted_at: { _is_null: true } }) {
                id
                name
                price
                menuPackageType: menu_package_menu_package_type {
                    value
                    title
                }
                menuMealType: menu_package_menu_package_meal_type {
                    title
                    value
                }
                menuRates: menu_package_menu_package_rates {
                    id
                    price
                    maximumLimit: upper_limit
                    minimumLimit: lower_limit
                }
                featuredImage: featured_image
            }
        }
        mealType: menu_package_meal_type {
            title
            value
        }
    }
`;
