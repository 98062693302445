import { gql } from '@apollo/client';

export const GET_ONLINE_ORDERS = gql`
    query GetOnlineOrders {
        orders: order(
            where: { deleted_at: { _is_null: true }, account_id: { _is_null: false }, quote_id: { _is_null: true } }
            order_by: { created_at: desc }
        ) {
            id
            reference
            status: order_status {
                value
                title
            }
            quote {
                id
            }
            createdAt: created_at
            paymentStatus: payment_status {
                value
                title
            }
            invoicePath: invoice_path
            account: user_account {
                id
                company: company_name
                individualName: individual_name
                user {
                    id
                    name
                    surname
                    email
                }
            }
        }
    }
`;

export const GET_MANUAL_ORDERS = gql`
    query GetManualOrders {
        orders: order(
            where: { deleted_at: { _is_null: true }, account_id: { _is_null: false }, quote_id: { _is_null: false } }
            order_by: { created_at: desc }
        ) {
            id
            reference
            status: order_status {
                value
                title
            }
            quote {
                id
            }
            createdAt: created_at
            paymentStatus: payment_status {
                value
                title
            }
            invoicePath: invoice_path
            account: user_account {
                id
                company: company_name
                individualName: individual_name
                user {
                    id
                    name
                    surname
                    email
                }
            }
        }
    }
`;

export const GET_ORDER_DETAILS = gql`
    query GetOrderDetails($orderId: uuid!) {
        order: order_by_pk(id: $orderId) {
            id
            reference
            createdAt: created_at
            dueBy: due_by
            status: order_status {
                value
                title
            }
            quote {
                id
            }
            paymentStatus: payment_status {
                value
                title
            }
            invoicePath: invoice_path
            account: user_account {
                id
                company: company_name
                vatNumber: vat_number
                status {
                    value
                    title
                }
                user {
                    id
                    name
                    surname
                    email
                    contactNumber: contact_number
                }
            }
            items(where: { _and: { deleted_at: { _is_null: true } } }, order_by: { created_at: asc_nulls_last }) {
                id
                totalPrice: total_price
                quotedPrice: quoted_price
                price
                discount
                quantity
                unit
                booking {
                    id
                    reference
                    from: time_from
                    to: time_to
                    price
                    totalPrice: total_price
                    quotedPrice: quoted_price
                    unit
                    discount
                    quantity
                    status {
                        title
                        value
                    }
                    bookingResources: booking_after_hour_resources {
                        id
                        bookingId: booking_id
                        name
                        quantity
                        rate
                        resourceId: resource_id
                        totalPrice: total_price
                    }
                    roomExtras: room_extras {
                        id
                        quantity
                        rate
                        totalPrice: total_price
                    }
                    menuPackages: menu_packages {
                        id
                        unitPrice: price
                        vendorName: vendor_name
                        totalPrice: total_price
                        menuPackageName: menu_package_name
                        menuPackageId: menu_package_id
                        featuredImage: featured_image
                        vendorId: vendor_id
                        quantity
                    }
                    meetingRoomItem: meeting_room_item {
                        id
                        name
                        featuredImage: featured_image
                        meetingRoom: meeting_room {
                            id
                            name
                        }
                    }
                }
                packageBooking: package_booking {
                    id
                    reference
                    from
                    to
                    status: booking_status {
                        value
                        title
                    }
                    package: product_package {
                        id
                        name
                        featuredImage: featured_image
                    }
                }
            }
        }
        statusses: order_status(order_by: { title: asc }) {
            value
            title
        }
        paymentStatusses: payment_status {
            value
            title
        }
    }
`;

export const REMOVE_ORDER = gql`
    mutation RemoveOrder($orderId: uuid!) {
        update_order_by_pk(pk_columns: { id: $orderId }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const UPDATE_ORDER_STATUS = gql`
    mutation UpdateOrderStatus($orderId: uuid!, $status: order_status_enum) {
        update_order_by_pk(pk_columns: { id: $orderId }, _set: { status_enum: $status }) {
            id
        }
    }
`;

export const UPDATE_ORDER_PAYMENT_STATUS = gql`
    mutation UpdateOrderPaymentStatus($orderId: uuid!, $status: payment_status_enum) {
        update_order_by_pk(pk_columns: { id: $orderId }, _set: { payment_status_enum: $status }) {
            id
        }
    }
`;

export const GET_ORDER_EXPORTS = gql`
    query GetOrderExports {
        exports: order_export(where: { deleted_at: { _is_null: true } }, order_by: { created_at: desc }) {
            id
            datestamp
            from
            name
            to
            userAccount: user_account {
                companyName: company_name
                individualName: individual_name
            }
            path: file_path
            createdAt: created_at
            status {
                value
                title
            }
            type {
                value
                title
            }
        }
        types: order_export_type {
            value
            title
        }
        statuses: order_export_status {
            value
            title
        }
    }
`;

export const REMOVE_ORDER_EXPORT = gql`
    mutation RemoveOrderExport($exportId: uuid!) {
        update_order_export_by_pk(pk_columns: { id: $exportId }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const UPDATE_EXPORT_STATUS = gql`
    mutation UpdateOrderExport($exportId: uuid!, $status: order_export_status_enum) {
        update_order_export_by_pk(pk_columns: { id: $exportId }, _set: { status_enum: $status }) {
            id
        }
    }
`;

export const CREATE_ORDER_EXPORT = gql`
    mutation CreateOrdersExport($type: String!, $from: String!, $to: String!, $accountId: String, $name: String) {
        exportOrders(type: $type, from: $from, to: $to, accountId: $accountId, name: $name) {
            id
            path
        }
    }
`;

export const IS_ORDER_EXPORT_AVAILABLE = gql`
    query IsOrderExportAvailable($args: is_order_export_available_args!) {
        export: is_order_export_available(args: $args) {
            from
            isAvailable: is_available
        }
    }
`;
