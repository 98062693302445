/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Select, MenuItem } from '@mui/material';
import { useForm } from 'react-hook-form';
import FeaturedImage from '../../../components/Image component/ImageContainer';
import { useMutation } from '@apollo/client';
import { GET_PRODUCTS, UPDATE_PRODUCT } from '../../../graphql/products.graphql';
import useToast from '../../../hooks/useToast';
import Spinner from '../../../components/Spinner/Spinner';
import { useParams } from 'react-router-dom';
import { uploadImage } from '../../../hooks/upload-image.hook';

export default function DetailView({ product, productStatus, productTypes }: any) {
    type FormData = {
        name: string;
        status: string;
        type: string;
    };

    const [status, setStatus] = useState(product?.status.value);
    const [type, setType] = useState(product?.type.value);
    const fileInputRef: any = useRef(null);
    const [description, setDescription] = useState(product?.description);
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [imageFile, setImageFile] = useState<any>(null);
    const [image, setImage] = useState<any>(`${process.env.REACT_APP_IMAGE_URL}/${product.productImage}`);
    const { showToast, Notification } = useToast();
    const [updateProduct, { error: updateError, data: called }] = useMutation(UPDATE_PRODUCT, {
        refetchQueries: [{ query: GET_PRODUCTS }],
    });

    const param: any = useParams();

    useEffect(() => {
        if (updateError) {
            showToast('An error has occurred, please tyr again', 'danger');
            setImageLoading(false);
        }
    }, [updateError]);

    useEffect(() => {
        if (!updateError && called) {
            showToast('Product successfully updated', 'success');
            setImageLoading(false);
        }
    }, [called]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const onSubmit = async (data: FormData) => {
        let imageKey;
        setImageLoading(true);
        if (imageFile) imageKey = await uploadImage(imageFile, 'product_image');
        if (!imageFile && product.productImage) imageKey = product.productImage;
        if (imageKey === 'error') {
            showToast('An error has occurred while trying to add this image, please try again', 'danger');
            setImageLoading(false);
            return;
        }

        const _product = {
            name: data.name,
            description: description,
            product_status_enum: status,
            product_type_enum: type,
            featured_image: imageKey,
        };

        updateProduct({
            variables: {
                id: param.id,
                product: _product,
            },
        });
    };

    const changeImage = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(url);
    };

    return (
        <div>
            {Notification}
            <Row>
                <Col md='12' lg='6'>
                    <Card className='mt-4'>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='featured-image'>
                                        Featured Image
                                    </label>
                                    <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
                                    <input
                                        type='file'
                                        ref={fileInputRef}
                                        accept='image/*'
                                        style={{ display: 'none' }}
                                        onChange={(e) => changeImage(e)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Name
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('name', {
                                            required: true,
                                            value: product?.name,
                                        })}
                                        id='product'
                                        placeholder="Enter product's name..."
                                        type='text'
                                    />
                                    {errors.name && <span>This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='description'>
                                        Description
                                    </label>
                                    <CKEditor
                                        id='description'
                                        data={description}
                                        editor={ClassicEditor}
                                        config={{
                                            removePlugins: ['Heading'],
                                            toolbar: [
                                                'bold',
                                                'italic',
                                                'bulletedList',
                                                'numberedList',
                                                'blockQuote',
                                                'link',
                                            ],
                                        }}
                                        onChange={(event: any, editor: any) => {
                                            setDescription(editor.getData());
                                        }}
                                        onReady={(editor: any) => {}}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='status'>
                                        Status
                                    </label>
                                    <Select
                                        id='status'
                                        sx={{ borderRadius: '12px', padding: '0' }}
                                        value={status}
                                        className='form-control'
                                        onChange={(e) => {
                                            setStatus(e.target.value);
                                        }}
                                        placeholder='Select a role'
                                        defaultValue={''}>
                                        {productStatus.map((status: any) => {
                                            return (
                                                <MenuItem selected={true} value={status.value}>
                                                    {status?.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='type'>
                                        Type
                                    </label>
                                    <Select
                                        id='type'
                                        sx={{ borderRadius: '12px', padding: '0' }}
                                        className='form-control'
                                        value={type}
                                        placeholder='Select a role'
                                        onChange={(e) => {
                                            setType(e.target.value);
                                        }}
                                        defaultValue={''}>
                                        {productTypes.map((types: any) => {
                                            return (
                                                <MenuItem selected={true} value={types.value}>
                                                    {types.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormGroup>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 btn-block'
                                            disabled={imageLoading}>
                                            {imageLoading ? (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            ) : (
                                                <span>Update details</span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
