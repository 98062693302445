import React, { useState } from 'react';
import { ConferenceBookingDocument } from '../../../../models/bookings.model';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import { useMutation } from '@apollo/client';
import { GET_BOOKING_DETAILS, REMOVE_BOOKING_DOCUMENT } from '../../../../graphql/bookings.graphql';
import SweetAlert from 'react-bootstrap-sweetalert';
import { downloadPdf } from '../../../../utils/file.utils';

interface DocumentItemProps {
    document: ConferenceBookingDocument;
    documents: ConferenceBookingDocument[];
    getDocuments: (documents: ConferenceBookingDocument[]) => void;
}

export default function DocumentItem({ document, documents, getDocuments }: DocumentItemProps) {
    const [removedDocument] = useMutation(REMOVE_BOOKING_DOCUMENT, {
        refetchQueries: [GET_BOOKING_DETAILS],
    });

    const [alert, setAlert] = useState(null);
    const handleDocumentDelete = () => {
        const index = documents.indexOf(document);
        if (index === -1) {
            return;
        }
        if (document.id) {
            removedDocument({
                variables: {
                    id: document.id,
                },
            });
        }
        const newDocuments = documents.filter((doc) => documents.indexOf(doc) !== index);
        getDocuments(newDocuments);
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    handleDocumentDelete();
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This document will be removed from the system.
            </SweetAlert>,
        );
    };

    const handleOnDownload = () => {
        if (document.documentPath && document.id) {
            const url = `${process.env.REACT_APP_IMAGE_URL}/${document.documentPath}`;
            downloadPdf(url, `${document.id}`);
        }
    };

    return (
        <>
            {alert}
            <div className='row mt-4'>
                <div className='col-9'>
                    <p className='small-text overflow-text'>{document?.name || ''}</p>
                </div>
                <div className='col-3 d-flex alight-items-left'>
                    {document?.documentPath && (
                        <DownloadIcon
                            sx={{
                                cursor: 'pointer',
                            }}
                            className='ml-5'
                            onClick={handleOnDownload}
                        />
                    )}
                    <ClearIcon
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={() => displayAlert(document)}
                        className='ml-4'
                        color='error'
                    />
                </div>
            </div>
            <hr className='mt-1'></hr>
        </>
    );
}
