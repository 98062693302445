import { gql } from '@apollo/client';

export const CREATE_USER = gql`
    mutation CreateUser($user: CreateUserInput!) {
        user: create_user(user: $user) {
            id
        }
    }
`;

export const UPDATE_USER = gql`
    mutation UpdateUser($id: uuid!, $user: user_set_input!) {
        update_user_by_pk(pk_columns: { id: $id }, _set: $user) {
            id
        }
    }
`;

export const UPDATE_MEMBER = gql`
    mutation UpdateMember(
        $id: uuid!
        $user: user_set_input!
        $memberId: uuid!
        $member: account_team_member_set_input
    ) {
        update_account_team_member_by_pk(pk_columns: { id: $memberId }, _set: $member) {
            id
        }
        update_user_by_pk(pk_columns: { id: $id }, _set: $user) {
            id
        }
    }
`;

export const GET_ROLES = gql`
    query GetRoles {
        role(where: { level: { _eq: "client" } }, order_by: { name: asc }) {
            id
            tag
            name
        }
    }
`;

export const GET_PORTAL_ROLES = gql`
    query GetRoles {
        role(where: { level: { _neq: "client" } }, order_by: { name: asc }) {
            id
            tag
            name
        }
    }
`;

export const GET_USER_ROLE = gql`
    query GetUserRole {
        role(where: { tag: { _eq: "client" } }) {
            id
            name
            permissions: role_permissions {
                id
                read
                update
                delete
                create
                permission {
                    id
                    name
                    tag
                }
            }
        }
    }
`;

export const GET_PORTAL_USER = gql`
    query GetUser($id: uuid!) {
        user: user_by_pk(id: $id) {
            id
            name
            surname
            email
            contactNumber: contact_number
            role {
                id
                name
            }
        }
        role(order_by: { name: asc }, where: { level: { _eq: "portal" } }) {
            id
            name
            permissions: role_permissions {
                id
                read
                update
                delete
                create
                permission {
                    id
                    name
                    tag
                }
            }
        }
    }
`;

export const REMOVE_USER = gql`
    mutation RemoveUser($id: uuid!) {
        update_user_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;
