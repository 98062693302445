import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup } from '@mui/material';
import React, { useState } from 'react';
import { Input, Row } from 'reactstrap';
import { ConferenceBookingNote } from '../../../../models/bookings.model';

interface AddNoteDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    notes: ConferenceBookingNote[];
    selectedNote?: ConferenceBookingNote;
    getNotes: (notes: ConferenceBookingNote[]) => void;
}

export default function AddNoteDialog({ isOpen, handleClose, notes, getNotes, selectedNote }: AddNoteDialogProps) {
    const [note, setNote] = useState<string>(selectedNote?.notes ? selectedNote?.notes : '');

    const handleNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNote(e.target.value);
    };

    const onNoteAdd = () => {
        const newNote: ConferenceBookingNote = {
            notes: note,
        };

        if (selectedNote) {
            const newNotes = [...notes];
            const noteIndex = notes.indexOf(selectedNote);
            if (noteIndex === -1) return;
            newNotes[noteIndex] = { ...newNotes[noteIndex], notes: note };
            getNotes([...newNotes]);
            handleClose();

            return;
        }

        getNotes([...notes, newNote]);
        handleClose();
    };

    return (
        <>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>{note ? 'Update note' : 'Add note'}</DialogTitle>

                <DialogContent
                    sx={{
                        width: 600,
                    }}>
                    <Row>
                        <div className='col'>
                            <FormGroup>
                                <label className='form-control-label'>Notes</label>
                                <Input
                                    onChange={handleNoteChange}
                                    value={note}
                                    className='form-control'
                                    rows={4}
                                    placeholder='Enter yours notes here...'
                                    type='textarea'
                                />
                            </FormGroup>
                        </div>
                    </Row>
                </DialogContent>

                <DialogActions>
                    <div className='mt-4 d-flex h-100 p-2'>
                        <div className='align-self-end ml-auto'>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={onNoteAdd} disabled={note == '' || !note} type='button'>
                                {note ? 'Update' : 'Add'}
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
}
