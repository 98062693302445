/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import { getPermission } from '../../../utils/user.utils';
import { useSelector } from 'react-redux';
import { User } from '../../../models/user.model';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import useToast from '../../../hooks/useToast';
import _ from 'lodash';
import BasicMenu from '../../../components/Menu';
import SweetAlert from 'react-bootstrap-sweetalert';
import { GET_NEWSLETTER_EMAILS, REMOVE_NEWSLETTER_EMAIL } from '../../../graphql/newsletter-email.graphql';
import { Emails } from '../../../models/newsletter-emil.model';
import { SEND_MAIL_LIST_EMAILS } from '../../../graphql/mail-list.graphql';
import LoaderDialog from '../quotes/dialogs/LoaderDialog';

export default function EmailView() {
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const { showToast, Notification } = useToast();
    const [searchKey, setSearchKey] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [sortFiled, setSortField] = useState<string>('title');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [alert, setAlert] = useState<any>(null);
    const [sendEmails, { loading, error, data }] = useMutation(SEND_MAIL_LIST_EMAILS);

    const { data: emailData, loading: loadingEmails, error: emailError } = useQuery(GET_NEWSLETTER_EMAILS);

    const [removeNewsletterEmail, { data: emailRemoved, error: emailErrorRemove }] = useMutation(
        REMOVE_NEWSLETTER_EMAIL,
        {
            refetchQueries: [{ query: GET_NEWSLETTER_EMAILS }, 'getNewsletterEmails'],
        },
    );

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    const removeEmail = (id: string) => {
        removeNewsletterEmail({ variables: { id } });
    };

    useEffect(() => {
        if (data) {
            showToast('Successfully resent the email', 'success');
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to resend the email', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (emailError) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }

        if (emailErrorRemove) {
            showToast('An error has occurred while trying to remove a post', 'danger');
        }
    }, [emailError, emailErrorRemove]);

    useEffect(() => {
        if (emailRemoved) {
            showToast('Successfully removed email', 'success');
        }
    }, [emailRemoved]);

    const sortBy: any = (newsletterEmail: Emails[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['title', _.orderBy(newsletterEmail, (email) => email.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['subject', _.orderBy(newsletterEmail, (email) => email.subject, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'status',
                _.orderBy(newsletterEmail, (email) => email.status.title, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'template',
                _.orderBy(newsletterEmail, (email) => email.template?.name, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'mailList',
                _.orderBy(newsletterEmail, (email) => email.mailList?.name, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'publishedDate',
                _.orderBy(newsletterEmail, (email) => email.publishDate, [direction === 'asc' ? 'asc' : 'desc']),
            ],
        ]);
        return orderedBookings.get(field);
    };

    const newsletterEmail: Emails[] = emailData?.newsLetterEmail;

    let filteredEmail = newsletterEmail?.filter((email) => {
        if (searchKey === '') {
            return email;
        }
        return (
            email?.title?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            email?.subject?.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            email?.template?.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            email?.mailList?.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            email?.status?.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });

    if (loadingEmails) {
        return <LinearProgress />;
    }

    filteredEmail = sortBy(filteredEmail, sortFiled, sortDirection);

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeEmail(props.email.id);
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This email will be removed from the system.
            </SweetAlert>,
        );
    };

    return (
        <>
            {Notification}
            {alert}
            <LoaderDialog isOpen={loading} />
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Emails</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Sent Emails</h3>
                                    </Col>
                                    {getPermission(user, 'newsletter', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <Link
                                                to={'/admin/emails/new'}
                                                className=' btn btn-round btn-dark btn-sm'
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'> New email</span>
                                            </Link>
                                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                Send new email
                                            </UncontrolledTooltip>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col>
                                        <Input
                                            className='w-25'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                                setPage(0);
                                            }}></Input>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush'>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('subject');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='subject'
                                            scope='col'>
                                            Subject
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Status
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('template');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Template
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('mailList');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Mail List
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredEmail
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((email: Emails) => {
                                            return (
                                                <tr key={email.id}>
                                                    <td>{email.subject}</td>
                                                    <td>{email.status.title}</td>
                                                    <td>{email?.template?.name}</td>
                                                    <td>{email?.mailList?.name}</td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'newsletter', 'update') && (
                                                            <Button
                                                                className='btn btn-outlined-info btn-sm'
                                                                disabled={email.mailList.deletedAt !== null}
                                                                onClick={(e) => {
                                                                    sendEmails({
                                                                        variables: {
                                                                            id: email.id,
                                                                        },
                                                                    });
                                                                }}>
                                                                <span>Resend</span>
                                                            </Button>
                                                        )}
                                                        {getPermission(user, 'newsletter', 'update') && (
                                                            <Link
                                                                className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                                to={`/admin/emails/manage/${email.id}`}>
                                                                <i className='fa-solid fa-pencil'></i>
                                                            </Link>
                                                        )}

                                                        {getPermission(user, 'locations', 'delete') && (
                                                            <Button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={() => {
                                                                    displayAlert({ warning: true, email });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredEmail.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredEmail.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}
