import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import routes from '../routes';

import { PreLoader } from '../components/Pre-Loader';
import { useGetUser } from '../hooks/auth.hook';

function Admin(): any {
    const [sidenavOpen, setSidenavOpen] = useState(true);
    const location = useLocation();
    const mainContentRef: any = useRef(null);
    const { userLoading, user } = useGetUser();

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (user) {
            if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
            mainContentRef.current.scrollTop = 0;
        }
    }, [location, user]);

    // toggles collapse between mini sidenav and normal
    const toggleSidenav = useCallback(
        (e: any) => {
            if (document.body.classList.contains('g-sidenav-pinned')) {
                document.body.classList.remove('g-sidenav-pinned');
                document.body.classList.add('g-sidenav-hidden');
            } else {
                document.body.classList.add('g-sidenav-pinned');
                document.body.classList.remove('g-sidenav-hidden');
            }
            setSidenavOpen(!sidenavOpen);
        },
        [sidenavOpen],
    );

    if (!user || userLoading) {
        return <PreLoader />;
    }

    return (
        user && (
            <>
                <Sidebar
                    routes={routes}
                    toggleSidenav={toggleSidenav}
                    sidenavOpen={sidenavOpen}
                    logo={{
                        innerLink: '/',
                        imgSrc: require('../assets/img/brand/argon-react.png'),
                        imgAlt: '...',
                    }}
                />
                <div className='main-content' ref={mainContentRef}>
                    <Outlet />
                </div>
                {sidenavOpen ? <div className='backdrop d-xl-none' onClick={toggleSidenav} /> : null}
            </>
        )
    );
}

export default Admin;
