/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';
import Timeline, { DateHeader, TimelineHeaders, TimelineTimeSteps } from 'react-calendar-timeline';
import moment from 'moment';
import { Booking } from '../../../models/bookings.model';
import { MeetingRoomItem } from '../../../models/meeting.room.model';
import { Card, CardBody, FormGroup } from 'reactstrap';
import { getDayMissingTimes, getTime } from '../../../utils/booking.utils';
import { v4 } from 'uuid';
import ReactDatetimeClass from 'react-datetime';
import BasicMenu from '../../../components/Menu';
// import BookingTimeAverageView from "./components/BookingTimeAverageView";

const timeStepSettings: TimelineTimeSteps = {
    second: 1,
    minute: 1,
    hour: 0.5,
    day: 1,
    month: 1,
    year: 1,
};
interface BookingTimelineProps {
    rooms: MeetingRoomItem[];
    changeDate({}: any): void;
    getSelectedBooking(bookingItem: Booking | undefined): void;
    onDialogOpen(): void;
    getMeetingRoomItemId(id: string): void;
    refetchIncomeData: (date: any) => void;
}

const BookingTimeLine = ({
    rooms,
    changeDate,
    getSelectedBooking,
    onDialogOpen,
    getMeetingRoomItemId,
    refetchIncomeData,
}: BookingTimelineProps) => {
    const [roomFilter, setRoomFilters] = useState<string>('All');
    const [date, setDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'));
    const startingTime: any = moment();
    const endingTime: any = moment();
    const datePickerRef: any = useRef(null);
    const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
    });
    startingTime.set('hour', 8);
    endingTime.set('hour', 18);

    const validationTextColor = (percentage: number): string => {
        if (percentage >= 30 && percentage < 70) {
            return 'text-warning';
        }

        if (percentage >= 70) {
            return 'text-success';
        }

        return 'text-danger';
    };

    const getTotalBookingHoursPerDay = (bookings: Booking[]): number => {
        let totalHours: number = 0;

        for (let booking of bookings) {
            const startTime = moment(booking.from);
            const endTime = moment(booking.to);
            totalHours += Math.fround(endTime.diff(startTime, 'hours', true));
        }

        return totalHours;
    };

    const getBookingAveragePerRoom = (room: MeetingRoomItem) => {
        const totalHourPerDay = 9;
        const totalHoursBooked = getTotalBookingHoursPerDay(room.bookings);

        return (totalHoursBooked / totalHourPerDay) * 100;
    };

    let bookingItems: any[] = [];

    const roomGroups = rooms
        .filter((room) => {
            if (roomFilter === 'All') {
                return room;
            }
            return room.name.toLocaleLowerCase() === roomFilter.toLocaleLowerCase();
        })
        .map((room) => {
            const bookings: Booking[] = room.bookings;

            const missingSlots = getDayMissingTimes(startingTime.utc(false), room.bookings);
            const roomBookingItems: any[] = [];

            for (let booking of bookings) {
                const item = {
                    id: booking.id,
                    group: room.id,
                    title: booking.subject,
                    start_time: moment(booking.from),
                    end_time: moment(booking.to),
                };
                roomBookingItems.push(item);
            }

            // console.log("Booking in timeline from", missingSlots);

            const missingLinkItems = missingSlots.map((slot) => {
                const startHour = getTime(moment(slot.start).format('HH:mm'))[0];
                const endHour = getTime(moment(slot.end).format('HH:mm'))[0];

                //Don't forget to add the minutes OSWELL!
                const startingMinute = getTime(moment(slot.start).format('HH:mm'))[1];
                const endingMinute = getTime(moment(slot.end).format('HH:mm'))[1];

                return {
                    id: v4(),
                    group: room.id,
                    title: 'Open Slot',
                    className: 'open',
                    color: 'rgb(0, 0, 0)',
                    bgColor: 'red',
                    start_time: moment(date).hour(startHour).minute(startingMinute),
                    end_time: moment(date).hour(endHour).minute(endingMinute),
                };
            });

            bookingItems = [...bookingItems, ...roomBookingItems, ...missingLinkItems];
            return {
                id: room.id,
                title: room.name,
                rightTitle: `${getBookingAveragePerRoom(room)?.toFixed(2)}%`,
                tip: 'additional information',
                percentage: getBookingAveragePerRoom(room),
            };
        });

    const onChangeDate = (e: any) => {
        setDate(e._d);
        changeDate({
            start: moment(e._d).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            end: moment(e._d).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        });
        refetchIncomeData(moment(e._d).format('YYYY-MM-DD'));
    };

    const onDateClick = () => {
        datePickerRef.current.click();
    };

    return (
        <>
            <Card className='mt-0'>
                <CardBody>
                    <div className='row'>
                        <div className='col-4'>
                            <h3>Available bookings</h3>
                        </div>
                        <div className='col-4'>
                            <FormGroup>
                                <h3 className='date-header' onClick={onDateClick}>
                                    {moment(date).format('LL')}
                                </h3>
                                <ReactDatetimeClass
                                    inputProps={{
                                        placeholder: 'Select booking date...',
                                        ref: datePickerRef,
                                        style: { display: 'none' },
                                    }}
                                    dateFormat={'YYYY-MM-DD'}
                                    timeFormat={false}
                                    value={moment(date).format('YYYY-MM-DD')}
                                    onChange={(e) => onChangeDate(e)}
                                    // closeOnSelect={true}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-4'>
                            <div className='d-flex'>
                                <span>Room</span>
                                <div className='mt-1'>
                                    <BasicMenu
                                        getValue={setRoomFilters}
                                        options={['All', ...rooms.map((room) => room.name)].sort(collator.compare)}
                                        value={roomFilter}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col'>
                            <Timeline
                                groups={roomGroups.sort((a, b) => {
                                    return a.title.localeCompare(b.title, undefined, {
                                        numeric: true,
                                        sensitivity: 'base',
                                    });
                                })}
                                items={bookingItems}
                                sidebarContent='Rooms'
                                defaultTimeStart={moment(date).hour(8).minute(0)}
                                defaultTimeEnd={moment(date).hour(17).minute(30)}
                                visibleTimeStart={moment(date).hour(8).minute(0).valueOf()}
                                visibleTimeEnd={moment(date).hour(17).minute(30).valueOf()}
                                minZoom={6 * 60 * 60 * 1000}
                                maxZoom={12 * 60 * 60 * 1000}
                                timeSteps={timeStepSettings}
                                lineHeight={50}
                                sidebarWidth={200}
                                groupRenderer={({ group }) => {
                                    return (
                                        <div className='row'>
                                            <div className='col-8'>
                                                <span>{group.title}</span>
                                                {' -'}
                                            </div>
                                            <div className='col-4'>
                                                <span
                                                    className={`font-weight-bold ${validationTextColor(
                                                        group.percentage,
                                                    )}`}>{` ${group.rightTitle}`}</span>
                                            </div>
                                        </div>
                                    );
                                }}
                                canMove={false}
                                canResize={'left'}
                                onItemClick={(itemId) => {
                                    const item = bookingItems.find((bookingItem) => bookingItem.id === itemId);

                                    // console.log("Clicked");

                                    if (item.className === 'open') {
                                        // onDialogOpen();
                                        getMeetingRoomItemId(item.group);
                                        // getSelectedBooking(undefined);
                                    } else {
                                        const room = rooms.find((room) =>
                                            room.bookings.some((booking) => booking.id === itemId),
                                        );

                                        getMeetingRoomItemId(undefined);

                                        const booking: Booking | undefined = room?.bookings.find(
                                            (booking) => booking.id === itemId,
                                        );
                                        getSelectedBooking(booking);
                                        onDialogOpen();
                                    }
                                }}>
                                <TimelineHeaders>
                                    <DateHeader unit='primaryHeader' />
                                    <DateHeader
                                        unit='hour'
                                        labelFormat='HH:mm'
                                        style={{ height: 50, color: '#999999' }}
                                    />
                                </TimelineHeaders>
                            </Timeline>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default BookingTimeLine;
