/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { GET_DAY_MEETING_ITEM_BOOKINGS } from '../../../graphql/meeting-rooms.graphql';
import { useQuery } from '@apollo/client';
import { ResizeEndEvent, TimelineContext, Range, RowDefinition, ItemDefinition, Span } from 'dnd-timeline';
import Timeline from './components/timeline/Timeline';
import { MeetingRoomItem } from '../../../models/meeting.room.model';
import { setHours, setMinutes, setSeconds } from 'date-fns';
import { useGetUser } from '../../../hooks/auth.hook';
import { PreLoader } from '../../../components/Pre-Loader';

const DEFAULT_RANGE: Range = {
    start: setSeconds(setMinutes(setHours(new Date(), 7), 0), 0).getTime(),
    end: setSeconds(setMinutes(setHours(new Date(), 18), 30), 0).getTime(),
};

export default function ConferenceDayTimelineView() {
    const startDate = moment().startOf('day').utc(false);
    const endDate = moment().endOf('day').utc(false);
    const [range, setRange] = useState<Range>(DEFAULT_RANGE);
    const [rows, setRows] = useState([]);
    const [items, setItems] = useState([]);
    const { userLoading, user } = useGetUser();
    const { loading, data, refetch } = useQuery(GET_DAY_MEETING_ITEM_BOOKINGS, {
        variables: {
            start: startDate,
            end: endDate,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        //We set up a timer to refetch our data every after 5 minutes
        const interval = setInterval(() => {
            refetch({
                start: startDate,
                end: endDate,
            });
        }, 300000);
        return () => {
            console.log('Cleared interval');
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (data) {
            const rooms: MeetingRoomItem[] = data?.meetingRoomItem || [];
            const itemList: ItemDefinition[] = [];

            const rows: RowDefinition[] = rooms.map((room) => {
                room.bookings.forEach((booking) => {
                    let id = booking.client?.companyName || booking.client?.individualName || 'Unknown';
                    const rowId = room.name;
                    const disabled = false;

                    id += `   (${moment(booking.from).format('HH:mm')} - ${moment(booking.to).format('HH:mm')}) `;

                    const span: Span = {
                        start: new Date(booking.from).getTime(),
                        end: new Date(booking.to).getTime(),
                    };

                    const item = {
                        id,
                        rowId,
                        span,
                        disabled,
                    };
                    itemList.push(item);
                });

                return {
                    id: room.name,
                    disabled: false,
                };
            });

            setItems([...itemList]);

            setRows(
                [...rows].sort((a, b) => {
                    return a.id.localeCompare(b.id, undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                }),
            );
        }
    }, [data]);

    const onResizeEnd = useCallback((event: ResizeEndEvent) => {
        const updatedSpan = event.active.data.current.getSpanFromResizeEvent?.(event);

        if (!updatedSpan) return;

        const activeItemId = event.active.id;

        setItems((prev) =>
            prev.map((item) => {
                if (item.id !== activeItemId) return item;

                return {
                    ...item,
                    span: updatedSpan,
                };
            }),
        );
    }, []);

    if (loading || (!user && userLoading)) {
        return <PreLoader />;
    }

    return (
        <Container className='mt-4' fluid>
            <Card>
                <CardBody className='p-4'>
                    <TimelineContext onResizeEnd={onResizeEnd} range={range} onRangeChanged={setRange}>
                        <Timeline items={items} rows={rows} />
                    </TimelineContext>
                </CardBody>
            </Card>
        </Container>
    );
}
