/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap';
import { Client, ClientStatus, ClientType } from '../../../models/client.model';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_CLIENT_DETAILS, GET_ADD_CLIENT_DATA } from '../../../graphql/clients.graphql';
import useToast from '../../../hooks/useToast';
import Spinner from '../../../components/Spinner/Spinner';
import { LinearProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import ReactDatetimeClass from 'react-datetime';
import moment, { isMoment } from 'moment';
import ClientAddressCard from './components/ClientAddressCard';
import { User } from '../../../models/user.model';
import { useSelector } from 'react-redux';
import { getPermission } from '../../../utils/user.utils';
import { PhoneInput } from 'react-international-phone';
import ClientSettingsCard from './components/ClientSettingsCard';
import { PhoneNumberUtil } from 'google-libphonenumber';

interface ClientProps {
    client: Client;
}

type FormData = {
    companyName: string;
    individualName: string;
    companyRegNumber: string;
    vatNumber: string;
    email: string;
    contactNumber: string;
    clientName: string;
    clientSurname: string;
    status: string;
    type: string;
    isColabMember: boolean;
    hasAccount: boolean;
    contractExpiry: string;
    accountCode: string;
    identityNumber: string;
    licencePlate: string;
};

const ClientDetailView = ({ client }: ClientProps) => {
    const user: User | null = useSelector((state: any) => state.user.userInfo);

    const [isAccountCode, setIsAccountCode] = useState(client?.hasAccount && client?.hasAccount === true);

    const [accountType, setAccountType] = useState<string>('');

    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = (phone: string) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
            return false;
        }
    };

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
    } = useForm<FormData>();

    const { showToast, Notification } = useToast();
    const [updateClientDetailsMutation, { loading: isUpdating, error, called, reset }] = useMutation(
        UPDATE_CLIENT_DETAILS,
        {
            refetchQueries: ['GetClients', 'GetClient'],
        },
    );

    useEffect(() => {
        if (client) {
            setAccountType(client?.type?.value);
        }
    }, [client]);

    useEffect(() => {
        console.log('Client has account', isAccountCode);
    }, []);

    const { loading: loadingStatus, error: statusError, data: loadedStatus } = useQuery(GET_ADD_CLIENT_DATA);

    useEffect(() => {
        if (statusError) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [statusError]);

    useEffect(() => {
        if (!error && called && !isUpdating) {
            reset();
            showToast("Successfully updated client's details", 'success');
        }

        if (error && called) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [called, error, isUpdating]);

    const onSubmit = (data: FormData) => {
        if (!isPhoneValid(data?.contactNumber)) {
            setError('contactNumber', { type: 'custom', message: '*Please enter a valid phone number' });
            return;
        }

        if (data?.status === 'empty' || data?.status === '') {
            setError('status', {
                message: '*This field is required',
                type: 'custom',
            });
        }

        if (data?.type === 'empty' || data?.type === '') {
            setError('type', { message: '*This field is required', type: 'custom' });
            return;
        }

        const userAccount: object = {
            company_name: data.companyName,
            individual_name: data.individualName,
            vat_number: data.vatNumber,
            status_enum: data.status,
            type_enum: data.type,

            contract_expiry_date: data.contractExpiry ? moment(data.contractExpiry).format('yyyy-MM-DD') : null,
            account_code: data.accountCode,
            has_colab_account: isAccountCode,
            company_registration_number: data.companyRegNumber,
        };

        const user: object = {
            email: data.email,
            contact_number: data.contactNumber,
            name: data.clientName,
            surname: data.clientSurname,
            id_number: data.identityNumber,
            licence_plate: data.licencePlate,
        };

        updateClientDetailsMutation({
            variables: {
                account_id: client.id,
                user_account: userAccount,
                user_id: client?.user?.id,
                user: user,
            },
        });
    };

    if (loadingStatus) {
        return <LinearProgress />;
    }

    const clientStatusList: ClientStatus[] = loadedStatus?.status;
    const clientTypes: ClientType[] = loadedStatus?.types;

    const handleChange = (e: any) => {
        if (e.target.checked) {
            setIsAccountCode(true);
        } else setIsAccountCode(false);
    };

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardBody>
                            <h3>Client Information</h3>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    {accountType !== 'individual' && (
                                        <>
                                            <div className='col-lg-6 col-md-12'>
                                                <FormGroup>
                                                    <label className='form-control-label' htmlFor='name'>
                                                        Company name
                                                    </label>
                                                    <input
                                                        className='form-control'
                                                        id='company-name'
                                                        placeholder="Enter company's name..."
                                                        {...register('companyName', {
                                                            required: accountType !== 'individual',
                                                            value: client?.companyName ?? '',
                                                        })}
                                                        type='text'
                                                    />
                                                    {errors.companyName && (
                                                        <span className='invalid'>*This field is required</span>
                                                    )}
                                                </FormGroup>
                                            </div>
                                            <div className='col-lg-6 col-md-12'>
                                                <FormGroup>
                                                    <label className='form-control-label' htmlFor='companyRegNumber'>
                                                        Company registration number
                                                    </label>
                                                    <input
                                                        className='form-control'
                                                        id='companyRegNumber'
                                                        placeholder="Enter company's registration number..."
                                                        {...register('companyRegNumber', {
                                                            required: false,
                                                            value: client?.companyRegNumber ?? '',
                                                        })}
                                                        type='text'
                                                    />
                                                    {errors?.companyRegNumber && (
                                                        <span className='invalid'>*This field is required</span>
                                                    )}
                                                </FormGroup>
                                            </div>
                                        </>
                                    )}
                                    {accountType === 'individual' && (
                                        <>
                                            <div className='col-lg-6 col-md-12'>
                                                <FormGroup>
                                                    <label className='form-control-label' htmlFor='name'>
                                                        Individual name
                                                    </label>
                                                    <input
                                                        className='form-control'
                                                        id='individual-name'
                                                        placeholder="Enter individual's name..."
                                                        {...register('individualName', {
                                                            required: accountType === 'individual',
                                                            value: client?.individualName ?? '',
                                                        })}
                                                        type='text'
                                                    />
                                                    {errors.individualName && (
                                                        <span className='invalid'>*This field is required</span>
                                                    )}
                                                </FormGroup>
                                            </div>
                                        </>
                                    )}

                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='name'>
                                                VAT number (optional)
                                            </label>
                                            <input
                                                className='form-control'
                                                id='vatNumber'
                                                placeholder="Enter client's VAT number..."
                                                {...register('vatNumber', {
                                                    required: false,
                                                    value: client?.vatNumber ?? '',
                                                })}
                                                type='text'
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='email'>
                                                Email
                                            </label>
                                            <input
                                                className='form-control'
                                                id='email'
                                                placeholder="Enter the client's email..."
                                                {...register('email', {
                                                    required: false,
                                                    value: client?.user?.email ?? '',
                                                })}
                                                type='email'
                                            />
                                            {errors?.email && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-9'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='contact'>
                                                Contact number
                                            </label>
                                            <PhoneInput
                                                defaultCountry='za'
                                                placeholder='Enter phone number...'
                                                inputClassName='form-control'
                                                value={client?.user?.contactNumber ?? ''}
                                                style={
                                                    {
                                                        '--react-international-phone-width': '100%',
                                                        '--react-international-phone-height': '44px',
                                                        '--react-international-phone-border-radius': '12px',
                                                    } as React.CSSProperties
                                                }
                                                // value={watch('contactNumber')}
                                                {...register('contactNumber', {
                                                    required: true,
                                                    value: client?.user?.contactNumber ?? '',
                                                })}
                                                onChange={(phone) => setValue('contactNumber', phone)}
                                            />
                                            {errors.contactNumber && (
                                                <span className='invalid'>
                                                    {errors?.contactNumber?.message ?? '*This field is required'}
                                                </span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label'>Contract Expiry Date</label>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    placeholder: 'Select expiry date...',
                                                    ...register('contractExpiry', {
                                                        required: false,
                                                        value: client?.contractExpiry,
                                                    }),
                                                    autoComplete: 'off',
                                                }}
                                                onChange={(value) => {
                                                    console.log('SELECT DATE', value);
                                                    if (value && isMoment(value)) {
                                                        setValue('contractExpiry', value?.toString());
                                                    }
                                                }}
                                                initialViewMode='years'
                                                initialValue={client?.contractExpiry}
                                                timeFormat={false}
                                                dateFormat={'YYYY-MM-DD'}
                                            />
                                            {errors?.contractExpiry && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='p-member-surname'>
                                                Status
                                            </label>
                                            <Select
                                                id='status'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={client?.status ? client?.status.value : 'empty'}
                                                inputProps={{
                                                    ...register('status', {
                                                        required: true,
                                                        value: client?.status ? client?.status.value : 'empty',
                                                    }),
                                                }}>
                                                <MenuItem disabled value='empty'>
                                                    Select status...
                                                </MenuItem>
                                                {clientStatusList?.map((status) => {
                                                    return (
                                                        <MenuItem key={status?.value} value={status?.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.status && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='p-member-surname'>
                                                Type
                                            </label>
                                            <Select
                                                id='status'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={client?.type ? client?.type?.value : 'empty'}
                                                onChange={(event: SelectChangeEvent) => {
                                                    setAccountType(event.target?.value);
                                                }}
                                                inputProps={{
                                                    ...register('type', {
                                                        required: true,
                                                        value: client?.type ? client?.type?.value : 'empty',
                                                    }),
                                                }}>
                                                <MenuItem disabled value='empty'>
                                                    Select account type...
                                                </MenuItem>
                                                {clientTypes?.map((type) => {
                                                    return (
                                                        <MenuItem key={type?.value} value={type?.value}>
                                                            {type?.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.type && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>

                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup check inline>
                                            <input
                                                type='checkbox'
                                                className='mr-2'
                                                checked={isAccountCode}
                                                {...register('hasAccount', {
                                                    required: false,
                                                    value: client?.hasAccount,
                                                })}
                                                onChange={handleChange}
                                            />
                                            <Label check>Has Colab Account?</Label>
                                        </FormGroup>
                                        {isAccountCode && (
                                            <FormGroup className='mt-2'>
                                                <label className='form-control-label' htmlFor='accountCode'>
                                                    Account code
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id='accountCode'
                                                    placeholder='Enter Account code...'
                                                    {...register('accountCode', {
                                                        required: false,
                                                        value: client?.accountCode,
                                                    })}
                                                    type='text'
                                                />
                                                {errors.accountCode && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        )}
                                    </div>

                                    <div className='col-md-12 mt-4'>
                                        <h3>Principle Member</h3>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='p-member-name'>
                                                Member name
                                            </label>
                                            <input
                                                className='form-control'
                                                id='p-member-name'
                                                placeholder="Enter member's name..."
                                                {...register('clientName', {
                                                    required: false,
                                                    value: client?.user?.name,
                                                })}
                                                type='text'
                                            />
                                            {errors.clientName && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </FormGroup>
                                    </div>

                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='p-member-surname'>
                                                Member surname
                                            </label>
                                            <input
                                                className='form-control'
                                                id='p-member-surname'
                                                placeholder="Enter member's surname..."
                                                {...register('clientSurname', {
                                                    required: false,
                                                    value: client?.user?.surname,
                                                })}
                                                type='text'
                                            />
                                            {errors.clientSurname && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    {/**ID FIELD */}
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='identityNumber'>
                                                Identity number
                                            </label>
                                            <input
                                                className='form-control'
                                                id='identityNumber'
                                                placeholder="Enter member's identity number..."
                                                {...register('identityNumber', {
                                                    required: false,
                                                    value: client?.user?.identityNumber,
                                                })}
                                                type='text'
                                            />
                                            {errors.identityNumber && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='licencePlate'>
                                                License plate (optional)
                                            </label>
                                            <input
                                                className='form-control'
                                                id='licencePlate'
                                                placeholder="Enter member's licence plate..."
                                                {...register('licencePlate', {
                                                    required: false,
                                                    value: client?.user?.licencePlate,
                                                })}
                                                type='text'
                                            />
                                            {errors.licencePlate && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                                {getPermission(user, 'clients', 'update') && (
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button type='submit' className='btn btn-dark mt-4' disabled={isUpdating}>
                                                {isUpdating ? (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                ) : (
                                                    <span>Update</span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </CardBody>
                    </Card>
                </Col>
                <Col md='12' lg='6'>
                    <ClientSettingsCard client={client} />
                    <ClientAddressCard address={client?.addresses[0]} accountId={client?.id} />
                </Col>
            </Row>
        </>
    );
};

export default ClientDetailView;
