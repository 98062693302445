import React from 'react';
import CalendarView from '../dashboards/components/CalendarView';
import { Col, Container, Row } from 'reactstrap';
import { BookingEvent, BookingStatus } from '../../../models/bookings.model';
import { GET_FULL_CALENDAR_BOOKINGS } from '../../../graphql/bookings.graphql';
import { useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import moment from 'moment';

export default function BookingFullCalendarView() {
    const { data, loading } = useQuery(GET_FULL_CALENDAR_BOOKINGS);

    const getBookingClassName = (status: BookingStatus): string => {
        switch (status.value) {
            case 'active':
                return 'bg-green';
            case 'complete':
                return 'bg-green';
            case 'pending':
                return 'bg-orange';
            case 'confirmed':
                return 'bg-info';
            default:
                return 'bg-info';
        }
    };

    const getIsAllDayBooking = (start: string, end: string): boolean => {
        const hours = moment(end).diff(moment(start), 'hours');
        return hours >= 8;
    };

    const getBookingEvents = (): BookingEvent[] => {
        if (data?.bookings) {
            const { bookings } = data;
            return bookings.map((booking) => {
                const event: BookingEvent = {
                    id: booking.id,
                    title: booking?.client?.companyName || booking?.client?.individualName,
                    description: booking.subject,
                    allDay: getIsAllDayBooking(booking.from, booking.to),
                    className: getBookingClassName(booking.status),
                    start: new Date(booking.from),
                    roomName: booking.meetingRoomItem.name,
                };
                return event;
            });
        }
        return [];
    };

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <Container className='mt-4' fluid>
            <Row>
                <Col>
                    <h1>Full Calendar</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    {' '}
                    <CalendarView
                        rooms={data?.rooms || []}
                        bookings={getBookingEvents()}
                        bookingData={data?.bookings || []}
                        statusList={data?.statusList || []}
                    />
                </Col>
            </Row>
        </Container>
    );
}
