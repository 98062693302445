/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import { getPermission } from '../../../../utils/user.utils';
import { Link, useNavigate } from 'react-router-dom';
import BasicMenu from '../../../../components/Menu';
import { useSelector } from 'react-redux';
import useToast from '../../../../hooks/useToast';
import { GET_CAMPAIGNS, REMOVE_CAMPAIGN } from '../../../../graphql/campain.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import _ from 'lodash';
import { Campaign } from '../../../../models/campain.model';
import SweetAlert from 'react-bootstrap-sweetalert';
import { currencyFormatter } from '../../../../utils/format.utils';

export default function CampaignListView() {
    const user = useSelector((state: any) => state.user.userInfo);

    const { showToast, Notification } = useToast();
    const [alert, setAlert] = useState<any>(null);
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [searchKey, setSearchKey] = useState<string>('');

    const navigate = useNavigate();
    const [sortFiled, setSortField] = useState<string>('title');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const { data, loading, error } = useQuery(GET_CAMPAIGNS);
    const [removeCampaign, { data: removedCampaign }] = useMutation(REMOVE_CAMPAIGN, {
        refetchQueries: [{ query: GET_CAMPAIGNS }],
    });
    const sortBy: any = (campaigns: Campaign[], field: string, direction: string) => {
        const orderedCampaigns = new Map([
            ['title', _.orderBy(campaigns, (campaign) => campaign.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['code', _.orderBy(campaigns, (campaign) => campaign.code, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'startDate',
                _.orderBy(campaigns, (campaign) => campaign.startDate, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            ['endDate', _.orderBy(campaigns, (campaign) => campaign.endDate, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'category',
                _.orderBy(campaigns, (campaign) => campaign.category.title, [direction === 'asc' ? 'asc' : 'desc']),
            ],
        ]);
        return orderedCampaigns.get(field);
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying load the page', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (removedCampaign) {
            showToast('', 'success');
        }
    }, [removedCampaign]);

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={async () => {
                    await removeCampaign({
                        variables: {
                            id: props.campaign.id,
                        },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This campaign will be removed.
            </SweetAlert>,
        );
    };

    if (loading) {
        return <LinearProgress />;
    }

    const campaigns: Campaign[] = data?.campaign;

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    let filteredCampaigns: Campaign[] = campaigns?.filter((campaign) => {
        if (searchKey === '') {
            return campaign;
        }

        return (
            campaign.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            campaign.category.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            campaign.code.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            campaign.startDate.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            campaign.endDate.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });
    filteredCampaigns = sortBy(filteredCampaigns, sortFiled, sortDirection);

    return (
        <>
            {alert}
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Campaigns</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Campaigns</h3>
                                    </Col>
                                    {getPermission(user, 'locations', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <Link
                                                to={'/admin/campaign/new'}
                                                className=' btn btn-round btn-dark btn-sm'
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'>Add campaign</span>
                                            </Link>
                                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                Add campaign
                                            </UncontrolledTooltip>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col>
                                        <Input
                                            className='w-25'
                                            value={searchKey}
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                            }}></Input>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('title');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='title'
                                            scope='col'>
                                            Title
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('code');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='code'
                                            scope='col'>
                                            Code
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('startDate');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='startDate'
                                            scope='col'>
                                            Start Date
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('endDate');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='endDate'
                                            scope='col'>
                                            End Date
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('category');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='category'
                                            scope='col'>
                                            Category
                                        </th>
                                        <th className='sort' data-sort='category' scope='col'>
                                            Page views
                                        </th>
                                        <th className='sort' data-sort='category' scope='col'>
                                            Total Bookings
                                        </th>
                                        <th className='sort' data-sort='category' scope='col'>
                                            Total Confirmed Bookings
                                        </th>
                                        <th className='sort' data-sort='category' scope='col'>
                                            Total Sales(Excl VAT)
                                        </th>

                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredCampaigns
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((campaign) => {
                                            return (
                                                <tr
                                                    key={campaign.id}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => navigate(`/admin/campaign/manage/${campaign.id}`)}>
                                                    <td>{campaign.title}</td>
                                                    <td>{campaign.code}</td>
                                                    <td>{campaign.startDate}</td>
                                                    <td>{campaign.endDate}</td>
                                                    <td>{campaign.category.title}</td>
                                                    <td>{campaign.pageClicks ?? 0}</td>
                                                    <td>
                                                        {campaign.interactionsAggregate.aggregate?.sum?.totalBookings ??
                                                            0}
                                                    </td>
                                                    <td>
                                                        {campaign.interactionsAggregate.aggregate?.sum
                                                            ?.completedBookings ?? 0}
                                                    </td>
                                                    <td>
                                                        {currencyFormatter(
                                                            campaign.interactionsAggregate.aggregate?.sum.salesTotal ??
                                                                0,
                                                        )}
                                                    </td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'products', 'update') && (
                                                            <Link
                                                                to={`/admin/campaign/manage/${campaign.id}`}
                                                                className='btn btn-info btn-icon-only rounded-circle btn-sm'>
                                                                <i className='fa-solid fa-pencil'></i>
                                                            </Link>
                                                        )}
                                                        {getPermission(user, 'products', 'delete') && (
                                                            <button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, campaign });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredCampaigns.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredCampaigns.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}
