import React, { useEffect, useState } from 'react';
import { ConferenceBookingNote } from '../../../../models/bookings.model';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useMutation } from '@apollo/client';
import { GET_BOOKING_DETAILS, REMOVE_BOOKING_NOTE } from '../../../../graphql/bookings.graphql';
interface NoteItemProps {
    notes: ConferenceBookingNote[];
    note: ConferenceBookingNote;

    getNotes: (notes: ConferenceBookingNote[]) => void;
    userId?: string;
    onClick: () => void;
}
export default function NoteItem({ notes, note, getNotes, userId, onClick }: NoteItemProps) {
    const noteBelongsToCurrentUser = userId === note?.user?.id;
    const [alert, setAlert] = useState(null);
    const [removeNote, { error }] = useMutation(REMOVE_BOOKING_NOTE, { refetchQueries: [GET_BOOKING_DETAILS] });
    const getUserName = (): string =>
        note.user ? (noteBelongsToCurrentUser ? 'You' : `${note.user.name} ${note.user.surname}`) : '';

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    handleNoteDelete();
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                These notes will be removed from the system.
            </SweetAlert>,
        );
    };

    useEffect(() => {
        if (error) {
            console.log('An error has occurred while trying to remove this notes', error);
        }
    }, [error]);

    const handleNoteDelete = () => {
        const index = notes.indexOf(note);
        if (index === -1) return;

        if (note?.id) {
            removeNote({
                variables: {
                    id: note?.id,
                },
            });
        }

        const newNotes = notes.filter((n) => notes.indexOf(n) !== index);
        getNotes(newNotes);
    };
    return (
        <>
            {alert}
            <div
                onClick={() => {
                    if (note?.user?.id && !noteBelongsToCurrentUser) return;
                    onClick();
                }}
                style={{ cursor: !note?.user?.id || noteBelongsToCurrentUser ? 'pointer' : 'default', width: '600px' }}
                className='mt-3 comment-card'>
                <div className='card-body'>
                    <div className='row mb-2'>
                        <div className='col-12 text-right'>
                            {noteBelongsToCurrentUser && (
                                <DeleteIcon
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        displayAlert(note);
                                    }}
                                />
                            )}
                            {!noteBelongsToCurrentUser && !note?.user?.id && (
                                <DeleteIcon
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        displayAlert(note);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                            <h5 className='card-title'>
                                {`${getUserName()}`}{' '}
                                <span className='card-text'>
                                    {' '}
                                    {note?.createdAt ? 'added note' : 'You added the note'}
                                </span>
                            </h5>
                        </div>
                        <div className='col-6 text-right'>
                            <span className='card-text'>
                                {note?.createdAt ? moment(note.createdAt).fromNow() : 'Just now'}
                            </span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p className='card-text'>{note?.notes}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
